// @flow
import React from 'react';
import { useFela, } from 'react-fela';

import type { Node, } from 'react';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import Picture from '../../../Image/Picture';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import getPictureAssets from '../../../../utils/getPictureAssets';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserLabelDisclaimer from '../../../TeaserLabelDisclaimer/TeaserLabelDisclaimer';
import setBiAction from '../../../../utils/setBiAction';


// ////////////////////////////////////////////////////////////////// //
//                             Container                              //
// ////////////////////////////////////////////////////////////////// //

const areasTemplate = [
  {
    until: 's',
    value: `
    "he"
    "t1"
    "t2"
    "t3"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "he he"
    "t1 t2"
    "t3 t3"
    "t4 t4"
    `,
  },
  {
    from: 'l',
    value: `
   "he he t1 t1 t1 t2 t2 t2 t3 t3 t3 t3"
   "he he t1 t1 t1 t2 t2 t2 t4 t4 t4 t4"
    `,
  },
];
const teasers = [ Teaser1, Teaser1, Teaser2, Teaser2, ];
const teaserGridAreaNames = [ 't1', 't2', 't3', 't4', ];

type Lrrr2PropsType = {
  list: ListDataType,
  isLazyloadImages: boolean,
  biAction: ?ListBiActionType,
  gaAction: ?() => void,
};

Lrrr2.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

function Lrrr2({ list, isLazyloadImages, biAction, gaAction, }: Lrrr2PropsType): Node {
  const { theme, } = useFela();
  const { title, banners, description, } = list;
  const { clickTrackerBanners, } = banners;
  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[ { until: 's', value: '1fr', }, { from: 's', until: 'l', value: 'repeat(2, 1fr)', }, { from: 'l', value: 'repeat(12, 1fr)', }, ]}
      rowTemplate={[
        { until: 's', value: 'auto auto auto auto', },
        { from: 's', until: 'l', value: 'auto', },
        { from: 'l', value: 'auto', },
      ]}
      padding={[ { from: 's', value: '4rem', }, { until: 's', value: '2rem', }, ]}
      gridGap={null}
      rowGap="4rem"
      colGap="4rem"
      attrs={{
        'data-test': 'lrrr2',
      }}
    >
      {/* Header */}
      <ListViewHeader
        title={title}
        isCommercial
        description={description}
        miscStyles={{
          fontFamily: theme.fontStacks.commercial,
          flexDirection: [ { from: 's', until: 'l', value: 'column', }, ],
          backgroundColor: [ { until: 's', value: theme.color('commercial', 'bg'), }, { from: 's', value: 'transparent', }, ],
        }}
      />
      {clickTrackerBanners
        && clickTrackerBanners.map((item, idx) => (idx < 4 ? (
          <ClickTracker
            key={item.contentName}
            {...item}
            render={(banner: ClickTrackerBannerType) => {
              const { name, url, image, } = banner;
              const T = teasers[idx];
              return (
                <T
                  text={name}
                  link={url}
                  gridArea={teaserGridAreaNames[idx]}
                  clicktrackerimage={image}
                  banner={banner}
                  isLazyloadImages={isLazyloadImages}
                  teaserIdx={idx}
                  biAction={setBiAction(idx, banner, biAction)}
                />
              );
            }}
          />
        ) : null)
        )}
    </ListView>
  );
}

type teaserProps = {
  banner: ClickTrackerBannerType,
  biAction: ?() => void,
  gridArea: string,
  text: ?string,
  link: string,
  isLazyloadImages: boolean,
  clicktrackerimage: ImageDataType,
  teaserIdx: number,
};
const teaserDefaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

const mediaObjTeaserAreas = `
  "media    .    ."
  "media content ."
  "media footer  ."
  "media label   ."
  "media    .    ."
`;


Teaser1.defaultProps = teaserDefaultProps;

function Teaser1({
  banner,
  biAction,
  isLazyloadImages,
  gridArea,
  clicktrackerimage,
  text,
  link,
  teaserIdx,
}: teaserProps) {
  const { theme, } = useFela();
  return (
    <Teaser
      areasTemplate={[
        {
          from: 's',
          value:
          `
            "media media media"
            "  .  content  .  "
            "  .  label    .  "
            "  .     .     .  "
          `,
        },
        {
          until: 's',
          value: mediaObjTeaserAreas,
        },
      ]}
      colTemplate={[ { from: 's', value: '0 1fr 0', }, { until: 's', value: '18rem 1fr', }, ]}
      rowTemplate={[ { from: 's', value: 'auto 1fr auto 1rem', }, { until: 's', value: '0 auto auto auto 0.3rem', }, ]}
      colGap="1rem"
      rowGap="1rem"
      gridArea={gridArea}
      onClick={biAction}
      backgroundColor={[ 'commercial', 'bg', ]}
      miscStyles={{
        fontFamily: theme.fontStacks.commercial,
        border: [ '1px', 0, 'solid', theme.color('neutral', -5), ],
      }}
    >
      <TeaserMedia data={banner} isClickTracker>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: clicktrackerimage,
            defaultImgOptions: {
              sizes: '108px',
              aspect: 'square',
              widths: [ 108, 216, ],
            },
            sources: [
              {
                aspect: 'regular',
                from: 's',
                sizes: [
                  { from: 'xl', size: '285px', },
                  { from: 'l', size: '226px', },
                  { from: 'm', size: '348px', },
                  { from: 's', size: '264px', },
                ],
                widths: [ 348, 281, 264, 226, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        {...{
          title: text || '',
          path: link,
        }}
        typeScale={[ { until: 's', value: -1, }, { from: 's', until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
      />
      <TeaserLabelDisclaimer companyName={banner.advertiser || ''} />
    </Teaser>
  );
}

Teaser2.defaultProps = teaserDefaultProps;

function Teaser2({
  banner,
  biAction,
  isLazyloadImages,
  gridArea,
  clicktrackerimage,
  text,
  link,
  teaserIdx,
}: teaserProps) {
  const { theme, } = useFela();
  return (
    <Teaser
      areasTemplate={[
        {
          from: 's',
          value: `
      "  .     .       .   ."
      "  .  content  media ."
      "  .  content  media ."
      "  .     .     media ."
      "  .  label    media ."
      "  .     .       .   ."
    `,
        },
        {
          until: 's',
          value: mediaObjTeaserAreas,
        },
      ]}
      colTemplate={[ { until: 's', value: '18rem 1fr', }, { from: 's', until: 'l', value: '0 1fr 21.5rem 1rem', }, { from: 'l', value: '0 1fr 25.5rem 1rem', }, ]}
      rowTemplate={[ { from: 's', value: '1rem 1fr 1fr 1fr auto 1rem', }, { until: 's', value: 'auto 1fr auto auto 0.3rem', }, ]}
      colGap="1rem"
      rowGap="1rem"
      gridArea={gridArea}
      onClick={biAction}
      backgroundColor={[ 'commercial', 'bg', ]}
      miscStyles={{
        fontFamily: theme.fontStacks.commercial,
        border: [ '1px', 0, 'solid', theme.color('neutral', -5), ],
        ...(teaserIdx === 3 ? theme.mq({ until: 's', }, { display: 'none', }) : {}),
      }}
    >
      <TeaserMedia data={banner} isClickTracker>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: clicktrackerimage,
            defaultImgOptions: {
              sizes: [
                { from: 'xl', size: '285px', },
                { from: 'l', size: '225px', },
                { from: 's', size: '348px', },
                { size: '296px', },
              ],
              aspect: 'regular',
              widths: [ 300, 400, 600, 800, ],
            },
            sources: [
              {
                aspect: 'square',
                until: 's',
                sizes: '185px',
                widths: [ 190, 480, 600, 800, ],
              },
              {
                aspect: 'regular',
                from: 's',
                sizes: [
                  { from: 'xl', size: '285px', },
                  { from: 'l', size: '215px', },
                  { from: 's', size: '348px', },
                  { size: '296px', },
                ],
                widths: [ 215, 260, 350, 520, 700, 1080, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        {...{
          title: text || '',
          path: link,
        }}
        typeScale={[ { until: 's', value: -1, }, { from: 's', until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
      />
      <TeaserLabelDisclaimer
        companyName={banner.advertiser || ''}
        miscStyles={{
          paddinhBottom: '1rem',
          extend: [
            theme.mq({ until: 's', }, { paddingEnd: '1rem', }),
          ],
        }}
      />
    </Teaser>

  );
}

export default Lrrr2;
