// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  borderTop,
  borderBottom,
  parseComponentProp,
} from '@haaretz/htz-css-tools';

import type { Node, } from 'react';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import Section from '../../../AutoLevels/Section';
import AdSlot from '../../../AdManager/AdSlot';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Picture from '../../../Image/Picture';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import pictureAssetProps from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import Query from '../../../ApolloBoundary/Query';
import stocksQuery from './stocksQuery.graphql';

// ////////////////////////////////////////////////////////////////// //
//                             Container                              //
// ////////////////////////////////////////////////////////////////// //

const areasTemplate = [
  {
    until: 's',
    value: `
    "he"
    "stocks"
    "main"
    "textual1"
    "textual2"
    "textual3"
    "ad"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "he   he "
    "stocks   stocks"
    "main   textual1"
    "main   textual2"
    "main   textual3"
    `,
  },
  {
    from: 'l',
    until: 'xl',
    value: `
    "he   main textual1 stocks"
    "he   main textual2 stocks"
    "he   main textual3 stocks"
    `,
  },
  {
    from: 'xl',
    value: `
    "he  stocks   stocks   stocks"
    "he  main     textual1     ad"
    "he  main     textual2     ad"
    "he  main     textual3     ad"
    `,
  },
];
const teasers = [ MainTeaser, TextualTeaser, TextualTeaser, TextualTeaser, ];
const teaserGridAreaNames = [ 'main', 'textual1', 'textual2', 'textual3', ];

type StockType = {
  name: string,
  value: number,
  change: number,
};

type StocksType = {
  "142": StockType,
  "137": StockType,
  "9001": StockType,
  "29.10.@CCO": StockType,
  "30.10.!DJI": StockType,
};

export type Props = {
  list: ListDataType,
  gaAction: () => void,
  biAction: ?ListBiActionType,
  isLazyloadImages: boolean,
};

const headerTypo = [
  { until: 's', value: 0, },
  { from: 's', until: 'l', value: -1, },
  { from: 'l', until: 'xl', value: 0, },
  { from: 'xl', value: -1, },
];

export default function Zombie({
  list,
  gaAction,
  biAction,
  isLazyloadImages,
}: Props): React.Node {
  const { theme, } = useFela();
  const { items, banners, ...restOfList } = list || {};

  const adSlot = banners?.adSlots || [];

  const dfpData = adSlot && adSlot[0];

  if (!(items && items.length)) return null;

  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        gridArea: teaserGridAreaNames[i],
        biAction: setBiAction(i, data, biAction),
        index: i + 1,
        isLazyloadImages,
        theme,
      });
    }
    return null;
  }

  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[
        { from: 's', until: 'l', value: '1fr 1fr', },
        { from: 'l', until: 'xl', value: '2fr 5fr 3fr 2fr', },
        { from: 'xl', value: '2fr 3fr 3fr 4fr', },
      ]}
      attrs={{
        'data-test': 'zombie',
      }}
    >
      {/* Title */}
      <ListViewHeader
        miscStyles={{
          marginBottom: [
            { until: 's', value: '-1rem', },
            { from: 's', until: 'l', value: '-2rem', },
          ],
        }}
        isSticky
        {...restOfList}
      />
      <Section isFragment>
        {/* Stocks */}
        <Query
          query={stocksQuery}
          variables={{
            ids: [
              '142',
              '137',
              '9001',
              '29.10.@CCO',
              '30.10.!DJI',
            ],
          }}
        >
          {({ loading, error, data, }) => {
            if (loading || error) return null;
            const { assets, } = data || {};
            return (
              <Stocks stocks={assets} />
            );
          }}
        </Query>
        {/* Teasers */}
        {items.map(itemRenderer)}
        {/* DFP */}
        {dfpData ? (
          <AdSlot
            {...dfpData}
            wrapperMiscStyles={{
              gridArea: 'ad',
              justifyContent: 'center',
              justifyItems: 'center',
              margin: 'auto',
              marginTop: [ { untils: 's', value: '3rem', }, ],
              display: [
                { until: 's', value: 'flex', },
                { from: 's', until: 'xl', value: 'none', },
                { from: 'xl', value: 'flex', },
              ],
            }}
          />
        ) : null}
      </Section>
    </ListView>
  );
  //   }
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

const stackedTeaserAreas = `
  "media media media"
  ".     content   ."
  ".     footer    ."
  ".       .       ."
`;

type TeaserProps = {
  data: TeaserDataType,
  isLazyloadImages?: boolean,
  biAction: ?() => void,
  gridArea: string,
  theme: Object,
  index?: number,
};
MainTeaser.defaultProps = { isLazyloadImages: true, biAction: null, index: 0, };
function MainTeaser({
  data,
  gridArea,
  isLazyloadImages,
  biAction,
  theme,
}: TeaserProps): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      data={data}
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={stackedTeaserAreas}
      rowTemplate="auto 1fr auto 0"
      colTemplate="0 1fr 0"
      onClick={biAction}
      rowGap="1rem"
      colGap="2rem"
    >
      <TeaserMedia data={data} isStacked onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...pictureAssetProps({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: 'calc(100vw - 24px)',
              aspect: 'headline',
              widths: [ 300, 352, 400, 560, ],
            },
            sources: [
              {
                from: 's',
                aspect: 'regular',
                sizes: [
                  { from: 'xl', size: '281px', },
                  { from: 'l', size: '393px', },
                  { from: 'm', size: '348px', },
                  { from: 's', size: '264px', },
                ],
                widths: [ 528, 393, 348, 281, 264, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        {...data}
        typeScale={[
          { until: 's', value: 1, },
          { from: 's', until: 'xl', value: 2, },
          { from: 'xl', value: 1, },
        ]}
        onClick={biAction}
        isGridItem
      />
      <TeaserFooter data={data} showRank showAuthor />
    </Teaser>
  );
}

TextualTeaser.defaultProps = {
  isLazyloadImages: false,
  biAction: null,
  index: 1,
};

function TextualTeaser({
  data,
  gridArea,
  biAction,
}: TeaserProps): React.Node {
  return (
    <Teaser
      data={data}
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={`
      ".    .    ."
      ". content ."
      ". footer  ."
      ".    .    ."
      `}
      onClick={biAction}
      rowTemplate="0 1fr auto 0"
      colTemplate="0 auto 0"
      rowGap="1rem"
      colGap={[ { until: 's', value: '2rem', }, { from: 's', value: '1rem', }, ]}
    >
      <TeaserHeader
        {...data}
        typeScale={headerTypo}
        onClick={biAction}
        isGridItem
      />
      <TeaserFooter showAuthor showRank data={data} />
    </Teaser>
  );
}

// /////////////////////////////////////////////////////////////////////
//                               STOCKS                               //
// /////////////////////////////////////////////////////////////////////

type StockProps = StockType & {
  hideOnM: boolean,
  isFirst: boolean,
};

Stock.defaultProps = {
  hideOnM: false,
  isFirst: false,
};
function setBorderEnd(prop, ruleOptions, theme) {
  return {
    position: 'relative',
    ':after': {
      backgroundColor: theme.color(...theme.gridStyle.ruleColor),
      content: '""',
      height: '100%',
      // This depends on `fela-plugin-bidi`
      insetInlineEnd: '0',
      position: 'absolute',
      top: '0',
      // This depends on `fela-plugin-bidi`
      // We set the rule at the half its width past the gutter's end,
      // so it is placed exactly between two `<GridItem>`s. However, we
      // round floats into integers to avoid fuzzy rendering of subpixels.
      transform: `logical translateX(${Math.floor(
        theme.gridStyle.ruleWidth / 2
      )}px)`,
      width: `${theme.gridStyle.ruleWidth}px`,
    },
  };
}
function setBorderStart(prop, ruleOptions, theme) {
  return {
    position: 'relative',
    ':before': {
      backgroundColor: theme.color(...theme.gridStyle.ruleColor),
      content: '""',
      height: '100%',
      insetInlineStart: '0',
      position: 'absolute',
      top: '0',
      transform: `logical translateX(${Math.floor(
        theme.gridStyle.ruleWidth / 2
      )}px)`,
      width: `${theme.gridStyle.ruleWidth}px`,
    },
  };
}
function Stock({ name, value, change, hideOnM, isFirst, }: StockProps): Node {
  const { theme, css, } = useFela();
  const rule = [ { until: 'l', value: true, }, { from: 'xl', value: true, }, ];
  return (
    <div
      className={css({
        extend: [
          theme.mq({ until: 's', }, { padding: '1rem 1rem', }),
          theme.mq({ from: 's', until: 'l', }, { padding: '2rem', }),
          theme.mq({ from: 'l', until: 'xl', }, { padding: '2rem 1rem 0', }),
          theme.mq({ from: 'xl', }, { padding: '0 2rem', }),
          theme.mq({ until: 'l', }, { display: hideOnM ? 'none' : 'block', }),
          theme.type(-1),
          ...[ parseComponentProp('rule', rule, theme.mq, setBorderEnd, theme), ],
          ...(isFirst
            ? [
              parseComponentProp(
                'rule',
                rule,
                theme.mq,
                setBorderStart,
                theme
              ),
            ]
            : []),
        ],
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexWrap: 'wrap',

          paddingBlockEnd: '1rem',
          paddingBlockStart: '1rem',
          height: '100%',
          ...theme.mq(
            { from: 'l', until: 'xl', },
            {
              alignContent: 'flex-start',
              paddingBlockStart: '0',
              ...borderBottom('1px', 0, 'solid', theme.color('neutral', '-5')),
            }
          ),
        })}
      >
        <div
          className={css({
            fontWeight: '700',
            flexGrow: 0,
            flexBasis: '100%',
            ...theme.type(-2, { untilBp: 's', }),
            ...theme.type(-1, { fromBp: 's', untilBp: 'xl', }),
            ...theme.type(-2, { fromBp: 'xl', }),
          })}
        >
          {name}
        </div>
        <div
          className={css({
            color:
              change && change < 0
                ? theme.color('negative')
                : theme.color('positive'),
            direction: 'ltr',
            fontWeight: '700',
            textAlign: 'start',
            extend: [
              theme.mq({ until: 's', }, { order: 1, flexBasis: '100%', }),
              theme.type(0, { untilBp: 'xl', }),
            ],
          })}
        >
          {change
            ? `${change > 0 ? '+' : '-'}${numToHebrewString(Math.abs(change))}`
            : ''}
        </div>
        <div
          className={css({
            color: theme.color('neutral', '-3'),
            ...theme.mq({ from: 's', }, { paddingInlineStart: '1rem', }),
            ...theme.type(-2, { untilBp: 's', }),
            ...theme.type(0, { fromBp: 's', untilBp: 'xl', }),
          })}
        >
          {value}
        </div>
      </div>
    </div>
  );
}

type StocksProps = {
  stocks: StocksType,
};

function Stocks({ stocks, }: StocksProps): Node {
  const { theme, css, } = useFela();

  if (!stocks) {
    return null;
  }

  return (
    <div
      className={css({
        display: 'grid',
        gridArea: 'stocks',
        backgroundColor: theme.color('white'),
        extend: [
          theme.mq(
            { until: 'l', },
            {
              gridAutoFlow: 'column',
              gridAutoColumns: '1fr',
              ...borderTop('2px', 0, 'solid', theme.color('neutral', '-5')),
              ...borderBottom('2px', 0, 'solid', theme.color('neutral', '-5')),
            }
          ),
          theme.mq(
            { from: 'l', },
            {
              ...borderTop('1px', 0, 'solid', theme.color('neutral', '-3')),
              ...borderBottom('1px', 0, 'solid', theme.color('neutral', '-3')),
            }
          ),
          theme.mq(
            { from: 'xl', },
            { gridAutoFlow: 'column', gridAutoColumns: '1fr', }
          ),
          theme.mq({ from: 'l', until: 'xl', }, { gridAutoRows: '1fr', }),
        ],
      })}
    >
      <Stock {...stocks[0]} isFirst />
      <Stock {...stocks[1]} hideOnM />
      <Stock {...stocks[2]} />
      <Stock {...stocks[3]} />
      <Stock {...stocks[4]} hideOnM />
    </div>
  );
}

// /////////////////////////////////////////////////////////////////////
//                          STYLE and UTILS                           //
// /////////////////////////////////////////////////////////////////////

function numToHebrewString(num: number): string {
  return num.toLocaleString('he', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
