// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { AdSlotType, } from '../../../../flowTypes/AdSlotType';

import AdSlot from '../../../AdManager/AdSlot';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Picture from '../../../Image/Picture';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import setBiAction from '../../../../utils/setBiAction';
import useMedia from '../../../../hooks/useMedia';
import getPictureAssets from '../../../../utils/getPictureAssets';

const teasers = [ Teaser1, Teaser2, Teaser2, ];
Morbo.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

export default function Morbo({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ListViewProps): React.Node {
  const { theme, } = useFela();

  const { items, banners, extraLinks, ...restOfList } = list || {};
  if (!items || !items.length) return null;
  const adSlotData = banners?.adSlots && banners.adSlots[0];

  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        biAction: setBiAction(i, data, biAction),
        isLazyloadImages,
        theme,
      });
    }
    return null;
  }

  return (
    <ListView
      colTemplate={[
        { until: 's', value: '1fr', },
        { from: 's', until: 'l', value: 'repeat(2, 1fr)', },
        { from: 'l', until: 'xl', value: 'repeat(4, 1fr)', },
        { from: 'xl', value: 'repeat(6, 1fr)', },
      ]}
      rowGap={[ { until: 's', value: '2rem', }, { from: 's', value: '1rem', }, ]}
      colGap={[ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ]}
      gridGrap={null}
      attrs={{
        'data-test': 'morbo',
      }}
      // sectionMiscStyles={{ display: [ { until: 's', value: 'none', }, ], }}
    >
      {/* Header */}
      <ListViewHeader
        {...restOfList}
        extraLinks={null}
        isHorizontal
        biAction={biAction}
        isSticky
        miscStyles={{ gridArea: 'auto', gridColumn: '1/-1', }}
      />
      <Section isFragment>
        {/* Items */}
        {items.map(itemRenderer)}
        {/* Banner */}
        {adSlotData ? DfpItem({ data: adSlotData, loadPriority: list.loadPriority, }) : null}
      </Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                         Teaser Components                          //
// /////////////////////////////////////////////////////////////////////

type TeaserPropsType = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  theme: Object,
};

const teaserDefaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

Teaser1.defaultProps = teaserDefaultProps;
function Teaser1({ data, isLazyloadImages, biAction, theme, }: TeaserPropsType): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      data={data}
      onClick={biAction}
      areasTemplate={`
      "media topGap    endGap"
      "media content   endGap"
      "media footer    endGap"
      "media bottomGap endGap"
      `}
      colTemplate={[
        { until: 's', value: '18rem 1fr 0', },
        { from: 's', until: 'xl', value: '27rem 1fr 0', },
        { from: 'xl', value: '25rem 1fr 0', },
      ]}
      rowTemplate="0 1fr auto 0"
      gridGap="1rem"
      miscStyles={{
        ...theme.mq({ from: 's', }, { gridColumnEnd: 'span 2', }),
      }}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          onClick={biAction}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: '108px',
              aspect: 'square',
              widths: [ 108, ],
            },
            sources: [
              {
                aspect: 'regular',
                from: 's',
                sizes: [
                  { from: 's', size: '175px', },
                ],
                widths: [ 175, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        {...data}
        onClick={biAction}
        typeScale={[ { until: 'xl', value: 0, }, { from: 'xl', value: -1, }, ]}
        isGridItem
      />
      <TeaserFooter data={data} />
    </Teaser>
  );
}

Teaser2.defaultProps = teaserDefaultProps;
function Teaser2({ data, biAction, }: TeaserPropsType): React.Node {
  return (
    <Teaser
      key={data.contentId}
      data={data}
      onClick={biAction}
      areasTemplate={`
        "topGap      topGap     topGap"
        "startGap    content    endGap"
        "startGap    footer     endGap"
        "bottomGap   bottomGap  bottomGap"
      `}
      colTemplate="0 1fr 0"
      rowTemplate="0 1fr auto 0"
      gridGap="1rem"
    >
      <TeaserHeader
        {...data}
        typeScale={[ { until: 'xl', value: 0, }, { from: 'xl', value: -1, }, ]}
        onClick={biAction}
        isGridItem
      />
      <TeaserFooter data={data} />
    </Teaser>
  );
}

type DfpItemProps = { data: AdSlotType, loadPriority?: 'client' | 'lazy' | 'ssr', };
DfpItem.defaultProps = {
  loadPriority: null,
};
function DfpItem({ data, loadPriority, }: DfpItemProps): React.Node {
  const { css, theme, } = useFela();
  const wrapperClasses = css({
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    extend: [
      theme.mq({ from: 's', }, { gridColumnEnd: 'span 2', }),
    ],
  });
  const matches = useMedia({ query: { from: 'xl', }, matchOnServer: false, });
  const matchesS = useMedia({ query: { until: 's', }, matchOnServer: false, });

  return (
    <div className={wrapperClasses}>
      {matches || matchesS ? (
        <AdSlot {...data} />
      ) : null}
    </div>
  );
}
