// @flow
import React from 'react';
import { useFela, } from 'react-fela';

import type { Node, } from 'react';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import type { ClickTrackerBannerWrapperType, } from '../../../../flowTypes/ClickTrackerBannerWrapperType';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserLabelDisclaimer from '../../../TeaserLabelDisclaimer/TeaserLabelDisclaimer';
import Image from '../../../Image/Image';
import Debug from '../../../Debug/Debug';
import BlockLink from '../../../BlockLink/BlockLink';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import setBiAction from '../../../../utils/setBiAction';

type Props = {
  list: ListDataType,
  biAction: ?ListBiActionType,
  isLazyloadImages: boolean,
};

type ItemProps = {
  item: ClickTrackerBannerWrapperType,
  index: number,
  biAction: ?ListBiActionType,
  isLazyloadImages: boolean,
  miscStyles: StyleProps,
  theme: Object,
};

Donatello.defaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

export default function Donatello({ list, biAction, isLazyloadImages = true, }: Props): Node {
  const { theme, } = useFela();

  const items: ?Array<ClickTrackerBannerWrapperType> = Array.isArray(list?.banners?.clickTrackerBanners) && list.banners.clickTrackerBanners.length > 4
    ? list.banners.clickTrackerBanners.slice(0, 5)
    : null;

  return items ? (
    list.title ? (
      <ListView
        colTemplate={[ { until: 'l', value: 'repeat(4, 1fr)', }, { from: 'l', value: 'repeat(6, 1fr)', }, ]}
        gridGap={null}
        colGap="4rem"
        rowGap="1rem"
        innerBackgroundColor="transparent"
        miscStyles={{ fontFamily: theme.fontStacks.commercial, }}
        sectionMiscStyles={{ display: [ { until: 's', value: 'none', }, ], }}
        attrs={{
          'data-test': 'donatello',
        }}
      >
        <ListViewHeader
          title={list.title}
          backgroundColor="transparent"
          isCommercial
          biAction={biAction}
          miscStyles={{
            justifyContent: [ { from: 's', until: 'l', value: 'space-between', }, { from: 'l', value: 'flex-start', }, ],
            gridArea: [ { until: 'l', value: '1 / 1 / 2 / 5', }, { from: 'l', value: '1 / 1 / 2 / 2', }, ], }}
        />
        <Section isFragment>
          {items.map((item: ClickTrackerBannerWrapperType, index) => {
            const miscStyles = index === 4
              ? { display: [ { until: 'l', value: 'none', }, { from: 'l', value: 'flex', }, ], }
              : {};
            return (
              <Item
                key={item.id}
                item={item}
                biAction={biAction}
                index={index}
                isLazyloadImages={isLazyloadImages}
                miscStyles={miscStyles}
                theme={theme}
              />
            );
          })}
        </Section>
      </ListView>
    ) : (
      <Debug>This element cannot be rendered without a title</Debug>
    )
  ) : (
    <Debug>There is not enough items to render this list view</Debug>
  );
}

const areasItem = `
"media  media  media"
".     content     ."
".        .        ."
".      label      ."
`;

Item.defaultProps = {
  isLazyloadImages: true,
  biAction: null,
};
function Item({ item, biAction, index, isLazyloadImages, miscStyles, theme, }: ItemProps): Node {
  return (
    <ClickTracker
      {...item}
      render={(banner: ClickTrackerBannerType) => {
        const { image, link, text, linkTarget, advertiser, } = banner;
        const onClick = setBiAction(index, banner, biAction);
        return (
          <BlockLink
            miscStyles={{
              display: 'flex',
              // HACK ALERT!!!
              // "0.1" in the line argument is to force a regular border
              // instead of an absolutely positioned pseudo element that
              // break the vertical rhythm.
              // We need this here because the image has a higher z-index
              // than the faux-border, and hides it.
              // This is a hack, do not use this.
              border: [ '1px', 0.1, 'solid', theme.color('neutral', '-4'), ],
              ...miscStyles,
            }}
            href={link}
            onClick={onClick}
            target={linkTarget}
          >
            <Teaser
              areasTemplate={areasItem}
              rowTemplate="auto 1fr 0 auto"
              colGap="1rem"
              rowGap="1rem"
              gridGap={null}
              backgroundColor={[ 'neutral', '-7', ]}
            >
              <TeaserMedia
                data={banner}
                onClick={onClick}
              >
                <Image
                  image={image}
                  lazyLoad={isLazyloadImages}
                  imgOptions={{
                    transforms: {
                      width: '180',
                      aspect: 'regular',
                      quality: 'auto',
                    },
                  }}
                />
              </TeaserMedia>
              <TeaserHeader
                isGridItem
                title={text || ''}
                path={link}
                typeScale={-2}
                onClick={onClick}
              />
              <TeaserLabelDisclaimer
                companyName={advertiser || ''}
                miscStyles={{
                  paddingBottom: '1rem',
                  position: 'relative',
                  zIndex: 1,
                }}
              />
            </Teaser>
          </BlockLink>
        );
      }}
    />
  );
}
