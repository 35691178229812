// @flow
import React from 'react';
import { LRRR2_LIST_QUERY, } from '@haaretz/graphql';

import type { Node, } from 'react';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

import Lrrr2View from './Lrrr2View.js';
import ListWrapper from '../../ListWrapper';

type Props = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
};

export default function Lrrr2(props: Props): Node {
  return (
    <ListWrapper {...props} query={LRRR2_LIST_QUERY} view="Lrrr2">
      {dataProps => <Lrrr2View {...dataProps} />}
    </ListWrapper>
  );
}
