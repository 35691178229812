/* global window OBR document navigator */
// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import config from 'config';
import { appendScript, } from '../../utils/scriptTools';
import useOneTime from '../../hooks/useOneTime';
import useMedia from '../../hooks/useMedia';
import { useUser, } from '../User/UserDispenser';


declare var OBR: Object;
type Props = {
  widgetId: string,
}


function OutbrainHomepage({ widgetId, }: Props) {
  const { css, } = useFela();
  const { user, } = useUser();
  const isMobile = useMedia({ query: { until: 's', }, matchOnServer: false, });


  const isPayingUser = user?.type === 'paying';


  React.useEffect(() => {
    if (isPayingUser && window.OB_ContextKeyValue !== 'subscribed') window.OB_ContextKeyValue = 'subscribed';
    else if (window.OB_ContextKeyValue) window.OB_ContextKeyValue = undefined;

    return () => {
      window.OB_ContextKeyValue = undefined;
    };
  }, [ isPayingUser, ]);

  // eslint-disable-next-line consistent-return
  useOneTime(isMobile, () => {
    const id = 'homepageOutbrain';
    appendScript({
      src: 'https://widgets.outbrain.com/outbrain.js',
      id,
      isAsync: false,
      updateFunction: () => {
        if (typeof OBR !== 'undefined') {
          OBR.extern.reloadWidget();
        }
      },
    });
    return () => {
      const scriptEl = document.querySelector(`script#${id}`);
      if (scriptEl && typeof scriptEl.remove === 'function') { scriptEl.remove(); }
    };
  });
  if (!isMobile) return null;

  const className = css({
    padding: '0 2rem',
    marginTop: '6rem',
  });

  const site = config.get('domain');
  const dataObTemplate = site.includes('themarker.com')
    ? 'Themarker.com_Mobile'
    : site.includes('haaretz.com')
      ? 'Haaretz.com_Mobile'
      : 'Haaretz.co.il_Mobile';

  return (
    <div className={`OB-REACT-WRAPPER ${className}`}>
      <div
        className="OUTBRAIN"
        data-src={`https://www.${site}`}
        data-widget-id={widgetId}
        data-ob-template={dataObTemplate}
      />
    </div>
  );
}

export default OutbrainHomepage;
