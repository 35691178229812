// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import IconAvatar from '../../../Icon/icons/IconAvatar';
import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserAuthors from '../../../TeaserAuthors/TeaserAuthors';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';
import { QuoteTeaser, } from '../Spawn/SpawnView';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';
import AdSlot from '../../../AdManager/AdSlot';

// ////////////////////////////////////////////////////////////////// //
//                               Styles                               //
// ////////////////////////////////////////////////////////////////// //

// make sure these have the same sizes for avatar alignment

const avatarSize = [
  { until: 's', value: '15rem', },
  { from: 's', until: 'xl', value: '16rem', },
  { from: 'xl', value: '17rem', },
];

const avatarSizeIcon = [
  { until: 's', value: 15, },
  { from: 's', until: 'xl', value: 16, },
  { from: 'xl', value: 17, },
];

const stackedTeaserVerticalAlign = [ { until: 's', value: 'center', }, ];
const stackedTeaserGridGap = [ { until: 'l', value: '1rem 2rem', }, { from: 's', value: '2rem', }, ];
const nonStackedTeaserGridGap = '1rem 2rem';

const mediaObjTeaserAreas = `
"media gap1"
"media content"
"media footer"
"media gap2"
`;
const stackedTeaserArea = '"media" "content" "footer"';
const stackedAboveMobileTeaserAreasTemplate = [
  { until: 's', value: mediaObjTeaserAreas, },
  { from: 's', value: stackedTeaserArea, },
];
const stackedAboveLargeTeaserAreasTemplate = [
  { until: 'l', value: mediaObjTeaserAreas, },
  { from: 'l', value: stackedTeaserArea, },
];

const nonStackedTeaserAreasTemplate = mediaObjTeaserAreas;
const stackedAboveMobileTeaserColTemplate = [ { until: 's', value: 'auto 1fr', }, ];
const stackedAboveLargeTeaserColTemplate = [ { until: 'l', value: 'auto 1fr', }, ];
const nonStackedTeaserColTemplate = 'auto 1fr';

const stackedTeaserRow = 'auto 1fr auto';
const mediaObjTeaserRow = '1fr auto auto 1fr';
const stackedAboveMobileTeaserRowTemplate = [
  { until: 's', value: mediaObjTeaserRow, },
  { from: 's', value: stackedTeaserRow, },
];
const stackedAboveLargeTeaserRowTemplate = [
  { until: 's', value: mediaObjTeaserRow, },
  { from: 's', value: stackedTeaserRow, },
];
const nonStackedTeaserRowTemplate = mediaObjTeaserRow;
const areasTemplate = [
  {
    until: 's',
    value: `
    "he"
    "op-ed1"
    "op-ed2"
    "op-ed3"
    "quote"
    "op-ed4"
    "op-ed5"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "he        he        he       he       he        he"
    "op-ed1    op-ed1    op-ed2   op-ed2   op-ed3    op-ed3"
    "op-ed4    op-ed4    op-ed4   op-ed4   quote     quote"
    "op-ed5    op-ed5    op-ed5   op-ed5   quote     quote"
    `,
  },
  {
    from: 'l',
    value: `
    "he op-ed1 op-ed2 op-ed3 op-ed4 op-ed5"
    `,
  },
];

// ////////////////////////////////////////////////////////////////// //
//                             Container                              //
// ////////////////////////////////////////////////////////////////// //

function ModifiedQuoteTeaser(props) {
  return <QuoteTeaser {...props} miscStyles={{ display: [ { from: 'l', value: 'none', }, ], }} />;
}
const teasers = [
  // Comic,
  // Editorial,
  OpEdTeaser,
  OpEdTeaser,
  OpEdTeaser,
  OpEdTeaser,
  OpEdTeaser,
  ModifiedQuoteTeaser,
];

const teaserGridAreaNames = [ 'op-ed1', 'op-ed2', 'op-ed3', 'op-ed4', 'op-ed5', 'quote', ];

Hypnotoad.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};
export default function Hypnotoad({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);
  const { items, extraLinks, banners, rainbowTargetSlot, ...restOfList } = list || {};
  if (!(items && items.length)) return null;
  const adSlotData = banners && banners?.adSlots?.length > 0 && banners.adSlots[0];

  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        /**
         * Is the teaser at position `i` stacked on all
         * breakpoints except the default one (mobile)
         */
        isStackedAboveLarge: true,
        isStackedAboveMobile: i <= 2,
        hideUnderLarge: i >= 5,
        gridArea: teaserGridAreaNames[i],
        biAction: setBiAction(i, data, biAction),
        isLazyloadImages,
        theme,
        attrs: isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {},
      });
    }
    return null;
  }

  const itemsToRender = items.slice(0, 6);
  return (
    <React.Fragment>
      <ListView
        outerBackgroundColor={[ 'neutral', '-3', ]}
        innerBackgroundColor={[
          { until: 's', value: [ 'layout', 'rowBg', ], },
          { from: 's', value: [ 'neutral', '-2', ], },
        ]}
        areasTemplate={areasTemplate}
        padding={[ { until: 's', value: '0rem 2rem', }, { from: 's', value: '4rem 4rem', }, ]}
        colTemplate={[
          { from: 's', until: 'l', value: '1fr 1fr 1fr 1fr 1fr 1fr', },
          { from: 'l', value: '1fr 1fr 1fr 1fr 1fr 1fr', },
        ]}
        attrs={{
          'data-test': 'hypnotoad',
        }}
      >
        <ListViewHeader
          {...restOfList}
          extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
          biAction={biAction}
          customColor={[ 'primary', '-2', ]}
          customMobileColor={[ 'bodyText', ]}
          extraLinksMiscStyles={{
            color: theme.color('white'),
            ':hover': {
              color: theme.color('neutral', '-5'),
            },
            ':visited': { color: theme.color('white'), },
            fontWeight: 700,
          }}
          isSticky
        />
        { rainbowTargetSlot
          ? (
            <RainbowListPaywallSlot
              id={rainbowTargetSlot}
              gridArea={[
                { until: 'l', value: '2/1/-1/-1', },
                { from: 'l', value: '1/2/-1/-1', },
              ]}
              onToolRendered={onRainbowToolRendered}
            />
          )
          : null}
        <Section isFragment>{itemsToRender.map(itemRenderer)}</Section>
      </ListView>
      {adSlotData && (
        <AdSlot
          {...adSlotData}
          loadPriority={list.loadPriority}
          wrapperMiscStyles={{
            marginTop: [ { from: 's', value: '8rem', }, { until: 's', value: '6rem', }, ],
            width: '100%',
          }}
        />
      )}
    </React.Fragment>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

type TeaserPropTypes = {
  biAction: ?() => void,
  data: TeaserDataType,
  gridArea: string,
  isLazyloadImages: boolean,
  theme: Object,
};

type OpEdProps = TeaserPropTypes & {
  isStackedAboveLarge: boolean,
  isStackedAboveMobile: boolean,
  hideUnderLarge: boolean,
};
OpEdTeaser.defaultProps = { isLazyloadImages: true, };
function OpEdTeaser({
  biAction,
  data,
  gridArea,
  isLazyloadImages,
  isStackedAboveLarge,
  isStackedAboveMobile,
  hideUnderLarge,
  theme,
}: OpEdProps): React.Node {
  const areas = isStackedAboveMobile
    ? stackedAboveMobileTeaserAreasTemplate
    : isStackedAboveLarge
      ? stackedAboveLargeTeaserAreasTemplate
      : nonStackedTeaserAreasTemplate;
  const colTemplate = isStackedAboveMobile
    ? stackedAboveMobileTeaserColTemplate
    : isStackedAboveLarge
      ? stackedAboveLargeTeaserColTemplate
      : nonStackedTeaserColTemplate;
  const rowTemplate = isStackedAboveMobile
    ? stackedAboveMobileTeaserRowTemplate
    : isStackedAboveLarge
      ? stackedAboveLargeTeaserRowTemplate
      : nonStackedTeaserRowTemplate;
  const horizontalAlign = isStackedAboveMobile
    ? [ { from: 's', value: 'center', }, ]
    : isStackedAboveLarge
      ? [ { from: 'l', value: 'center', }, ]
      : undefined;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={areas}
      colTemplate={colTemplate}
      rowTemplate={rowTemplate}
      gridGap={
        isStackedAboveMobile || isStackedAboveLarge ? stackedTeaserGridGap : nonStackedTeaserGridGap
      }
      miscStyles={{
        padding: [
          { until: 's', value: '2rem', },
          {
            from: 's',
            value: isStackedAboveMobile ? '2rem 2rem 1rem' : '1rem 2rem',
          },
          {
            from: 'l',
            value: isStackedAboveLarge ? '2rem 2rem 1rem' : '1rem 2rem',
          },
        ],
        alignItems:
          isStackedAboveMobile || isStackedAboveLarge ? stackedTeaserVerticalAlign : 'center',
        textAlign: horizontalAlign,
        justifyItems: horizontalAlign,
        ...(hideUnderLarge ? { display: [ { until: 'l', value: 'none', }, ], } : {}),
      }}
    >
      <TeaserMedia
        data={data}
        onClick={biAction}
        miscStyles={{
          backgroundColor: theme.color('neutral', '-6'),
          borderRadius: '50%',
          height: avatarSize,
          overflow: 'hidden',
          paddingTop: '1rem',
          width: avatarSize,
        }}
      >
        {image?.type === 'image' ? (
          <Image
            image={image}
            imgOptions={getImageAssets({
              bps: theme.bps,
              aspect: 'square',
              sizes: [ { size: '119px', }, ],
              widths: [ 180, 119, ],
            })}
            lazyLoad={isLazyloadImages}
            miscStyles={{ backgroundColor: theme.color('neutral', '-6'), }}
          />
        ) : (
          <IconAvatar
            size={avatarSizeIcon}
            color={[ 'neutral', '-4', ]}
            miscStyles={{
              transform: 'translateY(.1em)',
            }}
          />
        )}
      </TeaserMedia>
      <TeaserContent>
        {data.credit ? (
          <TeaserAuthors
            authors={data.credit}
            miscStyles={{
              color: theme.color('secondary'),
              fontWeight: 'bold',
              display: 'block',
              type: [
                { until: 's', value: -2, },
                { until: 'xl', value: -1, },
                { from: 'xl', value: -2, },
              ],
            }}
          />
        ) : null}
        <TeaserHeader
          showKicker={false}
          {...data}
          typeScale={[ { until: 's', value: 0, }, { from: 'xl', value: -1, }, ]}
          onClick={biAction}
        />
      </TeaserContent>
      <TeaserFooter data={data} />
    </Teaser>
  );
}
