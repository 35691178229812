// @flow
import * as React from 'react';
import { ANTONIOC_LIST_QUERY, } from '@haaretz/graphql';
import AntonioCView from './AntonioCView.js';
import ListWrapper from '../../ListWrapper';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
};

export default function AntonioC(props: Props): React.Node {
  return (
    <ListWrapper {...props} query={ANTONIOC_LIST_QUERY} view="AntonioC">
      {dataProps => <AntonioCView {...dataProps} />}
    </ListWrapper>
  );
}
