// @flow
import * as React from 'react';

import Picture from '../../../Image/Picture';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getPictureAssets from '../../../../utils/getPictureAssets';
import useMedia from '../../../../hooks/useMedia';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type Hermes2ItemProps = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  hideImageOnMobile: boolean,
  biAction: ?() => void,
  theme: Object,
  gridArea: string,
};

Hermes2Item.defaultProps = {
  isLazyloadImages: true,
  hideImageOnMobile: false,
};

const areasMediaObjTeaser = `
"media . content"
"media . footer"
`;
const areasStackedTeaser = `
".    .    ."
". content ."
".    .    ."
". footer  ."
".   .     ."
`;
const areasMediaObjTeaserM = `
" media "
"   .   "
"content"
"   .   "
"footer "
"   .   "
`;

export default function Hermes2Item({
  data,
  isLazyloadImages,
  hideImageOnMobile,
  biAction,
  theme,
  gridArea,
}: Hermes2ItemProps): React.Node {
  // TODO: check if breakpoints in use
  // const isMobile = useMedia({ query: { until: 's', }, matchOnServer: false, });
  // const untilMediumBP = useMedia({ query: { until: 'l', }, matchOnServer: false, });

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      data={data}
      onClick={biAction}
      gridArea={gridArea}
      backgroundColor={[ 'neutral', '-7', ]}
      colGap={[ { until: 'l', value: '1rem', }, { from: 'l', value: '2rem', }, ]}
      rowGap={[ { from: 's', until: 'l', value: '1rem', }, ]}
      gridGap={null}
      areasTemplate={
          [
            { until: 's', value: areasStackedTeaser, },
            { from: 's', until: 'l', value: areasMediaObjTeaserM, },
            { from: 'l', value: areasMediaObjTeaser, },
          ]
      }
      colTemplate={[
        { until: 's', value: '0 1fr 0', },
        { from: 'l', until: 'xl', value: '1fr 0 1.4fr', },
        { from: 'xl', value: '1fr 0 1fr', },
      ]
      }
      rowTemplate={[
        {
          until: 's',
          value: '0 1fr 0 auto 0',
        },
        {
          from: 's',
          until: 'l',
          value: 'auto 0 1fr 0 auto',
        },
        { from: 'l', value: '1fr auto', },
      ]}
    >
      <TeaserMedia
        data={data}
        miscStyles={hideImageOnMobile ? { display: [ { until: 's', value: 'none', }, ], } : null}
        onClick={biAction}
      >
        {image ? (
          <Picture
            lazyLoad={isLazyloadImages}
            {...getPictureAssets({
              bps: theme.bps,
              imgData: image,
              defaultImgOptions: {
                sizes: '108px',
                aspect: 'square',
                widths: [ 108, 216, ],
              },
              sources: [
                {
                  from: 's',
                  until: 'l',
                  aspect: 'headline',
                  sizes: [
                    { from: 'xl', size: '280px', },
                    { from: 's', until: 'l', size: '320px', },
                    { from: 'l', size: '226px', },
                  ],
                  widths: [ 226, 276, 280, 310, 390, 552, 720, ],
                },
                {
                  from: 'l',
                  until: 'xl',
                  aspect: 'regular',
                  sizes: [
                    { from: 'xl', size: '280px', },
                    { from: 's', until: 'l', size: '320px', },
                    { from: 'l', size: '226px', },
                  ],
                  widths: [ 226, 276, 280, 310, 390, 552, 720, ],
                },
                {
                  from: 'xl',
                  aspect: 'headline',
                  sizes: [
                    { from: 'xl', size: '280px', },
                    { from: 's', until: 'l', size: '320px', },
                    { from: 'l', size: '226px', },
                    { from: 'm', size: '360px', },
                  ],
                  widths: [ 226, 276, 280, 310, 390, 552, 720, ],
                },
              ],
            })}
          />
        ) : null}
      </TeaserMedia>
      <TeaserHeader
        {...data}
        typeScale={[
          { until: 's', value: -1, },
          { from: 's', until: 'l', value: 1, },
          { from: 'l', value: -1, },
        ]}
        onClick={biAction}
        isGridItem
        wrapperMiscStyles={
          hideImageOnMobile
            ? { padding: [ { until: 's', value: '0 1rem', }, ], }
            : {
              paddingTop: [ { until: 's', value: '1rem', }, ],
              paddingInlineEnd: [ { until: 's', value: '1rem', }, ],
            }
        }
      />
      <TeaserFooter
        data={data}
        showAuthor
        showCommentsCount={false}
        miscStyles={{
          paddingInlineEnd: [ { until: 's', value: '1rem', }, ],
        }
        }
      />
    </Teaser>
  );
}
