import React from 'react';
import { borderTop, } from '@haaretz/htz-css-tools';
import { useFela, } from 'react-fela';
import HtmlNode from '../HtmlNode/HtmlNode';
import { useEventTracker, } from '../../utils/EventTracker';
import useOneTime from '../../hooks/useOneTime';

// eslint-disable-next-line react/prop-types
export default function CatchupListElement({ items, contentName, }) {
  const { theme, css, } = useFela();
  const { biAction, biImpression, } = useEventTracker();
  useOneTime(Array.isArray(items) && items.length > 0 && !!biImpression, () => {
    !!biImpression
      && biImpression({
        ListName: contentName || 'Top News',
        ViewName: contentName || 'Top News',
      });
  });

  return (
    <div
      className={css({
        paddingInlineEnd: '3rem',
        extend: [ theme.mq({ until: 'l', }, { display: 'none', }), ],
      })}
      data-test="catchupListElement"
    >
      <div
        className={css({
          fontWeight: '700',
          marginBottom: '2rem',
          extend: [
            theme.type(2),
            borderTop({
              lines: 2,
              width: 5,
              style: 'solid',
              color: theme.color('primary'),
            }),
          ],
        })}
      >
        {contentName || 'Top News'}
      </div>
      {items
        && items.map((item, i) => (
          <div className={css({ fontFamily: theme.fontStacks.alt, })}>
            {item.text.map((p, idx) => (
              <HtmlNode
                {...p}
                attributes={[
                  {
                    key: 'onClick',
                    value: e => {
                      biAction({
                        actionCode: 109,
                        ListName: 'Top News',
                        ViewName: 'Top News',
                        NoInList: i + 1,
                      });
                    },
                  },
                ]}
                miscStyles={{
                  marginBottom: '1rem',
                  type: [
                    { until: 'xl', value: -1, },
                    { from: 'xl', value: -2, },
                  ],
                  ...(!(
                    i === items.length - 1 && idx === item.text.length - 1
                  ) && {
                    borderBottom: [
                      '1px',
                      1,
                      'solid',
                      theme.color('neutral', '-6'),
                    ],
                  }),
                  '& a': {
                    borderBottomColor: theme.color('transparent'),
                    '&:hover': {
                      borderBottomColor: 'currentColor',
                    },
                  },
                }}
              />
            ))}
          </div>
        ))}
    </div>
  );
}
