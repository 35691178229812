// @flow
/* global window */
import * as React from 'react';
import { SLUGS_LIST_QUERY, } from '@haaretz/graphql';
import SlugsView from './SlugsView';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type SlugsWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
};

export default function SlugsWrapper(props: SlugsWrapperProps): React.Node {
  return (
    <ListWrapper
      {...props}
      query={SLUGS_LIST_QUERY}
      view="Slugs"
    >
      {dataProps => <SlugsView {...dataProps} />}
    </ListWrapper>
  );
}
