// @flow
import * as React from 'react';
// import { clickTrackerBannersWrapper, } from '@haaretz/app-utils';
import { LEONARDO_LIST_QUERY, } from '@haaretz/graphql';
import LeonardoView from './LeonardoView.js';
import ListWrapper from '../../ListWrapper';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type LeonardoWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function LeonardoWrapper(props: LeonardoWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={LEONARDO_LIST_QUERY} view="Leonardo">
      {dataProps => <LeonardoView {...dataProps} />}
    </ListWrapper>
  );
}
