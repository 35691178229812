// @flow
import { useFela, } from 'react-fela';
import * as React from 'react';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import Section from '../../../AutoLevels/Section';

import HtzLink from '../../../HtzLink/HtzLink';
import H from '../../../AutoLevels/H';
import Image from '../../../Image/Image';
import Picture from '../../../Image/Picture';
import ListView from '../../../ListView/NewListView';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import IconArrowDiagonal from '../../../Icon/icons/IconArrowDiagonal';
// import IconBack from '../../../Icon/icons/IconBack';
import getImageAssets from '../../../../utils/getImageAssets';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';

// ////////////////////////////////////////////////////////////////// //
//                             Container                              //
// ////////////////////////////////////////////////////////////////// //

const areasTemplate = [
  {
    until: 's',
    value: `
    "h"
    "t1"
    "t2"
    "t3"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "h   h"
    "t1 t2"
    "t3 t3"
    "t4 t4"
    `,
  },
  {
    from: 'l',
    value: `
    "h h t1 t1 t1 t2 t2 t2 t3 t3 t3 t3"
    "h h t1 t1 t1 t2 t2 t2 t4 t4 t4 t4"
    `,
  },
];
const teasers = [ TeaserA, TeaserA, TeaserB, TeaserB, ];
const teaserGridAreaNames = [ 't1', 't2', 't3', 't4', 'textual3', ];

type Props = ListViewProps & {
  noMargin?: boolean,
};

Mom.defaultProps = {
  gaAction: null,
  isLazyloadImages: true,
  noMargin: false,
};
export default function Mom({
  list,
  gaAction,
  biAction,
  isLazyloadImages,
  noMargin,
}: Props): React.Node {
  const { theme, css, } = useFela();
  const { title, description, items, url, } = list || {};

  if (!(items && items.length)) return null;

  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        gridArea: teaserGridAreaNames[i],
        biAction: setBiAction(i, data, biAction),
        isLazyloadImages,
        hideOnMobile: i > 2,
        showImageOnMobile: i === 0,
        theme,
      });
    }
    return null;
  }

  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[
        { from: 's', until: 'l', value: '1fr 1fr', },
        { from: 'l', value: 'repeat(12, 1fr)', },
      ]}
      innerBackgroundColor={[ 'primary', '-4', ]}
      gridGap={[ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ]}
      padding={[ { until: 's', value: '0 2rem 2rem', }, { from: 's', value: '4rem', }, ]}
      {...(noMargin
        ? {
          marginTop: '0',
        }
        : {})}
      attrs={{
        'data-test': 'mom',
      }}
    >
      <div className={css({ gridArea: 'h', })}>
        <HtzLink
          className={css({
            marginBottom: description ? '2rem' : '0',
            display: 'flex',
            alignItems: 'baseline',
          })}
          href={url}
        >
          <div
            className={css({
              paddingStart: '1rem',
              paddingEnd: '1rem',
              paddingTop: '0.5rem',
              paddingBottom: 'calc(0.5rem - 1px)',
              backgroundColor: theme.color('neutral', '-1'),
              color: theme.color('quaternary'),
              ...theme.mq(
                { from: 'l', until: 'xl', },
                {
                  paddingBottom: 'calc(0.5rem - 2px)',
                  paddingTop: 'calc(0.5rem - 2px)',
                }
              ),
            })}
          >
            <IconArrowDiagonal
              size={[
                { until: 'l', value: 3, },
                { from: 'l', until: 'xl', value: 16 / 6, },
                { from: 'xl', value: 3, },
              ]}
            />
          </div>
          <H
            className={css({
              position: 'relative',
              top: '1px',
              extend: [ theme.type(2, { untilBp: 'l', }), theme.type(1, { fromBp: 'l', }), ],
            })}
          >
            <span
              className={css({
                color: theme.color('neutral', '-1'),
                backgroundColor: theme.color('quaternary'),
                // boxDecorationBreak: 'clone',
                paddingInlineEnd: '0rem',
                paddingInlineStart: '0rem',
                extend: [
                  theme.mq(
                    { until: 'xl', },
                    {
                      paddingBottom: '1px',
                      paddingTop: '2px',
                    }
                  ),
                  theme.mq(
                    { from: 'xl', },
                    {
                      paddingBottom: '3px',
                      paddingTop: '5px',
                    }
                  ),
                ],
              })}
            >
              {title}
            </span>
          </H>
        </HtzLink>
        <p className={css({ extend: [ theme.type(-1), ], })}>{description}</p>
      </div>
      <Section isFragment>{items.slice(0, 4).map(itemRenderer)}</Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

type TeaserProps = {
  data: TeaserDataType,
  isLazyloadImages?: boolean,
  biAction: ?() => void,
  gridArea: string,
  hideOnMobile: boolean,
  showImageOnMobile: boolean,
  theme: Object,
};
const teaserDefaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

const headerTypeScale = [
  { until: 's', value: 0, },
  { from: 'm', until: 'l', value: 1, },
  { from: 'l', value: 0, },
];

const mediaObjTeaserAreas = `
  "media    .    ."
  "media content ."
  "media footer  ."
  "media    .    ."
`;

const stackedTeaserAreas = `
  "media media media"
  ".     content   ."
  ".     footer    ."
  ".       .       ."
`;

const textOnlyTeaserAreas = `
  ".    .    ."
  ". content ."
  ". footer  ."
  ".    .    ."
`;

TeaserA.defaultProps = teaserDefaultProps;
function TeaserA({
  data,
  gridArea,
  showImageOnMobile,
  isLazyloadImages,
  biAction,
  theme,
}: TeaserProps): React.Node {
  const itemId = data.representedContent == null ? data.contentId : data.representedContent;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      data={data}
      key={itemId}
      gridArea={gridArea}
      areasTemplate={[
        {
          until: 's',
          value: showImageOnMobile ? mediaObjTeaserAreas : textOnlyTeaserAreas,
        },
        { from: 's', value: stackedTeaserAreas, },
      ]}
      rowTemplate={[
        {
          until: 's',
          value: '0 1fr auto 0',
        },
        { from: 's', value: 'auto 1fr auto 0', },
      ]}
      onClick={biAction}
      colTemplate={[ { until: 's', value: showImageOnMobile ? '18rem 1fr 0' : '0 1fr 0', }, ]}
      colGap="2rem"
      rowGap="1rem"
      gridGap={null}
    >
      <TeaserMedia
        data={data}
        onClick={biAction}
        {...(showImageOnMobile
          ? {}
          : {
            miscStyles: {
              display: [ { until: 's', value: 'none', }, ],
            },
          })}
      >
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: '108px',
              aspect: 'square',
              widths: [ 108, 216, ],
            },
            sources: [
              {
                aspect: 'headline',
                from: 's',
                sizes: [
                  { from: 'xl', size: '281px', },
                  { from: 'l', size: '226px', },
                  { from: 'm', size: '348px', },
                  { from: 's', size: '264px', },
                ],
                widths: [ 348, 281, 264, 226, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        {...data}
        typeScale={[ { from: 'm', until: 'l', value: 1, }, ]}
        onClick={biAction}
        isGridItem
      />
      <TeaserFooter data={data} />
    </Teaser>
  );
}

const teaserBAreas = [
  {
    until: 's',
    value: textOnlyTeaserAreas,
  },
  {
    from: 's',
    until: 'l',
    value: `
    ".    .      .   ."
    ". media content ."
    ". media  footer ."
    ".    .      .   ."
    `,
  },
  {
    from: 's',
    value: `
    ".    .      .   ."
    ". content media ."
    ". footer  media ."
    ".    .      .   ."
    `,
  },
];
TeaserB.defaultProps = teaserDefaultProps;
function TeaserB({
  data,
  isLazyloadImages,
  biAction,
  gridArea,
  hideOnMobile,
  theme,
}: TeaserProps): React.Node {
  const itemId = data.representedContent == null ? data.contentId : data.representedContent;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      data={data}
      key={itemId}
      onClick={biAction}
      areasTemplate={teaserBAreas}
      colTemplate={[
        { until: 's', value: '0 1fr 0', },
        { from: 's', until: 'l', value: '0 13fr 30fr 0', },
        { from: 'l', value: '0 7fr 6fr 0', },
      ]}
      rowTemplate="0 1fr auto 0"
      gridArea={gridArea}
      rowGap={[
        { until: 's', value: '1rem', },
        { from: 's', until: 'l', value: '2rem', },
        { from: 'l', value: '1rem', },
      ]}
      colGap={[ { until: 'l', value: '2rem', }, { from: 'l', value: '1rem', }, ]}
      gridGap={null}
      miscStyles={{ display: [ { until: 's', value: hideOnMobile ? 'none' : undefined, }, ], }}
    >
      <TeaserMedia
        data={data}
        onClick={biAction}
        miscStyles={{
          alignSelf: 'center',
          display: [ { until: 's', value: 'none', }, ],
        }}
      >
        <Image
          lazyLoad={isLazyloadImages}
          image={image}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'regular',
            widths: [ 312, 207, 168, 135, ],
            sizes: [
              { from: 'xl', size: '168px', },
              { from: 'l', size: '135px', },
              { from: 'm', size: '207px', },
              { from: 's', size: '156px', },
              { size: '0px', },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader typeScale={headerTypeScale} {...data} onClick={biAction} isGridItem />
      <TeaserFooter data={data} />
    </Teaser>
  );
}
