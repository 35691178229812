// @flow
import React from 'react';

import type { Node, } from 'react';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import H from '../../../AutoLevels/H';
import HtzLink from '../../../HtzLink/HtzLink';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
import { BlockLinkInner, } from '../../../BlockLink/BlockLink';
import Picture from '../../../Image/Picture';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getPictureAssets from '../../../../utils/getPictureAssets';
import type { attrFlowType, } from '../../../../flowTypes/attrTypes';


type TeasersPropsType = {
  data: TeaserDataType,
  theme: Object,
  css: (Object | (Object => Object)) => string,
  biAction: ?() => void,
  isLazyloadImages: boolean,
  attrs?: ?attrFlowType,
};

const areasTeaser1N2 = `
"media media media"
".    content    ."
".    footer     ."
".       .       ."
`;

Teaser1N2.defaultProps = {
  attrs: null,
};

function Teaser1N2({
  data,
  theme,
  biAction,
  isLazyloadImages,
  attrs,
}: TeasersPropsType): Node {
  const image = data?.mobileImage || data?.image;

  const pictureProps = getPictureAssets({
    bps: theme.bps,
    imgData: image,
    defaultImgOptions: {
      aspect: 'regular',
      sizes: 'calc(50vw - 30px)',
      widths: [ 380, 189, ],
    },
    sources: [
      {
        from: 's',
        aspect: 'headline',
        sizes: [
          { from: 'xl', size: '281px', },
          { from: 'l', size: '226px', },
          { from: 'm', size: '348px', },
          { size: '264px', },
        ],
        widths: [ 348, 281, 226, ],
      },
    ],
  });
  return (
    <Teaser
      areasTemplate={areasTeaser1N2}
      rowTemplate="auto 1fr auto 0"
      colGap={[ { until: 's', value: '1rem', }, { from: 's', value: '2rem', }, ]}
      rowGap="1rem"
      gridGap={null}
      attrs={attrs}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...pictureProps}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={theme.weekendStyle.teaser1N2.headerTypeScale}
        onClick={biAction}
        {...data}
        kickerIsBlock
        kickerMiscStyles={{
          color: theme.color('Weekend', 'exclusive'),
          display: [ { until: 's', value: 'none', }, ],
        }}
        wrapperMiscStyles={{
          textAlign: [ { until: 's', value: 'start', }, { from: 's', value: 'center', }, ],
          paddingBottom: [ { until: 's', value: '1rem', }, ],
          ...(theme.weekendStyle.teaser1N2.headerWrapperMiscStyles),
        }}
        kickerInnerMiscStyles={{ backgroundColor: 'transparent', }}
        kickerTypeScale={[
          { until: 'xl', value: -1, },
          { from: 'xl', value: -2, },
        ]}
      />
      <TeaserFooter
        data={data}
        showAuthor
        showRank
        showTime={false}
        typeScale={[
          { until: 's', value: -3, },
          { from: 's', until: 'xl', value: -2, },
          { from: 'xl', value: -3, },
        ]}
        miscStyles={{
          textAlign: [ { until: 's', value: 'start', }, { from: 's', value: 'center', }, ],
        }}
      />
    </Teaser>
  );
}


Teaser3.defaultProps = {
  attrs: null,
};

function Teaser3({
  data,
  theme,
  biAction,
  isLazyloadImages,
  attrs,
}: TeasersPropsType): Node {
  const image = data?.mobileImage || data?.image;

  const pictureProps = getPictureAssets({
    bps: theme.bps,
    imgData: image,
    defaultImgOptions: {
      aspect: 'headline',
      sizes: 'calc(100vw - 24px)',
      widths: [ 700, 390, 351, ],
    },
    sources: [
      {
        aspect: 'vertical',
        from: 's',
        sizes: [
          { from: 'xl', size: '281px', },
          { from: 'l', size: '226px', },
          { from: 'm', size: '348px', },
          { size: '264px', },
        ],
        widths: [ 348, 281, 226, ],
      },
    ],
  });
  return (
    <Teaser
      gridArea="t3"
      areasTemplate={theme.weekendStyle.teaser3.areasTemplate}
      rowTemplate={theme.weekendStyle.teaser3.rowTemplate}
      colTemplate="0 1fr 0"
      colGap="2rem"
      rowGap="1rem"
      gridGap={null}
      attrs={attrs}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...pictureProps}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        onClick={biAction}
        typeScale={1}
        {...data}
        kickerIsBlock
        kickerMiscStyles={{
          color: theme.color('Weekend', 'exclusive'),
          paddingBottom: [ { until: 's', value: '1rem', }, ],
        }}
        // Kicker's default background color in block mode is red (#A8001C)
        kickerInnerMiscStyles={{ backgroundColor: 'transparent', }}
        kickerTypeScale={[
          { until: 'xl', value: -1, },
          { from: 'xl', value: -2, },
        ]}
        wrapperMiscStyles={{
          textAlign: 'center',
          ...theme.weekendStyle.teaser3.headerWrapperMiscStyles,
        }}
      />
      <TeaserFooter
        data={data}
        showAuthor
        showRank
        showTime={false}
        miscStyles={{
          textAlign: 'center',
          justifyContent: 'center',
          ...theme.weekendStyle.teaser3.footer.miscStyles,
        }}
      />
    </Teaser>
  );
}

Teaser4.defaultProps = {
  attrs: null,
};

function Teaser4({
  data,
  theme,
  css,
  biAction,
  isLazyloadImages,
  attrs,
}: TeasersPropsType): Node {
  const image = data?.mobileImage || data?.image;

  const pictureProps = getPictureAssets({
    bps: theme.bps,
    imgData: image,
    defaultImgOptions: {
      aspect: 'square',
      sizes: 'calc(100vw - 24px)',
      widths: [ 700, 390, 351, ],
    },
    sources: [
      {
        from: 's',
        aspect: 'headline',
        sizes: [
          { from: 'xl', size: '590px', },
          { from: 'l', size: '476px', },
          { from: 'm', size: '720px', },
          { size: '552px', },
        ],
        widths: [ 720, 590, 552, 476, ],
      },
    ],
  });
  return (
    <div
      {...(attrs || {})}
      className={css({
        // Hide white strip when it overflows from the bottom
        // because of subpixel rendering
        overflow: 'hidden',
        // TODO: Remove `height: fit-content` when and if Chrome issue resolves.
        // This is set to deal with faulty `fr` calculations when a grid item has `overflow`
        // set to anything but `visible` (Relevant to Chrome versions 80 and above).
        height: 'fit-content',
        position: 'relative',
        gridArea: 't4',
      })}
    >
      <Picture
        lazyLoad={isLazyloadImages}
        {...pictureProps}
      />
      <div
        // Copied off of boxyItem, with slight modifications.
        className={css({
          position: 'absolute',
          zIndex: '2',
          start: '0',
          extend: [
            // The text is outside the flow of the verticle rhythm,
            // so it is okay to use uneven numbers for `lines` to
            // keep the background lines nice
            theme.type(2, { lines: 5, untilBp: 's', }),
            theme.type(1, { lines: 4.5, fromBp: 's', untilBp: 'm', }),
            theme.type(2, { lines: 5, fromBp: 'm', untilBp: 'l', }),
            theme.type(1, { lines: 4.5, fromBp: 'l', untilBp: 'xl', }),
            theme.weekendStyle.teaser4.lineBgMiscStyles({ theme, }),
            theme.mq({ until: 'l', }, { bottom: '-1px', }),
            theme.mq({ from: 'l', }, { bottom: '0', }),
            theme.mq({ until: 's', }, { maxWidth: '85%', }),
            theme.mq({ from: 's', until: 'xl', }, { maxWidth: '66%', }),
            theme.mq({ from: 'xl', }, { maxWidth: '76%', }),
          ],
        })}
      >
        <H className={css({
          backgroundColor: theme.color('white'),
          display: 'inline',
          boxDecorationBreak: 'clone',
          paddingInlineStart: '1rem',
          paddingInlineEnd: '1rem',
          paddingBottom: '4px',
          paddingTop: '4px',
          opacity: '0.88',
          ...theme.weekendStyle.teaser4.hMiscStyles,
        })}
        >
          <HtzLink href={data.path} onClick={biAction}>
            <TeaserResponsiveText
              text={data.title}
              mobileText={data.titleMobile}
            />
          </HtzLink>
        </H>
      </div>
      <BlockLinkInner href={data.path} onClick={biAction} />
    </div>
  );
}

Teaser5.defaultProps = {
  attrs: null,
};

function Teaser5({
  data,
  theme,
  biAction,
  isLazyloadImages,
  attrs,
}: TeasersPropsType): Node {
  const image = data?.mobileImage || data?.image;

  const pictureProps = getPictureAssets({
    bps: theme.bps,
    imgData: image,
    defaultImgOptions: {
      aspect: 'headline',
      sizes: 'calc(100vw - 24px)',
      widths: [ 700, 390, 351, ],
    },
    sources: [
      {
        from: 's',
        aspect: 'regular',
        sizes: [
          { from: 'xl', size: '305px', },
          { from: 'l', size: '249px', },
          { from: 'm', size: '367px', },
          { size: '277px', },
        ],
        widths: [ 367, 305, 277, 249, ],
      },
    ],
  });
  return (
    <Teaser
      gridArea="t5"
      areasTemplate={theme.weekendStyle.teaser5.areasTemplate}
      colTemplate={[
        { until: 's', value: '0 1fr 0', },
        { from: 's', until: 'l', value: '1rem 15fr 0 13fr 1rem', },
        { from: 'l', until: 'xl', value: '1rem 13fr 0 10fr 1rem', },
        { from: 'xl', value: '1rem 5fr 0 4fr 1rem', },
      ]}
      gridGap={null}
      rowGap="1rem"
      colGap={[ { until: 's', value: '2rem', }, { from: 's', value: '1rem', }, ]}
      miscStyles={{
        textAlign: [ { until: 's', value: 'center', }, ],
      }}
      attrs={attrs}
    >
      <TeaserMedia
        data={data}
        onClick={biAction}
        miscStyles={{ alignSelf: 'center', }}
      >
        <Picture
          lazyLoad={isLazyloadImages}
          {...pictureProps}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={theme.weekendStyle.teaser5.headerTypeScale}
        onClick={biAction}
        {...data}
        kickerIsBlock
        kickerMiscStyles={{
          color: theme.color('Weekend', 'exclusive'),
          paddingBottom: [ { until: 's', value: '1rem', }, ],
        }}
        kickerInnerMiscStyles={{
          backgroundColor: 'transparent',
          paddingInlineStart: 0,
        }}
        kickerTypeScale={[
          { until: 'xl', value: -1, },
          { from: 'xl', value: -2, },
        ]}
        wrapperMiscStyles={theme.weekendStyle.teaser5.headerWrapperMiscStyles}
      />
      <TeaserFooter
        data={data}
        showAuthor
        showRank
        showTime={false}
      />
    </Teaser>
  );
}

export default [ Teaser1N2, Teaser1N2, Teaser3, Teaser4, Teaser5, ];
