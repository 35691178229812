// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import {
  RainbowDataProvider,
  RainbowSlot,
  LayoutRow,
  LayoutContainer,
  BIRequest,
  useGetComponent,
  useScrollYPosition,
  useSlotData,
} from '@haaretz/htz-components';

import HeaderSlot from './HeaderSlot';
import MainSlot from './MainSlot';

function PreHeader({ children, }: { children: React.ChildrenArray<React.Element<any>>, }) {
  const { theme, } = useFela();
  const [ passedScrollThreshold, setPassedScrollThreshold, ] = React.useState(false);
  const { y, } = useScrollYPosition();
  const rowRef = React.useRef(null);
  React.useEffect(() => {
    const dfpOverMaxHeight = rowRef && rowRef.current && rowRef.current.clientHeight > 150;
    if (dfpOverMaxHeight) {
      setPassedScrollThreshold(true);
    }
    if (!passedScrollThreshold && y > 400) {
      setPassedScrollThreshold(true);
    }
  }, [ passedScrollThreshold, y, ]);


  return (
    <LayoutRow
      elementRef={rowRef}
      miscStyles={
        passedScrollThreshold
          ? {}
          : {
            position: [ { until: 's', value: 'sticky', }, ],
            top: [ { until: 's', value: 0, }, ],
            zIndex: theme.getZIndex('masthead', 1),
          }
      }
    >
      {children}
    </LayoutRow>
  );
}
type Props = {
  globalLazyload: number,
  slots: {
    preHeader: {
      inputTemplate: string,
      contentId: string,
      properties: {},
    }[],
    header: { inputTemplate: string, contentId: string, properties: {}, }[],
    postHeader: { inputTemplate: string, contentId: string, properties: {}, }[],
    postMain: { inputTemplate: string, contentId: string, properties: {}, }[],
    footer: { inputTemplate: string, contentId: string, properties: {}, }[],
    main: { inputTemplate: string, contentId: string, properties: {}, }[],
  },
};

function HomePageSlotsLayout({
  globalLazyload,
}: Props): React.Node {
  const { theme, } = useFela();
  const getComponent = useGetComponent();
  const preHeader = useSlotData('preHeader');
  const header = useSlotData('header');
  const postHeader = useSlotData('postHeader');
  const postMain = useSlotData('postMain');
  const footer = useSlotData('footer');

  const extractElements = slot => slot.map(element => {
    const Element = getComponent(element.inputTemplate);
    const { properties, ...elementWithoutProperties } = element;

    if (element.inputTemplate === 'PageFooter') {
      return (
        <Element
          key={elementWithoutProperties.contentId}
          {...elementWithoutProperties}
          {...properties}
          shouldRenderScripts
        />
      );
    }
    return (
      <Element
        key={elementWithoutProperties.contentId}
        {...elementWithoutProperties}
        {...properties}
      />
    );
  });

  return (
    <React.Fragment>
      <RainbowDataProvider>
        <RainbowSlot id="top-strip" />
        <BIRequest pageType="Home" />
        {preHeader ? <PreHeader>{extractElements(preHeader)}</PreHeader> : null}
        {/* Layout row is inside HeaderSlot Component because its miscStyles depend on state */}
        <HeaderSlot content={header} />
        {postHeader ? (
          <LayoutRow>
            <LayoutContainer>{extractElements(postHeader)}</LayoutContainer>
          </LayoutRow>
        ) : null}
        <LayoutRow
          tagName="main"
          id="pageRoot"
          miscStyles={{
            backgroundColor: theme.color('layout', 'rowBg'),
            flexGrow: 1,
            paddingBottom: [ { from: 's', value: '8rem', }, { until: 's', value: '6rem', }, ],
          }}
        >
          <MainSlot globalLazyload={globalLazyload} />
        </LayoutRow>
        {postMain ? (
          <LayoutRow miscStyles={{ display: [ { until: 's', value: 'none', }, ], }}>
            <React.Fragment>{extractElements(postMain)}</React.Fragment>
          </LayoutRow>
        ) : null}
        {footer ? <LayoutRow>{extractElements(footer)}</LayoutRow> : null}
        <LayoutRow idName="modalsRoot" />
        <RainbowSlot id="bottom-strip" />
      </RainbowDataProvider>
    </React.Fragment>
  );
}

export default HomePageSlotsLayout;
