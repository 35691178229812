// @flow
import * as React from 'react';
import { WONG_LIST_QUERY as HERMES2_LIST_QUERY, } from '@haaretz/graphql';

import Hermes2View from './Hermes2View.js';
import ListWrapper from '../../ListWrapper';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Hermes2Props = {
  updateListDuplication: Function,
  listData: ListDataType,
}
export default function Hermes2(props: Hermes2Props): React.Node {
  return (
    <ListWrapper query={HERMES2_LIST_QUERY} view="Hermes2" {...props}>
      {dataProps => <Hermes2View {...dataProps} />}
    </ListWrapper>
  );
}
