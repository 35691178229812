// @flow
import * as React from 'react';
import { PANUCCI_LIST_QUERY, } from '@haaretz/graphql';
import PanucciView from './PanucciView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';
// eslint-disable-next-line import/no-extraneous-dependencies

type PanucciWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function PanucciWrapper(props: PanucciWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={PANUCCI_LIST_QUERY} view="Panucci">
      {dataProps => <PanucciView {...dataProps} />}
    </ListWrapper>
  );
}
