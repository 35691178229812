// @flow
import * as React from 'react';
import { ZOMBIE_LIST_QUERY, } from '@haaretz/graphql';

import ZombieView from './ZombieView.js';
import ListWrapper from '../../ListWrapper';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type ZombieWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
}

export default function ZombieWrapper(props: ZombieWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={ZOMBIE_LIST_QUERY} view="Zombie">
      {dataProps => <ZombieView {...dataProps} />}
    </ListWrapper>
  );
}
