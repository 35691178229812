// @flow
import React, { Fragment, useState, } from 'react';
import type { ChildrenArray, Node, } from 'react';
import { FelaComponent, useFela, } from 'react-fela';
import { borderTop, parseStyleProps, } from '@haaretz/htz-css-tools';

import type { Filter, } from '@haaretz/htz-components';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import type { Period, } from '../AssetStats/AssetStats';

import Tabs from '../Tabs/Tabs';
import TabList from '../TabList/TabList';
import Tab from '../Tab/Tab';
import TabPanel from '../TabPanel/TabPanel';
import TableGraphConnector from '../TableGraphConnector/TableGraphConnector';

export type TabType = {
  display: string | Node,
  control: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
  assetsId?: string[],
  headers?: ?({
    display: string,
    percentage?: boolean,
    value: string,
    style: ?Object,
  }[]),
  link?: Node,
  tabFilters?: Filter[],
  periods?: ?Array<{ display: string, value: Period, }>,
};

type Props = {
  filters: Filter[],
  tabs: Array<TabType>,
  disableScatter?: boolean,
  disableTimeTabs?: boolean,
  // a function that gets the isActive boolean and returns a style prop
  tabButtonMiscStyles?: boolean => StyleProps,
  tabListMiscStyles?: ?StyleProps,
  loadingHeight?: ?number,
  isTmHomePage?: ?boolean,
};

export type State = {
  tab: TabType,
  index: number,
};

type TabButtonProps = {
  children: ChildrenArray<Node> | Node,
  isActive: boolean,
  miscStyles?: boolean => StyleProps,
};

const incByKey = { ArrowLeft: 1, ArrowRight: -1, };

export const tabRule: Object => Object = ({ theme, }) => ({
  flexGrow: '1',
  flexBasis: '0',
  position: 'relative',
  textAlign: 'center',
  ':not(:last-of-type)': {
    ':after': {
      content: '""',
      position: 'absolute',
      end: '0',
      bottom: 'calc(50% - 3px)',
      transform: 'translateY(50%)',
      width: '1px',
      height: '3rem',
      backgroundColor: theme.color('neutral', '-3'),
    },
  },
});

// $FlowFixMe
export const TabButton = React.forwardRef(
  ({ isActive, children, miscStyles, ...props }: TabButtonProps, ref): Node => (
    <FelaComponent
      style={({ theme, }) => ({
        ...(isActive
          ? {
            backgroundColor: theme.color('neutral', '-10'),
            color: theme.color('primary'),
            fontWeight: '700',
            ':before': {
              content: '""',
              position: 'absolute',
              top: '0',
              start: '-1px',
              width: '1px',
              height: '100%',
              backgroundColor: theme.color('neutral', '-6'),
            },
          }
          : {}),
        position: 'relative',
        zIndex: 1,
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        width: '100%',
        ...borderTop(3, 2, 'solid', isActive ? theme.color('primary') : 'transparent'),
        ':focus': {
          outline: 'none',
          backgroundColor: theme.color('neutral', '-10'),
          textDecoration: 'underline',
        },
        extend: [
          ...(miscStyles ? parseStyleProps(miscStyles(isActive), theme.mq, theme.type) : []),
        ],
      })}
    >
      {({ className, }) => (
        <button type="button" className={className} ref={ref} {...props}>
          {children}
        </button>
      )}
    </FelaComponent>
  )
);

TabButton.defaultProps = {
  miscStyles: null,
};

TabbedGraph.defaultProps = {
  filters: null,
  disableScatter: false,
  disableTimeTabs: false,
  tabButtonMiscStyles: null,
  tabListMiscStyles: null,
  loadingHeight: null,
  isTmHomePage: false,
};

function TabbedGraph({
  filters,
  tabs,
  disableScatter,
  disableTimeTabs,
  tabButtonMiscStyles,
  tabListMiscStyles,
  loadingHeight,
  isTmHomePage,
}: Props): Node {
  const [ selectedIndex, setIndex, ] = useState(0);
  const [ selectedTab, setTab, ] = useState(tabs[0]);
  const { theme, } = useFela();
  const changeSelectedTab: State => void = ({ index, tab, }) => {
    setIndex(index);
    setTab(tab);
  };

  const tabRefs = tabs.map(React.createRef);
  const { sortBy, sortOrder, control, headers, assetsId, link, tabFilters, } = selectedTab;

  return (
    <Tabs activeTab={selectedIndex}>
      {({ setActiveTab, activeTab, }) => (
        <Fragment>
          <TabList
            miscStyles={{
              color: theme.color('neutral', '-3'),
              display: 'flex',
              marginTop: '2rem',
              ...theme.type(-1),
              ...tabListMiscStyles,
            }}
            onKeyDown={({ key, }) => {
              const inc = incByKey[key];
              if (!inc) return;
              const nextActiveTab = (tabs.length + activeTab + inc) % tabs.length;
              tabRefs[nextActiveTab].current
                && tabRefs[nextActiveTab].current.focus
                && tabRefs[nextActiveTab].current.focus();
              setActiveTab(nextActiveTab);
              changeSelectedTab({
                index: nextActiveTab,
                tab: tabs[nextActiveTab],
              });
            }}
          >
            {tabs.map((tab: TabType, i: number) => (
              <Tab
                key={tab.control}
                tabRef={tabRefs[i]}
                index={i}
                isActive={activeTab === i}
                controls={tab.control}
                presentation
                rule={tabRule}
                onClick={() => changeSelectedTab({
                  index: i,
                  tab,
                })
                }
                setActiveTab={index => setActiveTab(index)}
                render={props => <TabButton {...props} miscStyles={tabButtonMiscStyles} />}
              >
                {typeof tab.display === 'string' ? <span>{tab.display}</span> : tab.display}
              </Tab>
            ))}
          </TabList>
          <TabPanel id={control}>
            <Fragment>
              <TableGraphConnector
                assetsId={assetsId}
                filters={tabFilters || filters}
                sortBy={sortBy}
                sortOrder={sortOrder}
                count={9}
                headers={headers}
                disableScatter={disableScatter}
                disableTimeTabs={disableTimeTabs}
                loadingHeight={loadingHeight}
                isTmHomePage={isTmHomePage}
                periods={selectedTab.periods}
              />
              {link || null}
            </Fragment>
          </TabPanel>
        </Fragment>
      )}
    </Tabs>
  );
}

export default TabbedGraph;
