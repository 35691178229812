// @flow
import * as React from 'react';

import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import Image from '../../../Image/Image';
import Picture from '../../../Image/Picture';

import getImageAssets from '../../../../utils/getImageAssets';
import getPictureAssets from '../../../../utils/getPictureAssets';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type Props = {
  itemData: TeaserDataType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  gridArea: string,
  teaserIndex: number,
  theme: Object,
};

type TeaserImageProps = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  theme: Object,
}

KrokerTeaser.defaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

const headerType = [
  { until: 's', value: 0, },
  { from: 's', until: 'xl', value: 0, },
  { from: 'xl', value: -1, },
];
const headerTypeSecondary = [
  { until: 's', value: 0, },
  { from: 's', until: 'l', value: 0, },
  { from: 'l', value: -1, },
];

const areasMobile = `
"media  media   media"
".      content     ."
".      footer      ."
".        .         ."
`;
const areasDefault = `
"media"
"content"
"footer"
`;
const teaserAreas = [ { until: 's', value: areasMobile, }, { from: 's', value: areasDefault, }, ];
const verticalTeaserAreas = [
  {
    until: 's',
    value: `
    "media   .     ."
    "media content ."
    "media footer  ."
    "media   .     ."
    `,
  },
  { from: 's', value: areasDefault, },
];
const teaserCols = [ { until: 's', value: '0 1fr 0', }, ];
const verticalTeaserCols = [ { until: 's', value: '18rem 1fr', }, ];
const verticalTeaserRows = [
  { until: 's', value: '0 1fr auto 0', },
  { from: 's', value: 'auto 1fr auto', },
];

const teaserImages = [ MainTeaserImage, VerticalTeaserImage, ];

export default function KrokerTeaser({
  itemData,
  gridArea,
  isLazyloadImages,
  biAction,
  theme,
  teaserIndex,
}: Props): React.Node {
  const isVertical = teaserIndex === 1;
  return (
    <Teaser
      data={itemData}
      gridArea={gridArea}
      areasTemplate={isVertical ? verticalTeaserAreas : teaserAreas}
      rowTemplate={isVertical ? verticalTeaserRows : 'auto 1fr auto'}
      colTemplate={isVertical ? verticalTeaserCols : teaserCols}
      rowGap="1rem"
      colGap="1rem"
      gridGap={null}
      miscStyles={{
        borderBottom: [
          {
            from: 's',
            value: [ '1px', 1, 'solid', theme.color('neutral', '-6'), ],
          },
        ],
      }}
    >
      {teaserImages[teaserIndex] && (
        <TeaserMedia data={itemData} isStacked onClick={biAction}>
          {teaserImages[teaserIndex]({ data: itemData, isLazyloadImages, theme, })}
        </TeaserMedia>
      )}
      <TeaserHeader
        isGridItem
        {...itemData}
        typeScale={teaserIndex < 2 ? headerType : headerTypeSecondary}
        kickerTypeScale={teaserIndex < 2 ? headerType : headerTypeSecondary}
        onClick={biAction}
      />
      <TeaserFooter
        data={itemData}
        showLive={!!itemData.liveUpdates}
        showAuthor={teaserIndex === 0}
        typeScale={[ { until: 's', value: -2, }, { from: 's', value: -3, }, ]}
      />
    </Teaser>
  );
}

function MainTeaserImage({ data, isLazyloadImages, theme, }: TeaserImageProps): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Image
      image={image}
      lazyLoad={isLazyloadImages}
      imgOptions={getImageAssets({
        bps: theme.bps,
        aspect: 'headline',
        sizes: [
          { from: 'xl', size: '281px', },
          { from: 'l', size: '226px', },
          { from: 'm', size: '423px', },
          { from: 's', size: '322px', },
          { size: 'calc(100vw - 24px)', },
        ],
        widths: [ 226, 281, 322, 390, 423, 600, ],
      })}
    />
  );
}

function VerticalTeaserImage({ data, isLazyloadImages, theme, }: TeaserImageProps): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Picture
      lazyLoad={isLazyloadImages}
      {...getPictureAssets({
        bps: theme.bps,
        imgData: image,
        defaultImgOptions: {
          sizes: '108px',
          aspect: 'square',
          widths: [ 108, 216, ],
        },
        sources: [
          {
            from: 's',
            aspect: 'headline',
            sizes: [
              { from: 'xl', size: '178px', },
              { from: 'l', size: '143px', },
              { from: 'm', size: '274px', },
              { from: 's', size: '207px', },
            ],
            widths: [ 143, 178, 207, 274, 414, 548, ],
          },
        ],
      })}
    />
  );
}
