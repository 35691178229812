// @flow
import * as React from 'react';
import { BOXY_LIST_QUERY, } from '@haaretz/graphql';
import BoxyView from './BoxyView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type BoxyWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function BoxyWrapper(props: BoxyWrapperProps) {
  return (
    <ListWrapper {...props} query={BOXY_LIST_QUERY} view="Boxy">
      {dataProps => <BoxyView {...dataProps} />}
    </ListWrapper>
  );
}
