// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import AdSlot from '../../../AdManager/AdSlot';

import Image from '../../../Image/Image';
import Picture from '../../../Image/Picture';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import setBiAction from '../../../../utils/setBiAction';
import getImageAssets from '../../../../utils/getImageAssets';
import getPictureAssets from '../../../../utils/getPictureAssets';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';

Panucci.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const teasers = [ MainTeaser, VerticalTeaser, VerticalTeaser, HorizontalTeaser, HorizontalTeaser, ];
const areasTemplate = [
  { until: 's', value: '"he" "t1" "t2" "t3" "t4" "t5"', },
  {
    from: 's',
    until: 'l',
    value: `
    "he he he "
    "t1 t1 t2"
    "t3 t4 t4"
    "t3 t5 t5"
    "ad ad ad"
    `,
  },
  {
    from: 'l',
    until: 'xl',
    value: `
    "he he t1 t1 t1 t1 t1 t2 t2 t2 ad ad"
    "he he t3 t3 t3 t4 t4 t4 t4 t4 ad ad"
    "he he t3 t3 t3 t5 t5 t5 t5 t5 ad ad"
    `,
  },
  {
    from: 'xl',
    value: `
    "he t1 t1 t2 ad ad"
    "he t3 t4 t4 ad ad"
    "he t3 t5 t5 ad ad"
    `,
  },
];

export default function Panucci({
  biAction,
  gaAction,
  isLazyloadImages,
  list,
}: ListViewProps): React.Node {
  const { css, theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);

  const { items, banners, extraLinks, rainbowTargetSlot, ...restOfList } = list || {};
  const adSlotData = banners?.adSlots && banners.adSlots[0];
  if (!items || !items.length) return null;

  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        biAction: setBiAction(i, data, biAction),
        index: i + 1,
        isLazyloadImages,
        theme,
        attrs: isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {},
      });
    }
    return null;
  }
  return (
    <ListView
      miscStyles={{ gridAutoColumns: '1fr', }}
      areasTemplate={areasTemplate}
      attrs={{
        'data-test': 'panucci',
      }}
    >
      { rainbowTargetSlot
        ? (
          <RainbowListPaywallSlot
            id={rainbowTargetSlot}
            gridArea="t1 / t1 / t5 / t5"
            onToolRendered={onRainbowToolRendered}
          />
        )
        : null}
      {/* header */}
      {restOfList.title || restOfList.commercialLinks?.length || extraLinks?.length ? (
        <ListViewHeader
          {...restOfList}
          extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
          biAction={biAction}
          isSticky
        />
      ) : null}

      {/* List items */}
      <Section isFragment>
        {/* Items */}
        {items.map(itemRenderer)}
        {/* Banner */}
        {adSlotData ? (
          <AdSlot
            {...adSlotData}
            wrapperMiscStyles={{
              backgroundColor: '#f6f6f6',
              display: [ { until: 's', value: 'none', }, { from: 's', value: 'flex', }, ],
              gridArea: 'ad',
              justifyContent: 'center',
              justifyItems: 'center',
              margin: 'auto',
              minHeight: [ { from: 's', until: 'l', value: '250px', }, { from: 'l', value: '600px', }, ],
              minWidth: [ { from: 's', until: 'l', value: '300px', }, ],
              overflow: 'hidden',
              width: [ { from: 'l', until: 'xl', value: '120px', }, { from: 'xl', value: '300px', }, ],
            }}
            // Loading={() => (
            //   <div
            //     className={css({
            //       backgroundColor: '#f6f6f6',
            //       gridArea: 'ad',
            //       justifyContent: 'center',
            //       justifyItems: 'center',
            //       display: 'flex',
            //       ...theme.mq({ until: 's', }, { display: 'none', }),
            //       ...theme.mq(
            //         { from: 's', until: 'l', },
            //         {
            //           minWidth: '300px',
            //           minHeight: '250px',
            //         }
            //       ),
            //       ...theme.mq(
            //         { from: 'l', until: 'xl', },
            //         {
            //           width: '120px',
            //           minHeight: '600px',
            //         }
            //       ),
            //       ...theme.mq(
            //         { from: 'xl', },
            //         {
            //           width: '300px',
            //           minHeight: '600px',
            //         }
            //       ),
            //     })}
            //   />
            // )}
          />
        ) : null}
      </Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                         Teaser Components                          //
// /////////////////////////////////////////////////////////////////////

const teaserDefaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

const areasMediaObjTeaser = `
"media   .     ."
"media content ."
"media footer  ."
"media   .     ."
`;
const areasStackedTeaser = `
"media media media"
". content ."
". footer  ."
".    .    ."
`;
const stackedTeaserRow = 'auto 1fr auto 0';
const mediaObjTeaserRow = '0 1fr auto 0';

type TeaserPropsType = {
  biAction: ?() => void,
  data: TeaserDataType,
  index: number,
  isLazyloadImages: boolean,
  theme: Object,
};

MainTeaser.defaultProps = teaserDefaultProps;
function MainTeaser({
  biAction,
  data,
  index,
  isLazyloadImages,
  theme,
}: TeaserPropsType): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      areasTemplate={areasStackedTeaser}
      gridArea={`t${index}`}
      colGap={theme.panucciStyle.colGap}
      rowGap="1rem"
      gridGap={null}
      rowTemplate={stackedTeaserRow}
      colTemplate={[ { until: 's', value: 'auto 1fr 0', }, { from: 's', value: '0 1fr 0', }, ]}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Image
          image={image}
          lazyLoad={isLazyloadImages}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'headline',
            sizes: [
              { from: 'xl', size: '384px', },
              { from: 'l', size: '393px', },
              { from: 'm', size: '472px', },
              { from: 's', size: '360px', },
              { size: 'calc(100vw - 24px)', },
            ],
            widths: [ 360, 384, 393, 472, 575, 720, ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        {...data}
        isGridItem
        typeScale={[ { until: 'xl', value: 1, }, { from: 'xl', value: 0, }, ]}
        onClick={biAction}
      />
      <TeaserFooter
        data={data}
        showAuthor
        showCommentsCount={theme.panucciStyle.footer.showCommentsCount}
        showTime={theme.panucciStyle.footer.showTime}
      />
    </Teaser>
  );
}

function VerticalTeaser({
  biAction,
  data,
  index,
  isLazyloadImages,
  theme,
}: TeaserPropsType): React.Node {
  const hasImage = index === 2;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      areasTemplate={
        hasImage
          ? [ { until: 's', value: areasMediaObjTeaser, }, { from: 's', value: areasStackedTeaser, }, ]
          : areasStackedTeaser
      }
      key={data.contentId}
      gridArea={`t${index}`}
      colGap={theme.panucciStyle.colGap}
      rowGap="1rem"
      gridGap={null}
      rowTemplate={
        hasImage
          ? [ { until: 's', value: mediaObjTeaserRow, }, { from: 's', value: stackedTeaserRow, }, ]
          : stackedTeaserRow
      }
      colTemplate={
        hasImage
          ? [ { until: 's', value: 'auto 1fr 0', }, { from: 's', value: '0 1fr 0', }, ]
          : '0 1fr 0'
      }
    >
      {hasImage ? (
        <TeaserMedia data={data} onClick={biAction}>
          <Picture
            lazyLoad={isLazyloadImages}
            {...getPictureAssets({
              bps: theme.bps,
              imgData: image,
              defaultImgOptions: {
                sizes: '108px',
                aspect: 'square',
                widths: [ 108, 216, ],
              },
              sources: [
                {
                  from: 's',
                  aspect: 'regular',
                  sizes: [
                    { from: 'xl', size: '178px', },
                    { from: 'l', size: '226px', },
                    { from: 'm', size: '224px', },
                    { from: 's', size: '168px', },
                  ],
                  widths: [ 168, 180, 226, 336, 448, ],
                },
              ],
            })}
          />
        </TeaserMedia>
      ) : null}
      <TeaserHeader
        {...data}
        isGridItem
        typeScale={theme.panucciStyle.headerTypeScale(hasImage)}
        onClick={biAction}
      />
      <TeaserFooter
        data={data}
        showAuthor
        showCommentsCount={theme.panucciStyle.footer.showCommentsCount}
        showTime={theme.panucciStyle.footer.showTime}
      />
    </Teaser>
  );
}

function HorizontalTeaser({
  biAction,
  data,
  index,
  isLazyloadImages,
  theme,
}: TeaserPropsType): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      areasTemplate={areasMediaObjTeaser}
      key={data.contentId}
      gridArea={`t${index}`}
      colGap={theme.panucciStyle.colGap}
      rowGap="1rem"
      gridGap={null}
      rowTemplate={mediaObjTeaserRow}
      colTemplate={[
        { until: 's', value: 'calc(108px) 1fr', },
        { from: 's', value: 'calc(50% - 2rem) 1fr', },
      ]}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: '108px',
              aspect: 'square',
              widths: [ 108, 216, ],
            },
            sources: [
              {
                from: 's',
                aspect: 'regular',
                sizes: [
                  { from: 'xl', size: '178px', },
                  { from: 'l', size: '185px', },
                  { from: 'm', size: '224px', },
                  { from: 's', size: '168px', },
                ],
                widths: [ 350, 224, 185, 168, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        {...data}
        isGridItem
        typeScale={theme.panucciStyle.horizontalTeaser.headerTypeScale}
        onClick={biAction}
      />
      <TeaserFooter
        data={data}
        showAuthor
        showCommentsCount={theme.panucciStyle.footer.showCommentsCount}
        showTime={theme.panucciStyle.footer.showTime}
      />
    </Teaser>
  );
}
