// @flow
import * as React from 'react';
import { MICHELANGELO_LIST_QUERY, } from '@haaretz/graphql';
import MichelangeloView from './MichelangeloView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';
import ItemPlaceholder from '../../../ItemPlaceholder/ItemPlaceholder';

type MichelangeloWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function MichelangeloWrapper(props: MichelangeloWrapperProps) {
  return (
    <ListWrapper {...props} query={MICHELANGELO_LIST_QUERY} Placeholder={ItemPlaceholder} view="Michelangelo">
      {dataProps => <MichelangeloView {...dataProps} />}
    </ListWrapper>
  );
}
