/* globals window,document */
import React from 'react';
import PropTypes from 'prop-types';
import { GetComponentProvider, WidePageLayout, } from '@haaretz/htz-components';

import SlotsLayout from '../components/HomePage/SlotsLayout';
import getElements from '../utils/getHomePageElementsBS';

const propTypes = {
  /**
   * An object containing route information from Next, such as the `pathname`
   * and `query` object.
   */
  url: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.shape({
      path: PropTypes.string.isRequired,
      isBot: PropTypes.bool,
      isSmadar: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

function HomePage({ url, }) {
  const path = (url && url.query.path) || '/';
  const { isBot, } = url.query;
  return (
    <GetComponentProvider value={getElements}>
      <WidePageLayout
        isBot={isBot}
        path={path}
        render={({ slots, globalLazyload, }) => (
          <SlotsLayout slots={slots} globalLazyload={globalLazyload} />
        )}
        organizationSchema={'{"@context": "https://schema.org",'
          .concat('"@type": "Organization","name":"Haaretz","url":"https://www.haaretz.com",')
          .concat('"sameAs":["https://en.wikipedia.org/wiki/Haaretz","https://www.facebook.com/haaretzcom","https://twitter.com/haaretzcom",')
          .concat('"https://www.youtube.com/user/haaretzcom"],')
          .concat('"logo":{"@type":"ImageObject","url":"https://gif.haarets.co.il/bs/00000180-5ba0-db1e-a1d4-dfe147760001/26/33/264b70274ef7999398477796d771/hdclogo.gif"}}')}
      />
    </GetComponentProvider>
  );
}

HomePage.propTypes = propTypes;

export default HomePage;
