// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import dynamic from 'next/dynamic';
import AdSlot from '../../../AdManager/AdSlot';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import KrokerTeaser from './KrokerTeaser';
import Section from '../../../AutoLevels/Section';

import setBiAction from '../../../../utils/setBiAction';

import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import { writeToLocalStorage, } from '../../../../utils/writeToStorage';

import useOneTime from '../../../../hooks/useOneTime';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';

const BreakingNewsBox = dynamic(() => import('../../../BreakingNewsBox/BreakingNewsBox'));

type Props = {
  list: ListDataType,
  listId: string,
  isLazyloadImages: boolean,
  gaAction: ?() => void,
  biAction: ?ListBiActionType,
  renderBreakingNewsBox?: boolean,
};

const areasTemplate = ({ renderBreakingNewsBox, renderBottomAd, }) => [
  {
    until: 's',
    value: `
    "he"
    "t1"
    "t2"
    "t3"
    "t4"
    "t5"
    "ad"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "he     he     he     he"
    ${renderBreakingNewsBox ? '"ticker ticker ticker ticker"' : ''}
    "t1     t1     t2     t2"
    "t3     t4     t4     t5"
    "ad     ad     ad     ad"
    `,
  },
  {
    from: 'l',
    value: `
    ${renderBreakingNewsBox ? '"he ticker ticker ticker ticker"' : ''}
    "he t1     t2     t3     ad"
    "he t1     t2     t4     ad"
    "he t1     t2     t5     ad"
    ${renderBottomAd ? '"he ad2 ad2 ad2 ad2"' : ''}
    `,
  },
];
const colTemplate = [
  { until: 's', value: '1fr', },
  { from: 's', until: 'l', value: '7fr 5fr 1fr 7fr', },
  { from: 'l', value: '2fr 3fr 2fr 3fr 2fr', },
];

KrokerView.defaultProps = {
  renderBreakingNewsBox: true,
};

export default function KrokerView({
  list,
  listId,
  isLazyloadImages,
  gaAction,
  biAction,
  renderBreakingNewsBox,
}: Props): React.Node {
  const { theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);
  const { items, banners, extraLinks, rainbowTargetSlot, ...restOfList } = list;

  const adSlot = banners?.adSlots || [];

  const dfpData = adSlot && adSlot[0];
  const bottomAd = adSlot && adSlot[1];


  useOneTime(!!items, () => {
    const contentIds = items.map(item => item.contentId).join(', ');
    writeToLocalStorage({
      key: 'KrokerExclude',
      // eslint-disable-next-line
      value: contentIds,
      errorMessage: 'error writing from last article id to local storage',
    });
  }
  );

  if (!items || !items.length) return null;
  return (
    <ListView
      areasTemplate={areasTemplate({ renderBreakingNewsBox, renderBottomAd: !!bottomAd, })}
      colTemplate={colTemplate}
      padding={[
        { until: 's', value: '0 2rem', },
        { from: 's', value: !renderBreakingNewsBox ? '4rem' : '0 4rem 4rem', },
      ]}
      marginTop={[ { until: 's', value: 6, }, ]}
      rowGap={[ { until: 's', value: '2rem', }, { from: 's', value: '1rem', }, ]}
      colGap="4rem"
      gridGap={null}
      innerBackgroundColor={[ { until: 's', value: 'transparent', }, { from: 's', value: 'white', }, ]}
      attrs={{
        'data-test': 'kroker',
      }}
    >
      {/* list header */}
      <ListViewHeader
        isSticky
        {...restOfList}
        extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
        backgroundColor="white"
      />
      { rainbowTargetSlot
        ? (
          <RainbowListPaywallSlot
            id={rainbowTargetSlot}
            gridArea="t1 / t1 / t5 / t5"
            onToolRendered={onRainbowToolRendered}
          />
        )
        : null}
      {/* breaking news element */}
      {renderBreakingNewsBox ? (
        <BreakingNewsBox
          speed={4}
          loop
          itemsTransitionDuration={5}
          miscStyles={{
            gridArea: 'ticker',
            marginBottom: [
              { from: 's', until: 'l', value: '2rem', },
              { from: 'l', until: 'xl', value: '3rem', },
              { from: 'xl', value: '1rem', },
            ],
          }}
        />
      ) : null}
      {/* list items */}
      <Section isFragment>
        {items.map((item, i) => (item && i < 5 ? (
          <KrokerTeaser
            key={item.contentId}
            itemData={item}
            isLazyloadImages={isLazyloadImages}
            biAction={setBiAction(i, item, biAction)}
            gridArea={`t${i + 1}`}
            teaserIndex={i}
            theme={theme}
            attrs={isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {}}
          />
        ) : null)
        )}
      </Section>
      {/* banner */}
      {dfpData && (
        <AdSlot
          {...dfpData}
          loadPriority={list.loadPriority}
          wrapperMiscStyles={{
            gridArea: 'ad',
            marginTop: [ { from: 's', until: 'l', value: '3rem', }, ],
            // minHeight: '110px',
            width: '100%',
          }}
        />
      )}
      {bottomAd && (
        <AdSlot
          {...bottomAd}
          loadPriority={list.loadPriority}
          wrapperMiscStyles={{
            gridArea: 'ad2',
            marginTop: [ { from: 'l', value: '4rem', }, ],
            // minHeight: '110px',
            width: '100%',
            display: [ { until: 'l', value: 'none', }, ],
          }}
        />
      )}
    </ListView>
  );
}
