// @flow
import * as React from 'react';
import { DONATELLO_LIST_QUERY, } from '@haaretz/graphql';
import DonatelloView from './DonatelloView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type DonatelloWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function DonatelloWrapper(props: DonatelloWrapperProps) {
  return (
    <ListWrapper {...props} query={DONATELLO_LIST_QUERY} view="Donatello">
      {dataProps => <DonatelloView {...dataProps} />}
    </ListWrapper>
  );
}
