// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import ListView from '../../../ListView/NewListView';
import Hermes2Item from './Hermes2Item';
import setBiAction from '../../../../utils/setBiAction';

const teasers = [ Hermes2Item, Hermes2Item, Hermes2Item, Hermes2Item, ];

Hermes2View.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: false,
};
export default function Hermes2View({
  isLazyloadImages,
  list,
  gaAction,
  biAction,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const { items, } = list || {};

  if (!items || !items.length) return null;

  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        biAction: setBiAction(i, data, biAction),
        hideImageOnMobile: false,
        isLazyloadImages,
        theme,
        gridArea: `t${i + 1}`,
      });
    }
    return null;
  }
  const itemsToRender = items.slice(0, 4);
  return (
    <ListView
      innerBackgroundColor={[ { until: 's', value: 'white', }, { from: 's', value: [ 'neutral', '-7', ], }, ]}
      marginTop={[ { until: 'l', value: '3.5rem', }, ]}
      padding={[ { until: 's', value: '0 0 2rem', }, ]}
      colGap={[ { until: 's', value: '1rem', }, { from: 's', until: 'l', value: '2rem', }, ]}
      rowGap={[ { until: 'l', value: '2rem', }, { from: 'l', value: '4rem', }, ]}
      gridGap={null}
      areasTemplate={[
        {
          until: 'l',
          value: `
          "t1 . t2"
          "t3 . t4"
          `,
        },
        {
          from: 'l',
          value: `
          "t1"
          "t2"
          "t3"
          "t4"
          `,
        },
      ]}
      colTemplate={[
        { until: 'l', value: '1fr 0 1fr', },
      ]}
      rowTemplate={[ { until: 'l', value: '1fr 1fr', }, ]}
      attrs={{ 'data-test': 'hermes2', }}
    >
      {itemsToRender.map(itemRenderer)}
    </ListView>
  );
}
