// @flow
import * as React from 'react';
import { AKA_LIST_QUERY, } from '@haaretz/graphql';
import AkaView from './AkaView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type AkaWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
};

export default function AkaWrapper(props: AkaWrapperProps) {
  return (
    <ListWrapper {...props} query={AKA_LIST_QUERY} view="Aka">
      {dataProps => <AkaView {...dataProps} />}
    </ListWrapper>
  );
}
