// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { borderTop, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';

// import gql from 'graphql-tag';

import Query from '../../../../ApolloBoundary/Query';

// import StaticTable from '../../../../StaticTable/StaticTable';
// import StaticTable from '../../../../StaticTable/StaticTable';
import Tabs from '../../../../Tabs/Tabs';
import TabList from '../../../../TabList/TabList';
import Tab from '../../../../Tab/Tab';
import TabPanel from '../../../../TabPanel/TabPanel';
import { tabRule, } from '../../../../TabbedGraph/TabbedGraph';
import type { State, TabType, } from '../../../../TabbedGraph/TabbedGraph';
import StaticTable from '../../../../StaticTable/StaticTable';
import GET_STATIC_TABLE_DATA from './staticTableQuery.graphql';

const incByKey = { ArrowLeft: 1, ArrowRight: -1, };

const numToString: (number | string) => string = num => (typeof num === 'number'
  ? num.toLocaleString('he', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  : num);

// $FlowFixMe
const TabButton = React.forwardRef(
  (
    { isActive, children, ...props }: { isActive: boolean, children: React.Node, },
    ref
  ): React.Node => {
    const { css, theme, } = useFela();
    return (
      <button
        type="button"
        className={css({
          ...(isActive
            ? {
              backgroundColor: theme.color('neutral', '-10'),
              color: theme.color('neutral'),
              fontWeight: '700',
              ':before': {
                content: '""',
                position: 'absolute',
                top: '0',
                start: '-1px',
                width: '1px',
                height: '100%',
                backgroundColor: theme.color('neutral', '-6'),
              },
            }
            : {
              fontWeight: 700,
            }),
          position: 'relative',
          zIndex: 1,
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          width: '100%',
          ...borderTop(3, 2, 'solid', isActive ? theme.color('neutral') : 'transparent'),
          ':focus': {
            outline: 'none',
            backgroundColor: theme.color('neutral', '-10'),
            textDecoration: 'underline',
          },
        })}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export default function FryMobileGraph() {
  const { theme, css, } = useFela();
  const headers = [
    {
      display: 'שם אפיק',
      value: 'name',
      style: {
        paddingStart: '1rem',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        paddingEnd: '1rem',
        marginBottom: '1rem',
        backgroundColor: theme.color('neutral', '-6'),
      },
    },
    {
      display: 'שער אחרון',
      value: 'name',
      style: {
        // paddingStart: '1rem',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        paddingEnd: '1rem',
        marginBottom: '1rem',
        backgroundColor: theme.color('neutral', '-6'),
        ...theme.mq({ from: 's', }, { display: 'none', }),
      },
    },
    {
      display: '% שינוי',
      percentage: true,
      value: 'changePercentage',
      style: {
        paddingEnd: '1rem',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        marginBottom: '1rem',
        backgroundColor: theme.color('neutral', '-6'),
      },
    },
  ];
  const tabs = [
    {
      display: 'תל אביב',
      control: 'graph-up',
      assetsId: [ '142', '137', '145', '164', '167', ],
      tabFilters: [ { section: 'index', subSection: '2', }, ],
      headers,
    },
    {
      display: 'וול סטריט',
      control: 'graph-up',
      assetsId: [ '30.10.!DJI', '33.10.!SPX', '29.10.@CCO', '29.10.NDX', ],
      tabFilters: [ { section: 'index', subSection: '2', }, ],
      headers,
    },
    {
      display: 'מט"ח',
      control: 'graph-active',
      assetsId: [ '90012', '9001', '90041', '9004', '90011', ],
      headers,
    },
    {
      display: 'קריפטו',
      control: 'graph-upYearly',
      assetsId: [ 'BTC', 'LTC', 'ETH', 'ZEC', 'XRP', ],
      headers,
    },
  ];
  const [ selectedIndex, setIndex, ] = React.useState(0);
  const [ selectedTab, setTab, ] = React.useState(tabs[0]);
  const changeSelectedTab: State => void = ({ index, tab, }) => {
    setIndex(index);
    setTab(tab);
  };

  const tabRefs = tabs.map(React.createRef);
  const {
    control,
    // headers,
    assetsId,
  } = selectedTab;

  console.log('selectedtab: ', selectedTab);
  return (
    <Tabs activeTab={selectedIndex}>
      {({ setActiveTab, activeTab, }) => (
        <React.Fragment>
          <TabList
            miscStyles={{
              color: theme.color('neutral', '-3'),
              display: 'flex',
              marginTop: '2rem',
              ...theme.type(-1),
            }}
            onKeyDown={({ key, }) => {
              const inc = incByKey[key];
              if (!inc) return;
              const nextActiveTab = (tabs.length + activeTab + inc) % tabs.length;
              tabRefs[nextActiveTab].current
                && tabRefs[nextActiveTab].current.focus
                && tabRefs[nextActiveTab].current.focus();
              setActiveTab(nextActiveTab);
              changeSelectedTab({
                index: nextActiveTab,
                tab: tabs[nextActiveTab],
              });
            }}
          >
            {tabs.map((tab: TabType, i: number) => (
              <Tab
                key={tab.control}
                tabRef={tabRefs[i]}
                index={i}
                isActive={activeTab === i}
                controls={tab.control}
                presentation
                rule={tabRule}
                onClick={() => changeSelectedTab({
                  index: i,
                  tab,
                })
                }
                setActiveTab={index => setActiveTab(index)}
                render={TabButton}
              >
                {typeof tab.display === 'string' ? <span>{tab.display}</span> : tab.display}
              </Tab>
            ))}
          </TabList>
          <TabPanel id={control}>
            <Query
              query={GET_STATIC_TABLE_DATA}
              variables={{
                ids: assetsId,
              }}
            >
              {({ data, loading, error, }) => {
                if (loading) return null;
                if (error) return null;
                return (
                  <div
                    className={css({
                      backgroundColor: 'white',
                      paddingTop: '1rem',
                      paddingLeft: '1rem',
                      paddingRight: '1rem',
                    })}
                  >
                    <StaticTable
                      baseLinkPath="https://finance.themarker.com/"
                      data={data.assets}
                      linkContent
                      columns={[
                        {
                          title: 'שם נייר',
                          name: 'name',
                          styles: {
                            fontWeight: '700',
                            maxWidth: '17rem',
                            paddingStart: '2rem',
                          },
                          headerStyle: {
                            fontWeight: 300,
                            color: theme.color('neutral', '-2'),
                            backgroundColor: theme.color('layout', 'rowBg'),
                          },
                          render: value => value,
                        },
                        {
                          title: 'שער אחרון',
                          name: 'value',
                          styles: {
                            fontWeight: '700',
                            maxWidth: '17rem',
                            paddingStart: '2rem',
                          },
                          headerStyle: {
                            fontWeight: 300,
                            color: theme.color('neutral', '-2'),
                            backgroundColor: theme.color('layout', 'rowBg'),
                          },
                          render: value => value,
                        },
                        {
                          title: '% שינוי',
                          percentage: true,
                          name: 'changePercentage',
                          styles: function styles(value: number): StyleProps {
                            return {
                              ...(value
                                ? {
                                  color:
                                      value < 0 ? theme.color('negative') : theme.color('positive'),
                                }
                                : {}),
                              direction: 'ltr',
                              textAlign: 'start',
                              fontWeight: '700',
                              paddingStart: '2rem',
                              position: 'relative',
                            };
                          },
                          headerStyle: {
                            fontWeight: 300,
                            color: theme.color('neutral', '-2'),
                            backgroundColor: theme.color('layout', 'rowBg'),
                          },
                          render: value => `
                                    ${value > 0 ? '+' : value < 0 ? '-' : ''}
                                    ${value ? numToString(Math.abs(value)) : value}%
                                  `,
                        },
                      ]}
                    />
                  </div>
                );
              }}
            </Query>
          </TabPanel>
        </React.Fragment>
      )}
    </Tabs>
  );
}
