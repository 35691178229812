// @flow
import React, { Fragment, } from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, borderBottom, } from '@haaretz/htz-css-tools';

import type { StyleProps, } from '@haaretz/htz-css-tools';
import type { Node, } from 'react';
import H from '../AutoLevels/H';
import type { LinkType, } from '../SortableTable/SortableTable';

import { TdComponent, } from '../AssetsTable/AssetsTable';
import { TableLink, } from '../SortableTable/SortableTable';
import SectionLink from '../SectionLink/SectionLink';

type Column = {
  name: string, // eslint-disable-line react/no-unused-prop-types
  title: string, // eslint-disable-line react/no-unused-prop-types
  styles: ?((any) => StyleProps) | StyleProps, // eslint-disable-line react/no-unused-prop-types
  headerStyle?: ?StyleProps, // eslint-disable-line react/no-unused-prop-types
  render: any => string, // eslint-disable-line react/no-unused-prop-types
};

type Props = {
  baseLinkPath?: ?string,
  data: Array<any>,
  columns: Array<Column>,
  miscStyles: ?Object,
  link?: ?LinkType,
  linkContent?: boolean,
  title?: ?string | Node,
  titleMiscStyles?: ?StyleProps,
  trMiscStyles?: ?StyleProps,
};

StaticTable.defaultProps = {
  // base link path for TableLink, defaults to /
  baseLinkPath: null,
  link: null,
  linkContent: false,
  miscStyles: null,
  title: null,
  titleMiscStyles: null,
  trMiscStyles: null,
};

function StaticTable({
  baseLinkPath,
  data,
  columns,
  miscStyles,
  linkContent,
  link,
  title,
  titleMiscStyles,
  trMiscStyles,
}: Props): Node {
  const { css, theme, } = useFela();
  return (
    <Fragment>
      {title ? (
        <H
          className={css({
            fontWeight: '700',
            backgroundColor: theme.color('neutral', '-5'),
            paddingStart: '2rem',
            paddingEnd: '2rem',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            textAlign: 'start',
            extend: [
              theme.type(-1),
              ...(titleMiscStyles ? parseStyleProps(titleMiscStyles, theme.mq, theme.type) : []),
            ],
          })}
        >
          {title}
        </H>
      ) : null}
      <table
        className={css({
          tableLayout: 'fixed',
          whiteSpace: 'nowrap',
          width: '100%',
          extend: [
            theme.type(-1, { untilBp: 'xl', }),
            theme.type(-2, { fromBp: 'xl', }),
            ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
          ],
        })}
      >
        <thead>
          <tr>
            {columns.map(({ title, name, headerStyle, }) => (
              <TdComponent
                isHeader
                key={name}
                miscStyles={{
                  textAlign: 'start',
                  paddingTop: [
                    { until: 'xl', value: 'calc(5 / 6 * 1rem)', },
                    { from: 'xl', value: '0.5rem', },
                  ],
                  paddingBottom: [
                    { until: 'xl', value: 'calc(5 / 6 * 1rem)', },
                    { from: 'xl', value: '0.5rem', },
                  ],
                  paddingStart: '2rem',
                  backgroundColor: theme.color('neutral', '-7'),
                  color: theme.color('neutral', '-2'),
                  ...(headerStyle || {}),
                }}
              >
                {title}
              </TdComponent>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((asset, idx) => (
            <tr
              key={asset[Object.keys(asset)[0]]}
              className={css({
                backgroundColor: theme.color('neutral', '-10'),
                extend: [
                  borderBottom(
                    '2px',
                    1,
                    'solid',
                    idx === data.length - 1 ? 'transparent' : theme.color('neutral', '-6')
                  ),
                  ...(trMiscStyles ? parseStyleProps(trMiscStyles, theme.mq, theme.type) : []),
                ],
              })}
            >
              {columns.map(({ name, styles, render, }: Column, index: number) => (
                <TdComponent
                  key={`${name}-${asset[Object.keys(asset)[0]]}`}
                  miscStyles={{
                    paddingTop: [ { until: 'xl', value: '10px', }, { from: 'xl', value: '1.35rem', }, ],
                    paddingBottom: [
                      { until: 'xl', value: '10px', },
                      { from: 'xl', value: '1.35rem', },
                    ],
                    ...(typeof styles === 'function' ? styles(asset[name]) : styles),
                  }}
                >
                  {linkContent ? (
                    <TableLink
                      allowTab={index === 0}
                      content={render(asset[name])}
                      assetId={asset.id}
                      type={asset.type}
                      {...(baseLinkPath ? { basePath: baseLinkPath, } : {})}
                    />
                  ) : (
                    render(asset[name])
                  )}
                </TdComponent>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {link ? (
        <SectionLink
          href={link.href}
          as={link.as}
          miscStyles={{
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
        >
          <span>{link.text}</span>
        </SectionLink>
      ) : null}
    </Fragment>
  );
}

StaticTable.defaultProps = { miscStyles: null, trMiscStyles: null, };
export default StaticTable;
