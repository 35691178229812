// @flow
import * as React from 'react';
import {
  useGetComponent,
} from '@haaretz/htz-components';


type Props = {
  content: Array<Object>,
};

function HeaderSlot({ content, }: Props): React.Node {
  const getComponent = useGetComponent();
  if (content) {
    return content.map(element => {
      const Element = getComponent(element.inputTemplate);
      return (
        <Element
          key={element.contentId}
          {...element}
          {...(element.inputTemplate === 'PageNavigation'
            ? {
              pageType: 'homepage',
            }
            : {})}
        />
      );
    });
  }
  return null;
}

export default HeaderSlot;
