// @flow
import { useFela, } from 'react-fela';
import * as React from 'react';
import { useInView, } from 'react-intersection-observer';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { attrFlowType, } from '../../../../flowTypes/attrTypes';

import Section from '../../../AutoLevels/Section';

import AdSlot from '../../../AdManager/AdSlot';
import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import useOneTime from '../../../../hooks/useOneTime';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';

// ////////////////////////////////////////////////////////////////// //
//                             Container                              //
// ////////////////////////////////////////////////////////////////// //

const areasTemplate = [
  {
    until: 's',
    value: `
    "he      he"
    "main    main"
    "....    ...."
    "t2      t3"
    "....    ...."
    "textual textual"
    "....    ...."
    "ad      ad"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: '"he" "main" "." "t2" "." "t3" "." "textual" "." "ad"',
  },
  {
    from: 'l',
    until: 'xl',
    value: `
    "he he"
    "t2 main"
    ".  main"
    "t3 main"
    ".  main"
    "textual main"
    ".       ."
    "ad ad"
    `,
  },
  {
    from: 'xl',
    value: `
    "he      he      he"
    "t2      main    ad"
    ".       main    ad"
    "t3      main    ad"
    ".      main    ad"
    "textual main    ad"
    `,
  },
];
const teasers = [ MainTeaser, TwoUpTeaser, TwoUpTeaser, TextualTeaser, ];
const teaserGridAreaNames = [ 'main', 't2', 't3', 'textual', ];

const dfpWrapperStyles = {
  gridArea: 'ad',
  justifyContent: 'center',
  justifyItems: 'center',
  margin: 'auto',
  display: 'flex',
};

Slugs.defaultProps = {
  gaAction: null,
  isLazyloadImages: true,
};
export default function Slugs({
  list,
  biAction,
  biImpression,
  isLazyloadImages,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);
  const { items, banners, extraLinks, rainbowTargetSlot, ...restOfList } = list || {};
  const adSlotData = banners?.adSlots && banners.adSlots[0];
  if (!(items && items.length)) return null;
  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        gridArea: teaserGridAreaNames[i],
        biAction: setBiAction(i, data, biAction),
        biImpression: setBiImpression(i, data, biImpression),
        isLazyloadImages,
        theme,
        attrs: isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {},
      });
    }
    return null;
  }

  return (
    <ListView
      areasTemplate={areasTemplate}
      innerBackgroundColor="transparent"
      rowTemplate={[
        { until: 'l', value: 'auto auto 1rem auto 1rem auto 1rem auto', },
        {
          from: 'l',
          until: 'xl',
          value: 'auto auto 3rem auto 3rem 1fr auto',
        },
        { from: 'xl', value: 'auto auto 3rem auto 3rem 1fr', },
      ]}
      gridGap={null}
      rowGap="1rem"
      colGap={[
        { until: 's', value: '2rem', },
        { from: 's', value: '4rem', },
      ]}
      colTemplate={[
        { until: 's', value: '1fr 1fr', },
        { from: 'l', until: 'xl', value: '7fr 5fr', },
        { from: 'xl', value: '5fr 5fr 2fr', },
      ]}
      attrs={{
        'data-test': 'slugs',
      }}
    >
      <ListViewHeader
        width={1}
        {...restOfList}
        extraLinks={
          extraLinks && theme.slugsStyle.showExtraLinks ? extraLinks.slice(0, 5) : null
        }
        onClick={biAction}
        isSticky
        isHorizontal
      />
      { rainbowTargetSlot
        ? (
          <RainbowListPaywallSlot
            id={rainbowTargetSlot}
            gridArea={[
              { until: 'l', value: 'main / main / textual / textual', },
              { from: 'l', value: 't2 / t2 / main / main', },
            ]}
            onToolRendered={onRainbowToolRendered}
          />
        )
        : null}
      <Section isFragment>
        {items.map(itemRenderer)}
        {adSlotData ? (
          <AdSlot
            {...adSlotData}
            wrapperMiscStyles={dfpWrapperStyles}
          />
        ) : null}
      </Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

type TeaserProps = {
  data: TeaserDataType,
  isLazyloadImages?: boolean,
  biAction: ?() => void,
  biImpression: ?() => void,
  gridArea: string,
  theme: Object,
  attrs?: ?attrFlowType,
};
const teaserDefaultProps = {
  isLazyloadImages: true,
  biAction: null,
  biImpression: null,
  attrs: null,
};

const mediaObjTeaserAreas = `
  "media    .    ."
  "media content ."
  "media footer  ."
  "media    .    ."
`;
const stackedTeaserAreas = `
  "media media media"
  ".     content   ."
  ".     footer    ."
  ".       .       ."
`;

MainTeaser.defaultProps = teaserDefaultProps;
function MainTeaser({
  data,
  gridArea,
  isLazyloadImages,
  biAction,
  biImpression,
  theme,
  attrs,
}: TeaserProps): React.Node {
  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  useOneTime(!!inView && !!biImpression, () => {
    !!biImpression && biImpression();
  });

  const { mainTeaser, } = theme.slugsStyle;
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      fwRef={ref}
      data={data}
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={stackedTeaserAreas}
      rowTemplate="auto 1fr auto 0"
      onClick={biAction}
      colGap={[
        { until: 's', value: '2rem', },
        { from: 's', until: 'l', value: '4rem', },
        { from: 'l', value: '2rem', },
      ]}
      rowGap={mainTeaser.rowGap}
      gridGap={null}
      miscStyles={{
        justifySelf: 'center',
        maxWidth: [ { from: 's', until: 'l', value: '68rem', }, ],
      }}
      attrs={attrs}
    >
      <TeaserMedia data={data} isStacked onClick={biAction}>
        <Image
          lazyLoad={isLazyloadImages}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'square',
            sizes: [
              { from: 'xl', size: '487px', },
              { from: 'l', size: '393px', },
              { from: 's', size: '408px', },
              { size: 'calc(100vw - 24px)', },
            ],
            widths: [ 680, 600, 487, 293, 408, ],
          })}
          image={image}
        />
      </TeaserMedia>
      <TeaserHeader
        {...data}
        typeScale={[
          { until: 'l', value: 1, },
          { from: 'l', value: 2, },
        ]}
        onClick={biAction}
        isCentered
        wrapperMiscStyles={mainTeaser.headerWrapperMiscStyles}
        isGridItem
      />
      <TeaserFooter
        data={data}
        showRank
        showAuthor
        showMediaFlsags
        showCommentsCount={mainTeaser.footer.showCommentsCount}
        miscStyles={mainTeaser.footer.miscStyles}
      />
    </Teaser>
  );
}

TwoUpTeaser.defaultProps = teaserDefaultProps;
function TwoUpTeaser({
  data,
  gridArea,
  isLazyloadImages,
  biAction,
  biImpression,
  theme,
  attrs,
}: TeaserProps): React.Node {
  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  useOneTime(!!inView && !!biImpression, () => {
    !!biImpression && biImpression();
  });

  const { twoUpTeaser, } = theme.slugsStyle;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      fwRef={ref}
      data={data}
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={[
        { until: 's', value: stackedTeaserAreas, },
        { from: 's', value: mediaObjTeaserAreas, },
      ]}
      colGap={twoUpTeaser.colGap}
      rowGap={twoUpTeaser.rowGap}
      gridGap={null}
      colTemplate={[
        { from: 's', until: 'l', value: '1fr 2fr 0', },
        { from: 'l', until: 'xl', value: '4fr 5fr 0', },
        { from: 'xl', value: '3fr 2fr 0', },
      ]}
      rowTemplate={[
        { until: 's', value: 'auto 1fr auto 0', },
        { from: 's', value: '0 1fr auto 0', },
      ]}
      onClick={biAction}
      attrs={attrs}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Image
          lazyLoad={isLazyloadImages}
          imgOptions={{
            ...getImageAssets({
              bps: theme.bps,
              aspect: 'regular',
              sizes: [
                { from: 'xl', size: '274px', },
                { from: 'm', size: '240px', },
                { from: 's', size: '184px', },
                { size: 'calc(50vw - 15px)', },
              ],
              widths: [ 650, 600, 500, 400, 274, 240, 184, ],
            }),
          }}
          image={image}
        />
      </TeaserMedia>
      <TeaserHeader
        {...data}
        isGridItem
        typeScale={twoUpTeaser.headerTypeScale}
        onClick={biAction}
        wrapperMiscStyles={twoUpTeaser.headerWrapperMiscStyles}
      />
      <TeaserFooter
        data={data}
        showAuthor
        showRank
        showCommentsCount={twoUpTeaser.footer.showCommentsCount}
      />
    </Teaser>
  );
}

TextualTeaser.defaultProps = teaserDefaultProps;
function TextualTeaser({
  data,
  gridArea,
  biAction,
  biImpression,
  theme,
  attrs,
}: TeaserProps): React.Node {
  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  useOneTime(!!inView && !!biImpression, () => {
    !!biImpression && biImpression();
  });

  const { textualTeaser, } = theme.slugsStyle;
  return (
    <Teaser
      fwRef={ref}
      data={data}
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={`
      ".    .    ."
      ". content ."
      ". footer  ."
      ".    .    ."
      `}
      onClick={biAction}
      rowTemplate="0 1fr auto 0"
      colTemplate="0 auto 0"
      colGap={[
        { until: 'l', value: '1rem', },
        { from: 'l', value: '2rem', },
      ]}
      rowGap={textualTeaser.rowGap}
      gridGap={null}
      attrs={attrs}
    >
      <TeaserHeader
        {...data}
        typeScale={textualTeaser.headerTypeScale}
        onClick={biAction}
        wrapperMiscStyles={textualTeaser.headerWrapperMiscStyles}
        isGridItem
      />
      <TeaserFooter
        showAuthor
        showRank
        data={data}
        showCommentsCount={textualTeaser.footer.showCommentsCount}
      />
    </Teaser>
  );
}
