// @flow
import * as React from 'react';
import { WONG_LIST_QUERY as HERMES_LIST_QUERY, } from '@haaretz/graphql';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';
import HermesView from './HermesView.js';

type Props = {
  updateListDuplication: Function,
  variables: {},
  listData: ListDataType,
  viewProps: Object,
};

export default function Hermes(props: Props): React.Node {
  return (
    <ListWrapper query={HERMES_LIST_QUERY} view="Hermes" {...props}>
      {dataProps => (<HermesView {...dataProps} />)}
    </ListWrapper>
  );
}
