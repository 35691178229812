// @flow
import React from 'react';
import { useFela, } from 'react-fela';

import type { Node, } from 'react';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ClickTrackerBannerWrapperType, } from '../../../../flowTypes/ClickTrackerBannerWrapperType';

import VerticalList from '../commonViews/VerticalList';

type Props = {
  list: ListDataType,
  listId: string,
  gaAction: () => void,
  biAction: ?ListBiActionType,
};


function Michelangelo({ list, ...props }: Props): Node {
  const { theme, } = useFela();

  const banners = list.banners?.clickTrackerBanners;

  const titleSeparator = '~~';
  const rawTitle = list.title;
  const titleArray = rawTitle && rawTitle.split(titleSeparator).filter(Boolean);
  const title = titleArray || theme.clickTrackerI18n.promotedContentLabel;
  const propsWithTitle = { ...props, title, };

  return (banners && banners.length > 0) || (list.items && list.items.length > 0)
    ? (
      <VerticalList
        banners={banners}
        list={list}
        {...propsWithTitle}
        isCommercial
        attrs={{
          'data-test': 'michelangelo',
        }}
      />
    )
    : null;
}

export default Michelangelo;
