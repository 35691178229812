// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { GalleryDataType, } from '../../../../flowTypes/GalleryDataType';
import type { HTMLEmbedDataType, } from '../../../../flowTypes/HTMLEmbedDataType';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';
import type { CountdownType, } from '../../../../flowTypes/CountdownType';

import { isImage, isEmbed, isGallery, } from '../../../../utils/validateType.js';
import Picture from '../../../Image/Picture';

import RelatedLiveUpdates from './RelatedLiveUpdates';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserSubtitle from '../../../TeaserSubtitle/TeaserSubtitle';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import useGetMediaComponent from '../../../../hooks/useGetMediaComponent';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import useMedia from '../../../../hooks/useMedia';
import useGetComponent from '../../../../hooks/GetComponentContext/useGetComponent';
import useOneTime from '../../../../hooks/useOneTime';


import H from '../../../AutoLevels/H';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
import AboveBlockLink from '../../../BlockLink/AboveBlockLink';
import Highlight from '../../../Highlight/Highlight';
import HtzLink from '../../../HtzLink/HtzLink';
import RelatedArticleLinks from '../Wong/RelatedArticleLinks';
import LiveUpdateView from '../../../LiveUpdateView/LiveUpdateView';


export type Props = {
  list: ListDataType,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  isLazyloadImages: boolean,
  countdownObj: ?CountdownType,
  isRelatedArticlesAsLiveUpdate?: boolean,
  displayExclusiveMobile: boolean,
};

const areasTeaser = `
"media content"
"media  footer"
"media   links"
`;

const areasStackedTeaser = `
"media media media"
"...   content ..."
"...   footer  ..."
"...   links   ..."
`;

const areasStackedTeaserMobile = `
"...   content ..."
"...   footer  ..."
"media media media"
"...   links   ..."
`;
const areasStackedTeaserMobileNoLinks = `
"...   content ..."
"...   footer  ..."
"media media media"
`;

const styles = {
  rowTemplate: 'auto auto 1fr',
  colTemplate: [
    { until: 'l', value: '0 auto 0', },
    { from: 'l', until: 'xl', value: '7fr 5fr', },
    { from: 'xl', value: '50% 1fr', },
  ],
  colGap: [
    { until: 's', value: '2rem', },
    { from: 'l', value: '4rem', },
  ],
  rowGap: [
    { until: 'l', value: '1rem', },
    { from: 'l', value: '2rem', },
  ],
  miscStyles: {
    margin: [ { until: 's', value: '0 -2rem', }, ],
    gridColumnEnd: [
      { from: 'l', value: 'span 2', },
    ],
    width: 'auto',
  },
  typeScale: [
    { until: 's', value: 2, },
    { from: 's', until: 'l', value: 6, },
    { from: 'l', until: 'xl', value: 5, },
    { from: 'xl', value: 7, },
  ],
  kickerTypeScale: [ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ],
  kickerMiscStyles: {
    position: [ { until: 'l', value: 'absolute', }, ],
    top: '0',
    start: '0',
    zIndex: 1,
    pointerEvents: 'none',
    marginBottom: '1rem',
    marginInlineStart: [ { until: 's', value: '-2rem', }, ],
    marginInlineEnd: [ { until: 's', value: '-2rem', }, ],
    marginTop: [ { until: 'l', value: '-1rem', }, ],
    transform: [ { until: 'l', value: 'translateY(-100%)', }, ],
  },
  kickerInnerMiscStyles: {
    paddingInlineStart: [ { until: 's', value: '2rem', }, ],
    paddingInlineEnd: [ { until: 's', value: '2rem', }, ],
  },
  footerTypeScale: [
    { until: 's', value: -3, },
    { from: 's', until: 'xl', value: -2, },
    { from: 'xl', value: -3, },
  ],
};

Brannigan.defaultProps = {
  isRelatedArticlesAsLiveUpdate: true,
  isLazyloadImages: false,
  biAction: null,
  biImpression: null,
  countdownObj: null,
  displayExclusiveMobile: true,
};
export default function Brannigan({
  isRelatedArticlesAsLiveUpdate,
  displayExclusiveMobile,
  isLazyloadImages,
  list: {
    items: [ item, ],
  },
  biAction: rawBiAction,
  biImpression,
  countdownObj,
}: Props): React.Node {
  const isMobileBP = useMedia({ query: { until: 's', }, matchOnServer: false, });
  const isMediumBP = useMedia({ query: { from: 's', until: 'l', }, matchOnServer: false, });
  const { theme, css, } = useFela({ isRelatedArticlesAsLiveUpdate, });
  const getComponent = useGetComponent();
  const ImageGalleryTeaser = getComponent('imageGalleryTeaser');
  const getMediaComponent = useGetMediaComponent(Picture, ImageGalleryTeaser);
  const image = item?.mobileImage || item?.image;

  const media = item.media || image || null;
  const MediaComponent = getMediaComponent(media && media.kind);
  const mediaProps = getMediaProps(media, theme);
  const biAction = setBiAction(0, item, rawBiAction);

  useOneTime(item && !!biImpression && typeof biImpression === 'function', setBiImpression(0, item, biImpression));


  const {
    rowTemplate,
    colTemplate,
    colGap,
    rowGap,
    miscStyles,
    footerTypeScale,
  } = styles;

  const hasRelatedArticles = Array.isArray(item?.relatedArticles) && item.relatedArticles.length > 0;

  return (
    <Teaser
      areasTemplate={[
        { until: 's', value: item.relatedArticles ? areasStackedTeaserMobile : areasStackedTeaserMobileNoLinks, },
        { from: 's', until: 'l', value: areasStackedTeaser, },
        { from: 'l', until: 'xl', value: areasTeaser, },
        { from: 'xl', value: areasTeaser, },
      ]}
      colTemplate={colTemplate}
      rowTemplate={rowTemplate}
      colGap={colGap}
      rowGap={rowGap}
      gridGap={null}
      miscStyles={miscStyles}
      attrs={{
        'data-test': `brannigan${isRelatedArticlesAsLiveUpdate ? '' : '2'}`,
      }}
    >
      <TeaserMedia
        data={item}
        onClick={biAction}
        miscStyles={{
          '& iframe video': {
            left: '0 !important',
          }, }}
      >
        {mediaProps ? <MediaComponent {...mediaProps} lazyLoad={isLazyloadImages} /> : null}
        {(isMediumBP || (hasRelatedArticles && item.relatedArticles && isMobileBP))
          ? (
            <AboveBlockLink>
              {({ className, }) => (
                <div className={css(textWrapperStyle)}>
                  {item.title && (
                    // We use an offset here, because the title should be the same level
                    // as a header inside a section, no the same as a section's title
                    <H className={css(headlineInMediaStyle)}>
                      <HtzLink href={item.path} className={className}>
                        <Highlight
                          isBlock
                          highlightColor={[ 'amy', 'innerTextBg', ]}
                          displayBefore={false}
                          miscStyles={{
                            color: theme.color('amy', 'innerText'),
                            fontFamily: theme.fontStacks[theme.framedFont],
                          }}
                        >
                          <span className={css({
                            paddingInlineStart: '0.8rem',
                            paddingInlineEnd: '0.8rem',
                            ...theme.type(-1),
                            ...theme.mq({ from: 's', until: 'l', }, { marginInlineStart: '1rem', }),
                            ...theme.mq({ until: 's', }, { ':before': {
                              marginInlineEnd: '1rem',
                              transform: 'translateY(3%)',
                              animationDuration: '1s',
                              animationIterationCount: 'infinite',
                              animationDirection: 'alternate',
                              animationTimingFunction: 'linear',
                              animationName: {
                                '0%': { opacity: '0', },
                                '30%': { opacity: '0', },
                                '50%': { opacity: '1', },
                                '100%': { opacity: '1', },
                              },
                              display: 'inline-block',
                              content: '""',
                              borderRadius: '50%',
                              height: '1.5rem',
                              width: '1.5rem',
                              backgroundColor: 'white',
                            }, }),
                          })}
                          >
                            <TeaserResponsiveText
                              text={item.exclusiveMobile}
                              mobileText={theme.BranniganI18n.liveUpdates}
                            />
                          </span>
                        </Highlight>
                      </HtzLink>
                    </H>
                  )}
                </div>
              )}
            </AboveBlockLink>
          ) : null}
      </TeaserMedia>
      <TeaserContent
        miscStyles={{
          paddingTop: [ { until: 's', value: '3rem', }, ],
          textAlign: [ { until: 's', value: 'center', }, ],
        }}
      >
        {item.exclusiveMobile ? (
          <LiveUpdateView
            text={item.exclusiveMobile}
            textMiscStyles={{
              margin: [ { until: 's', value: 'auto 0', }, ],
            }}
            miscStyles={{
              justifyContent: [ { until: 's', value: 'center', }, ],
              display: [ { until: 's', value: 'flex', }, { from: 's', value: 'none', }, ],
              type: -1,
              paddingBottom: '1rem',
              fontFamily: theme.fontStacks[theme.framedFont],
            }}
            showDiameter={false}
          />
        ) : null}
        {item.exclusive && (!isMobileBP || !displayExclusiveMobile)
          ? (
            <H className={css(headlineStyle)}>
              <Highlight
                isBlock
                highlightColor={[ 'amy', 'innerTextBg', ]}
                displayBefore={false}
                miscStyles={{
                  color: theme.color('amy', 'innerText'),
                  fontFamily: theme.fontStacks[theme.framedFont],
                }}
              >
                <span className={css({ paddingInlineStart: '1rem', paddingInlineEnd: '1rem', })}>
                  <TeaserResponsiveText text={item.exclusive} mobileText={item.exclusiveMobile} />
                </span>
              </Highlight>
            </H>
          ) : null}
        <TeaserHeader
          kickerIsBlock
          isH1
          {...item}
          typeScale={[
            { until: 's', value: 2, },
            { from: 's', until: 'l', value: 5, },
            { from: 'l', until: 'xl', value: 4, },
            { from: 'xl', value: 5, },
          ]}
          showKicker={false}
          onClick={biAction}
          {...(countdownObj
            ? {
              showKicker: false,
              countdownObj,
            }
            : {})}
        />
        {!hasRelatedArticles && (
          <TeaserSubtitle
            {...item}
            typeScale={[
              { from: 's', until: 'xl', value: 0, },
              { from: 'xl', value: 0, },
            ]}
            miscStyles={{
              display: [ { until: 's', value: 'none', }, ],
              marginTop: [ { from: 's', value: '1rem', }, ],
              fontWeight: 400,
            }}
          />
        )}
      </TeaserContent>

      <TeaserFooter
        swapReportingFromAndTime
        showAuthor
        data={item}
        typeScale={footerTypeScale}
        miscStyles={{
          paddingBottom: '1rem',
          textAlign: [ { until: 's', value: 'center', }, ],
        }}
      />
      {hasRelatedArticles && (
        isRelatedArticlesAsLiveUpdate ? (
          <RelatedLiveUpdates
            miscStyles={{
              paddingTop: [ { until: 'l', value: '2rem', }, { from: 'l', value: '1rem', }, ],
              paddingBottom: [ { until: 'm', value: '1rem', }, ],
              fontFamily: theme.fontStacks[theme.framedFont],
            }}
            relatedArticles={item.relatedArticles}
            biAction={rawBiAction}
          />
        )
          : (
            <RelatedArticleLinks
              relatedArticles={item.relatedArticles}
              biAction={rawBiAction}
              relatedPadding="0"
            />
          )
      )}
    </Teaser>
  );
}

// /////////////////////////////////////////////////////////////////////
//                               UTILS                                //
// /////////////////////////////////////////////////////////////////////

const branniganPictureOptions = (theme, imgData) => ({
  bps: theme.bps,
  imgData,
  defaultImgOptions: {
    bps: theme.bps,
    aspect: 'regular',
    sizes: [
      { from: 'xl', size: '604px', },
      { from: 'l', size: '556px', },
      { from: 'm', size: '720px', },
      { from: 's', size: '552px', },
      { size: '100wv', },
    ],
    widths: [ 375, 470, 600, 720, ],
  },
  sources: [
    {
      aspect: 'headline',
      until: 'l',
      sizes: [
        { from: 'l', size: '556px', },
        { from: 'm', size: '720px', },
        { size: '100wv', },
      ],
      widths: [ 556, 772, 1000, ],
    },
  ],
});

function getImageProps(
  media: ImageDataType,
  theme: Object
): Object {
  return getPictureAssets(branniganPictureOptions(theme, media));
}


function getEmbedProps(media: HTMLEmbedDataType): Object {
  return media.inputTemplate === 'Youtube'
    ? {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: {
        ...media.settings,
        controls: '0',
        autoplay: true,
        loop: '1',
        logo: '1',
        startAt: 0,
        related: '0',
        mute: true,
      },
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    }
    : {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: media.settings,
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    };
}

function getMediaProps(
  media: ?(ImageDataType | HTMLEmbedDataType | GalleryDataType),
  theme: Object
): ?Object {
  if (media) {
    if (isImage(media)) return getImageProps(media, theme);
    if (isEmbed(media)) return getEmbedProps(media);
    if (isGallery(media)) {
      return {
        ...media,
        showTitle: false,
        isPicture: true,
        imgOptions: branniganPictureOptions,
      };
    }
  }
  return null;
}

// /////////////////////////////////////////////////////////////////////
//                               STYLE                                //
// /////////////////////////////////////////////////////////////////////

function textWrapperStyle({ theme, }) {
  return {
    backgroundColor: theme.color('tertiary'),
    paddingTop: theme.mq({ until: 's', value: '0.5rem', }),
    paddingInlineEnd: '1rem',
    position: 'absolute',
    start: 0,
    bottom: 0,
    maxWidth: '90%',
  };
}

function headlineStyle({ theme, }) {
  return {
    fontWeight: '700',
    extend: [
      theme.mq({ until: 'l', }, { display: 'none', }),
      theme.mq({ from: 'l', }, { marginBottom: '2rem', }),
      theme.type(-2, { untilBp: 's', lines: 3.9, }),
      theme.type(-1, { fromBp: 's', lines: 4.3, }),
    ],
  };
}


function headlineInMediaStyle({ theme, isRelatedArticlesAsLiveUpdate, }) {
  return {
    fontWeight: '700',
    extend: [
      ...isRelatedArticlesAsLiveUpdate ? [] : [ theme.mq({ until: 's', }, { display: 'none', }), ],
      theme.mq({ from: 'l', }, { display: 'none', }),
      theme.mq({ until: 's', }, { marginTop: '0.5rem', marginInlineStart: '0.5rem', }),
      theme.type(-2, { untilBp: 's', lines: 3.9, }),
    ],
  };
}
