// @flow
import * as React from 'react';
import { VOGEL_LIST_QUERY, } from '@haaretz/graphql';
import VogelView from './VogelView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ItemPlaceholder from '../../../ItemPlaceholder/ItemPlaceholder';
import ListWrapper from '../../ListWrapper';

type VogelWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function VogelWrapper(props: VogelWrapperProps) {
  return (
    <ListWrapper {...props} query={VOGEL_LIST_QUERY} Placeholder={ItemPlaceholder} view="Vogel">
      {dataProps => <VogelView {...dataProps} />}
    </ListWrapper>
  );
}
