// @flow
import React from 'react';
import { FRY_LIST_QUERY, } from '@haaretz/graphql';
import type { Node, } from 'react';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

import FryView from './FryView.js';
import ListWrapper from '../../ListWrapper';

type Props = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
};

export default function Fry(props: Props): Node {
  return (
    <ListWrapper {...props} query={FRY_LIST_QUERY} view="Fry">
      {dataProps => <FryView {...dataProps} />}
    </ListWrapper>
  );
}
