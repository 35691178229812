// @flow
/* global window */
import React from 'react';
import { BEAVIS_LIST_QUERY, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

import BeavisView from './BeavisView.js';

type BeavisWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function BeavisWrapper(props: BeavisWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={BEAVIS_LIST_QUERY} view="Beavis">
      {dataProps => <BeavisView {...dataProps} />}
    </ListWrapper>
  );
}
