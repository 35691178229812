// @flow
import * as React from 'react';
import { MOM_LIST_QUERY, } from '@haaretz/graphql';
import MomView from './MomView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type MomWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
  noMargin?: boolean,
};

MomWrapper.defaultProps = {
  noMargin: false,
};

export default function MomWrapper(props: MomWrapperProps) {
  return (
    <ListWrapper {...props} query={MOM_LIST_QUERY} view="Mom">
      {dataProps => <MomView noMargin={props.noMargin} {...dataProps} />}
    </ListWrapper>
  );
}
