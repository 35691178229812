// @flow
import * as React from 'react';
import { INEZ_LIST_QUERY, } from '@haaretz/graphql';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';
import InezView from './InezView.js';

type Props = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
};

export default function Wong(props: Props): React.Node {
  return (
    <ListWrapper {...props} query={INEZ_LIST_QUERY} view="Inez">
      {dataProps => <InezView {...dataProps} />}
    </ListWrapper>
  );
}
