// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { GalleryDataType, } from '../../../../flowTypes/GalleryDataType';
import type { HTMLEmbedDataType, } from '../../../../flowTypes/HTMLEmbedDataType';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { CountdownType, } from '../../../../flowTypes/CountdownType';

import { isImage, isEmbed, isGallery, } from '../../../../utils/validateType.js';
import Picture from '../../../Image/Picture';
import RelatedArticleLinks from '../Wong/RelatedArticleLinks';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserSubtitle from '../../../TeaserSubtitle/TeaserSubtitle';
import useGetMediaComponent from '../../../../hooks/useGetMediaComponent';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import useGetComponent from '../../../../hooks/GetComponentContext/useGetComponent';

type Props = {
  list: ListDataType,
  biAction: ?ListBiActionType,
  isLazyloadImages: boolean,
  countdownObj: ?CountdownType,
};

const areasTeaser = `
"media media"
"content ."
"footer  ."
"links   ."
`;

const areasStackedTeaser = `
"media media media"
"...   content ..."
"...   footer  ..."
"...   links   ..."
`;

const sideTeaser = `
"media  content"
"media   footer"
"media   links"
`;

const rowTemplateWithExclusiveUntilL = {
  mobile: {
    until: 's',
    value:
      '[media-start] auto [content-start] 4rem [media end] auto [content-end footer-start] auto [footer-end] 0',
  },
  desktop: {
    from: 's',
    until: 'l',
    value:
      '[media-start] auto [content-start] 4rem [media end] auto [content-end footer-start] auto [footer-end links-start] auto [links-end] 0',
  },
};

Inez.defaultProps = {
  isLazyloadImages: false,
  biAction: null,
  countdownObj: null,
};
export default function Inez({
  isLazyloadImages,
  list: {
    items: [ item, ],
  },
  biAction: rawBiAction,
  countdownObj,
}: Props): React.Node {
  const relatedPadding = '2rem';
  const { theme, } = useFela();
  const getComponent = useGetComponent();
  const ImageGalleryTeaser = getComponent('imageGalleryTeaser');
  const getMediaComponent = useGetMediaComponent(Picture, ImageGalleryTeaser.name !== 'Default' ? ImageGalleryTeaser : undefined);
  const media = item.media || null;
  const MediaComponent = getMediaComponent(media && media.kind);
  const mediaProps = getMediaProps(media, theme);
  const biAction = setBiAction(0, item, rawBiAction);

  const areasTemplate = [
    ...(item.exclusiveMobile ? [] : [ { until: 's', value: areasStackedTeaser, }, ]),
    ...(item.exclusive ? [] : [ { from: 's', until: 'l', value: areasStackedTeaser, }, ]),
    { from: 'l', until: 'xl', value: sideTeaser, },
    { from: 'xl', value: areasTeaser, },
  ];
  const rowTemplate = [
    ...(item.exclusiveMobile ? [ rowTemplateWithExclusiveUntilL.mobile, ] : []),
    ...(item.exclusive ? [ rowTemplateWithExclusiveUntilL.desktop, ] : []),
    { from: 'l', until: 'xl', value: 'auto  auto auto', },
    { from: 'xl', value: 'auto  auto 1fr', },
  ];
  return (
    <Teaser
      areasTemplate={areasTemplate}
      colTemplate={[
        {
          until: 'l',
          value:
            '[media start] 0 [content-start footer-start links-start] auto [content-end footer-end links-end] 0 [media-end]',
        },
        { from: 'l', until: 'xl', value: '7fr 5fr', },
        { from: 'xl', value: '1fr 0', },
      ]}
      rowTemplate={rowTemplate}
      colGap={[
        { until: 's', value: '2rem', },
        { from: 'l', until: 'xl', value: '4rem', },
        { from: 'xl', value: '2rem', },
      ]}
      rowGap="1rem"
      gridGap={null}
      miscStyles={{
        gridColumnEnd: [ { from: 'l', until: 'xl', value: 'span 2', }, ],
        margin: [ { until: 's', value: '0 -2rem', }, ],
        width: 'auto',
      }}
      attrs={{
        'data-test': 'inez',
      }}
    >
      <TeaserMedia data={item} onClick={biAction}>
        {mediaProps ? <MediaComponent {...mediaProps} lazyLoad={isLazyloadImages} /> : null}
      </TeaserMedia>
      <TeaserContent>
        <TeaserHeader
          kickerIsBlock
          isH1
          {...item}
          typeScale={[
            { until: 's', value: 2, },
            { from: 's', until: 'l', value: 6, },
            { from: 'l', until: 'xl', value: 5, },
            { from: 'xl', value: 4, },
          ]}
          kickerTypeScale={[ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
          kickerMiscStyles={{
            position: 'relative',
            zIndex: 1,
            pointerEvents: 'none',
            marginBottom: '1rem',
            marginInlineStart: [ { until: 's', value: '-2rem', }, ],
            marginInlineEnd: [ { until: 's', value: '-2rem', }, ],
          }}
          kickerInnerMiscStyles={{
            paddingInlineStart: [ { until: 's', value: '2rem', }, ],
            paddingInlineEnd: [ { until: 's', value: '2rem', }, ],
          }}
          onClick={biAction}
          {...(countdownObj
            ? {
              showKicker: false,
              countdownObj,
            }
            : {})}
        />
        <TeaserSubtitle
          {...item}
          typeScale={[
            { from: 's', until: 'l', value: 0, },
            { until: 'xl', value: 0, },
            { from: 'xl', value: -1, },
          ]}
          miscStyles={{
            display: [ { until: 's', value: 'none', }, ],
            marginTop: [ { from: 's', value: '1rem', }, ],
            fontWeight: 400,
          }}
        />
      </TeaserContent>
      <TeaserFooter
        showAuthor
        showLive={!!item.liveUpdates}
        data={item}
        typeScale={[
          { until: 's', value: -3, },
          { from: 's', until: 'xl', value: -2, },
          { from: 'xl', value: -3, },
        ]}
      />
      {item.relatedArticles && (
        <RelatedArticleLinks
          relatedArticles={item.relatedArticles}
          biAction={rawBiAction}
          relatedPadding={relatedPadding}
        />
      )}
    </Teaser>
  );
}

// /////////////////////////////////////////////////////////////////////
//                               UTILS                                //
// /////////////////////////////////////////////////////////////////////

const imgOptions = theme => ({
  bps: theme.bps,
  aspect: 'regular',
  sizes: [
    { from: 'xl', size: '604px', },
    { from: 'l', size: '556px', },
    { from: 'm', size: '720px', },
    { from: 's', size: '552px', },
    { size: '100wv', },
  ],
  widths: [ 375, 469, 556, 604, 720, ],
});

function getImageProps(media: ImageDataType, theme: Object): Object {
  return getPictureAssets({
    bps: theme.bps,
    imgData: media,
    defaultImgOptions: {
      sizes: [ { from: 'l', size: '480px', }, { size: '100vw', }, ],
      aspect: 'regular',
      widths: [ 375, 470, 600, ],
    },
    sources: [
      {
        from: 's',
        until: 'l',
        aspect: 'headline',
        sizes: [ { from: 'm', size: '720px', }, { size: '552px', }, ],
        widths: [ 556, 772, 1000, ],
      },
      {
        from: 'l',
        until: 'xl',
        aspect: 'headline',
        sizes: [ { from: 'l', until: 'xl', size: '390px', }, ],
        widths: [ 390, 800, 1000, ],
      },
    ],
  });
}

function getEmbedProps(media: HTMLEmbedDataType): Object {
  return media.inputTemplate === 'Youtube'
    ? {
      ...media,
      source: media.source,
      videoId: media.videoId,
      embedType: media.embedType,
      settings: {
        ...media.settings,
        controls: '0',
        autoplay: true,
        loop: '1',
        logo: '1',
        startAt: 0,
        related: '0',
        mute: true,
      },
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    }
    : {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: media.settings,
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    };
}

export function getMediaProps(
  media: ?(ImageDataType | HTMLEmbedDataType | GalleryDataType),
  theme: Object
): ?Object {
  if (media) {
    if (isImage(media)) return getImageProps(media, theme);
    if (isEmbed(media)) return getEmbedProps(media);
    if (isGallery(media)) return { ...media, imgOptions, disableFullScreen: true, };
  }
  return null;
}
