// @flow
import * as React from 'react';
import ListView from '../../../ListView/NewListView';
import Section from '../../../AutoLevels/Section';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import VogelItem from './VogelItem';
import VogelListTitle from './VogelListTitle';
import setBiAction from '../../../../utils/setBiAction';

type VogelViewPropTypes = {
  list: ListDataType,
  gaAction: ?() => void,
  biAction: ?() => void,
  isLazyloadImages: boolean,
};

VogelView.defaultProps = {
  isLazyloadImages: true,

};

export default function VogelView({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
}: VogelViewPropTypes): React.Node {
  const { items, title, url, } = list || {};
  if (!(items && items.length)) return null;

  function itemRenderer(data, i) {
    return data && i < 3 && (
      <VogelItem
        key={data.contentId}
        data={data}
        hideImageOnMobile={i === 0}
        hideImage={i > 0}
        hideSeparatorOnMobile={i === 1}
        {...(i === 2 ? { hideOnMobile: true, hideSeparator: true, } : {})}
        biAction={setBiAction(i, data, biAction)}
        isLazyloadImages={isLazyloadImages}
        index={i}
      />
    );
  }
  return (
    <ListView
      keepHeadingLevel
      marginTop="0"
      gridGap="1rem"
      rowTemplate="min-content"
      padding="0"
      outerBackgroundColor="white"
      sectionMiscStyles={{ display: 'flex', }}
      attrs={{
        'data-test': 'vogel',
      }}
    >
      {
        list.title
          ? (
            <VogelListTitle
              title={title}
              url={url}
            />
          )
          : null
        }
      <Section isFragment>{items.map(itemRenderer)}</Section>
    </ListView>
  );
}
