// @flow
import React from 'react';
import type { Node, } from 'react';
import { useFela, } from 'react-fela';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import AdSlot from '../../../AdManager/AdSlot';

type BeavisPropsType = {
  list: ListDataType,
  isLazyloadImages: boolean,
  biAction: ?ListBiActionType,
  gaAction: ?() => void,
};

Beavis.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

function Beavis({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: BeavisPropsType): Node {
  const { theme, } = useFela();
  const { adSlots, } = list.banners || {};
  const title = list
    && typeof list.title === 'string'
    && list.title.toLowerCase().includes('designer')
    ? [ list.title, 'תוכן מקודם', ]
    : list && list.title;

  return (
    <ListView
      colTemplate={[
        { from: 'l', until: 'xl', value: '2fr 5fr 5fr', },
        { from: 'xl', value: '1fr 3fr 2fr', },
      ]}
      gridGap={null}
      colGap="4rem"
      rowGap="1rem"
      attrs={{
        'data-test': 'beavis',
      }}
    >
      {/* Header */}
      <ListViewHeader
        isSticky
        title={title}
        isCommercial
        miscStyles={{ gridArea: '1 / 1 / 2 / 2', }}
        additionalTitleMiscStyles={{
          color: '#505050',
          ...theme.mq(
            { until: 's', },
            {
              ...theme.type(-2),
            }
          ),
          ...theme.mq({ from: 's', until: 'l', }, { fontWeight: '700', }),
          ...theme.mq(
            { from: 'l', until: 'xl', },
            { justifySelf: 'flex-start', marginTop: '1rem', fontWeight: '700', }
          ),
          ...theme.mq(
            { from: 'xl', },
            {
              justifySelf: 'flex-start',
              marginTop: '1rem',
              ...theme.type(-2),
              fontWeight: '700',
            }
          ),
        }}
        titleMiscStyles={{
          display: 'block',
        }}
      />

      {/* Items */}
      {Array.isArray(adSlots)
        && adSlots.map(data => (data ? (
          <AdSlot
            key={data.contentId}
            {...data}
            loadPriority={list.loadPriority}
          />
        ) : null)
        )}
    </ListView>
  );
}

export default Beavis;
