// @flow
import React, { Fragment, useState, } from 'react';
import { FelaTheme, FelaComponent, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import type { Node, } from 'react';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import type { Asset, } from '../../flowTypes/asset';
import type { Filter, } from '../SortableTable/SortableTable';
import type { Period, } from '../AssetStats/AssetStats';

import Grid from '../Grid/Grid';
import GridItem from '../Grid/GridItem';
import IconTmLoader from '../Icon/icons/IconTmLoader';
import AssetsTable from '../AssetsTable/AssetsTable';
import SectionLink from '../SectionLink/SectionLink';

import GraphController from '../GraphController/GraphController';
import NoData from '../NoData/NoData';

type Props = {
  filters: ?(Filter[]),
  count: ?number,
  assetsId: ?Array<string>,
  sortBy: ?string,
  sortOrder: ?'asc' | ?'desc',
  miscStyles?: ?StyleProps,
  headers?: ?({
    display: string,
    percentage?: boolean,
    value: string,
    style: ?Object,
  }[]),
  disableScatter?: boolean,
  disableTimeTabs?: boolean,
  loadingHeight?: ?number,
  isTmHomePage?: ?boolean,
  periods?: ?Array<{ display: string, value: Period, }>,
};

TableGraphConnector.defaultProps = {
  filters: null,
  count: null,
  assetsId: null,
  sortBy: null,
  sortOrder: null,
  miscStyles: null,
  headers: null,
  disableScatter: false,
  disableTimeTabs: false,
  loadingHeight: null,
  isTmHomePage: false,
  periods: null,
};

function TableGraphConnector({
  filters,
  assetsId,
  sortBy,
  sortOrder,
  count,
  miscStyles,
  headers,
  disableScatter,
  disableTimeTabs,
  loadingHeight,
  isTmHomePage,
  periods,
}: Props): Node {
  const [ id, setId, ] = useState(null);
  const [ name, setName, ] = useState(null);
  const [ type, setType, ] = useState(null);

  const changeAsset: Asset => void = stockData => {
    setId(stockData.id);
    setName(stockData.name);
    setType(stockData.type);
  };

  return (
    <FelaComponent
      style={({ theme, }) => ({
        extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
      })}
    >
      <FelaTheme>
        {theme => (
          <Grid
            gutter={2}
            miscStyles={{
              backgroundColor: theme.color('neutral', '-10'),
              marginStart: '0rem',
              marginEnd: '0rem',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <GridItem
              width={[ { from: 's', value: 1 / 3, }, { until: 's', value: 1, }, ]}
              miscStyles={{
                direction: 'ltr',
                overflowY: 'auto',
                position: 'relative',
              }}
            >
              <AssetsTable
                changeAsset={changeAsset}
                sortBy={sortBy}
                sortOrder={sortOrder}
                filters={filters}
                assetsId={assetsId}
                count={count || (assetsId ? assetsId.length : 0)}
                headers={
                  headers || [
                    {
                      display: 'שם אפיק',
                      value: 'name',
                      style: {
                        paddingStart: '1rem',
                        paddingTop: '0.75rem',
                        paddingBottom: '0.75rem',
                        paddingEnd: '2rem',
                        marginBottom: '1rem',
                        backgroundColor: theme.color('neutral', '-6'),
                      },
                    },
                    {
                      display: 'שער אחרון',
                      value: 'value',
                      style: {
                        paddingEnd: '2rem',
                        paddingTop: '0.75rem',
                        paddingBottom: '0.75rem',
                        marginBottom: '1rem',
                        backgroundColor: theme.color('neutral', '-6'),
                      },
                    },
                    {
                      display: '% שינוי',
                      percentage: true,
                      value: 'changePercentage',
                      style: {
                        paddingEnd: '2rem',
                        paddingTop: '0.75rem',
                        paddingBottom: '0.75rem',
                        marginBottom: '1rem',
                        backgroundColor: theme.color('neutral', '-6'),
                      },
                    },
                  ]
                }
                isTmHomePage={isTmHomePage}
                miscStyles={{ direction: 'rtl', }}
              />
            </GridItem>
            <GridItem width={2 / 3} miscStyles={{ display: [ { until: 's', value: 'none', }, ], }}>
              {id && name && type ? (
                <Fragment>
                  <GraphController
                    assetId={id}
                    scatter={type === 'indices'}
                    disableScatter={disableScatter}
                    disableTimeTabs={disableTimeTabs}
                    loadingHeight={loadingHeight}
                    periods={periods}
                  />
                  <SectionLink
                    href={{
                      pathname: `${
                        isTmHomePage ? 'https://finance.themarker.com' : ''
                      }/asset/${type || ''}`,
                      query: {
                        assetId: id,
                        section: type,
                      },
                    }}
                    as={`${isTmHomePage ? 'https://finance.themarker.com' : ''}/${type
                      || ''}/${id || ''}`}
                  >
                    <span>{`למידע נוסף על ${name}`}</span>
                  </SectionLink>
                </Fragment>
              ) : (
                <NoData
                  bgc={[ 'neutral', '-1', ]}
                  color={[ 'neutral', '-10', ]}
                  miscStyles={{
                    height: loadingHeight ? `${loadingHeight}px` : '100%',
                  }}
                >
                  <IconTmLoader size={9} color={[ 'primary', '-1', ]} />
                </NoData>
              )}
            </GridItem>
          </Grid>
        )}
      </FelaTheme>
    </FelaComponent>
  );
}

export default TableGraphConnector;
