// @flow
import * as React from 'react';
import { KROKER_LIST_QUERY, } from '@haaretz/graphql';

import KrokerView from './KrokerView.js';
import ListWrapper from '../../ListWrapper';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type KrokerWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
}

export default function KrokerWrapper(props: KrokerWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={KROKER_LIST_QUERY} view="Kroker">
      {dataProps => <KrokerView {...dataProps} />}
    </ListWrapper>
  );
}
