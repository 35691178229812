// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ClickTrackerBannerWrapperType, } from '../../../../flowTypes/ClickTrackerBannerWrapperType';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Picture from '../../../Image/Picture';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import Footer from '../../../TeaserFooter/TeaserFooter';
import getImageAssets from '../../../../utils/getImageAssets';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import HtzLink from '../../../HtzLink/HtzLink';
import AdSlot from '../../../AdManager/AdSlot';

AntonioC.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const teasers = [ Teaser1, Teaser2, Teaser3, Teaser4, Teaser5, Teaser6, ];
const areasTemplate = [
  { until: 's', value: '"he" "t1" "t3" "t4" "t6"', },
  {
    from: 's',
    until: 'l',
    value: `
    "he"
    "t1"
    "t3"
    "t4"
    "t6"
    `,
  },
  {
    from: 'l',
    until: 'xl',
    value: `
    "he he t1 t1 t1 t1 t1 t3 t3 t3 t3 t3 "
    "he he t1 t1 t1 t1 t1 t4 t4 t4 t4 t4"
    "he he t1 t1 t1 t1 t1 t6 t6 t6 t6 t6"
    `,
  },
  {
    from: 'xl',
    value: `
    "he t1 t1 t3 t3 ad"
    "he t1 t1 t4 t4 ad"
    "he t1 t1 .. .. ct"
    "he t1 t1 t6 t6 ct"
    `,
  },
];

export default function AntonioC({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const { items, extraLinks, banners, ...restOfList } = list || {};
  const { clickTrackerBanners, adSlots, } = banners || {};
  if (!(items && items.length)) return null;

  function itemRenderer(data, i) {
    if (data && data.inputTemplate === 'AdSlot') return AdSlotTeaser({ ...data, isLazyloadImages, });
    if (data && data.inputTemplate === 'ClickTrackerWrapper') return ClickTrackerTeaser({ ...data, isLazyloadImages, });
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        biAction: setBiAction(i, data, biAction),
        isLazyloadImages,
        theme,
      });
    }
    return null;
  }

  const clickTracker = clickTrackerBanners && clickTrackerBanners[0];
  const adSlotItem = adSlots && adSlots[0];
  const itemsWithDfpAndClickTrackers = [ ...items, ];

  // insert dfp and click tracker in their position if they exist
  if (adSlotItem) itemsWithDfpAndClickTrackers.splice(1, 0, adSlotItem);
  if (clickTracker) itemsWithDfpAndClickTrackers.splice(4, 0, clickTracker);

  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[
        { from: 's', until: 'l', value: '1fr', },
        { from: 'l', until: 'xl', value: 'repeat(12, 1fr)', },
        { from: 'xl', value: 'repeat(6, 1fr)', },
      ]}
      rowTemplate={[
        // { from: 's', until: 'l', value: '1fr 1fr 1fr 1fr 1fr 1fr', },
        // { from: 'l', until: 'xl', value: '2fr 5fr 5fr', },
        { from: 'l', until: 'xl', value: '1fr 1fr 1fr', },
        { from: 'xl', value: 'auto auto 4rem calc((100% - 8rem) / 3)', },
      ]}
      rowGap={[
        { until: 's', value: '2rem', },
        { from: 's', until: 'xl', value: '4rem', },
        { from: 'xl', value: '0', },
      ]}
      gridGap={[ { from: 's', value: '0 4rem', }, ]}
      colGap={[ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ]}
      attrs={{
        'data-test': 'antonioc',
      }}
    >
      <ListViewHeader
        {...restOfList}
        extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
        biAction={biAction}
        isSticky
      />
      <Section isFragment>{itemsWithDfpAndClickTrackers.map(itemRenderer)}</Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                         Teaser Components                          //
// /////////////////////////////////////////////////////////////////////

const teaserDefaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

const areasMediaObjTeaser = `
"media  ."
"media content"
"media footer"
`;
const areasStackedTeaser = `
"media"
"content"
"footer"
`;
const stackedTeaserRow = 'auto 1fr auto';
const mediaObjTeaserRow = '0 1fr auto';

type TeaserPropsType = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  theme: Object,
};

Teaser1.defaultProps = teaserDefaultProps;
function Teaser1({ data, isLazyloadImages, biAction, theme, }: TeaserPropsType): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      gridArea="t1"
      areasTemplate={[
        { until: 's', value: areasStackedTeaser, },
        { from: 's', until: 'l', value: areasMediaObjTeaser, },
        { from: 'l', value: areasStackedTeaser, },
      ]}
      // areasTemplate={areasStackedTeaser}
      colTemplate={[
        { until: 's', value: '1fr', },
        { from: 's', until: 'l', value: '7fr 5fr', },
        { from: 'l', value: '1fr', },
      ]}
      rowTemplate={[
        { until: 's', value: stackedTeaserRow, },
        { from: 's', until: 'l', value: mediaObjTeaserRow, },
        { from: 'l', value: stackedTeaserRow, },
      ]}
      rowGap="1rem"
      colGap="2rem"
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              aspect: 'headline',
              sizes: [
                { from: 'xl', size: '597px', },
                { from: 'l', size: '567px', },
                { from: 'm', size: '472px', },
                { from: 's', size: '360px', },
                { size: 'calc(100vw - 24px)', },
              ],
              widths: [ 597, 567, 472, 360, 400, ],
            },
            sources: [
              {
                aspect: 'regular',
                from: 's',
                sizes: [
                  { from: 'l', size: '567px', },
                  { from: 'm', size: '472px', },
                  { from: 's', size: '360px', },
                  { size: 'calc(100vw - 24px)', },
                ],
                widths: [ 567, 600, 1080, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={[
          { until: 's', value: 1, },
          { from: 's', until: 'xl', value: 3, },
          { from: 'xl', value: 2, },
        ]}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{
          padding: [ { until: 'l', value: '0 1rem', }, { from: 'l', value: '2rem 1rem 0', }, ],
        }}
      />
      <Footer data={data} showRank showAuthor miscStyles={{ padding: '0 1rem 1rem', }} />
    </Teaser>
  );
}

Teaser2.defaultProps = teaserDefaultProps;
function Teaser2({ data, isLazyloadImages, biAction, theme, }: TeaserPropsType): React.Node {
  const horizontalPadding = {
    paddingInlineEnd: '1rem',
    paddingInlineStart: [ { from: 's', until: 'l', value: '1rem', }, { from: 'xl', value: '1rem', }, ],
  };

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      gridArea="ad"
      areasTemplate={[
        { until: 's', value: areasMediaObjTeaser, },
        { from: 's', until: 'l', value: areasStackedTeaser, },
        { from: 'l', until: 'xl', value: areasMediaObjTeaser, },
        { from: 'xl', value: areasStackedTeaser, },
      ]}
      colTemplate={[
        { until: 's', value: '19rem 1fr', },
        { from: 'l', until: 'xl', value: '2fr 3fr', },
      ]}
      rowTemplate={[
        { until: 's', value: mediaObjTeaserRow, },
        { from: 's', until: 'l', value: stackedTeaserRow, },
        { from: 'l', until: 'xl', value: mediaObjTeaserRow, },
        { from: 'xl', value: stackedTeaserRow, },
      ]}
      colGap="1rem"
      rowGap="1rem"
      gridGap={null}
      miscStyles={{ paddingBottom: [ { from: 'xl', value: '4rem', }, ], }}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: [
                { from: 'l', size: '154px', },
                { from: 'm', size: '224px', },
                { from: 's', size: '168px', },
                { size: '108px', },
              ],
              aspect: 'square',
              widths: [ 108, 154, 168, 204, 224, 400, ],
            },
            sources: [
              {
                aspect: 'vertical',
                from: 'xl',
                sizes: '178px',
                widths: [ 178, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={[
          { until: 's', value: 0, },
          { from: 's', until: 'xl', value: 1, },
          { from: 'xl', value: -1, },
        ]}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{
          ...horizontalPadding,
          paddingTop: [ { until: 's', value: '1rem', }, { from: 'l', until: 'xl', value: '1rem', }, ],
        }}
      />
      <Footer data={data} miscStyles={{ ...horizontalPadding, paddingBottom: '1rem', }} />
    </Teaser>
  );
}

Teaser3.defaultProps = teaserDefaultProps;
function Teaser3({ data, isLazyloadImages, biAction, theme, }: TeaserPropsType): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      gridArea="t3"
      areasTemplate={areasMediaObjTeaser}
      colTemplate={[
        { until: 's', value: '19rem 1fr', },
        { from: 's', until: 'l', value: '1fr 2fr', },
        { from: 'l', until: 'xl', value: '2fr 3fr', },
        { from: 'xl', value: '4fr 8fr', },
      ]}
      rowTemplate={mediaObjTeaserRow}
      colGap="2rem"
      rowGap="1rem"
      gridGap={null}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: [ { from: 'l', until: 'xl', size: '154px', }, { size: '108px', }, ],
              aspect: 'square',
              widths: [ 108, 154, 216, ],
            },
            sources: [
              {
                aspect: 'square',
                from: 'xl',
                sizes: '189px',
                widths: [ 189, ],
              },
              {
                aspect: 'regular',
                from: 's',
                until: 'l',
                sizes: [
                  { from: 'm', until: 'l', size: '236px', },
                  { from: 's', until: 'm', size: '180px', },
                ],
                widths: [ 180, 376, 236, 512, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={[
          { until: 's', value: 0, },
          { from: 's', until: 'xl', value: 1, },
          { from: 'xl', value: -1, },
        ]}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{ paddingInlineEnd: '1rem', }}
      />
      <Footer data={data} miscStyles={{ paddingBottom: '1rem', paddingInlineEnd: '1rem', }} />
    </Teaser>
  );
}

Teaser4.defaultProps = teaserDefaultProps;
function Teaser4({ data, isLazyloadImages, biAction, theme, }: TeaserPropsType): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      gridArea="t4"
      areasTemplate={areasMediaObjTeaser}
      colTemplate={[
        { until: 's', value: '19rem 1fr', },
        { from: 's', until: 'l', value: '1fr 2fr', },
        { from: 'l', until: 'xl', value: '2fr 3fr', },
        { from: 'xl', value: '4fr 8fr', },
      ]}
      rowTemplate={mediaObjTeaserRow}
      colGap="2rem"
      rowGap="1rem"
      gridGap={null}
      miscStyles={{ marginTop: [ { from: 'xl', value: '4rem', }, ], }}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: [ { from: 'l', until: 'xl', size: '154px', }, { size: '108px', }, ],
              aspect: 'square',
              widths: [ 108, 154, 216, ],
            },
            sources: [
              {
                aspect: 'square',
                from: 'xl',
                sizes: '189px',
                widths: [ 189, ],
              },
              {
                aspect: 'regular',
                from: 's',
                until: 'l',
                sizes: [
                  { from: 'm', until: 'l', size: '236px', },
                  { from: 's', until: 'm', size: '180px', },
                ],
                widths: [ 180, 376, 236, 512, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={[
          { until: 's', value: 0, },
          { from: 's', until: 'xl', value: 1, },
          { from: 'xl', value: -1, },
        ]}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{
          paddingInlineEnd: '1rem',
        }}
      />
      <Footer
        data={data}
        miscStyles={{
          paddingBottom: '1rem',
          paddingInlineEnd: '1rem',
          paddingInlineStart: [ { until: 'xl', value: '1rem', }, ],
        }}
      />
    </Teaser>
  );
}
Teaser6.defaultProps = teaserDefaultProps;
function Teaser6({ data, biAction, theme, }: TeaserPropsType): React.Node {
  return (
    <Teaser
      backgroundColor={[ 'neutral', '-2', ]}
      key={data.contentId}
      gridArea="t6"
      areasTemplate={`
      ". content ."
      ". footer ."
      `}
      rowTemplate="1fr auto"
      colGap="1rem"
      rowGap="1rem"
      gridGap={null}
    >
      <TeaserHeader
        color="white"
        isGridItem
        typeScale={[ { until: 's', value: 0, }, { from: 's', until: 'xl', value: 1, }, ]}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{
          paddingInlineEnd: '1rem',
          paddingInlineStart: [ { until: 'xl', value: '1rem', }, ],
          paddingTop: '1rem',
        }}
        kickerMiscStyles={{
          color: theme.color('negative', '-2'),
        }}
      />
      <Footer
        isDark
        data={data}
        showLive={!!data.liveUpdates}
        commentsColor={[ 'primary', '-2', ]}
        miscStyles={{
          paddingBottom: '1rem',
          paddingInlineEnd: '1rem',
          paddingInlineStart: [ { until: 'xl', value: '1rem', }, ],
        }}
      />
    </Teaser>
  );
}

Teaser5.defaultProps = teaserDefaultProps;
function Teaser5({ data, biAction, }: TeaserPropsType): React.Node {
  return (
    <Teaser
      key={data.contentId}
      gridArea="ct"
      rowTemplate="1fr auto"
      rowGap="1rem"
      areasTemplate="'content' 'footer'"
      miscStyles={{ display: [ { until: 'xl', value: 'none', }, ], }}
    >
      <TeaserHeader
        isGridItem
        typeScale={[
          { until: 's', value: 0, },
          { from: 's', until: 'xl', value: 1, },
          { from: 'xl', value: -1, },
        ]}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{ padding: '1rem 1rem 0', }}
      />
      <Footer
        data={data}
        miscStyles={{ padding: '0 1rem 1rem', }}
        showLive={!!data.liveUpdates}
      />
    </Teaser>
  );
}

function AdSlotTeaser(data) {
  return data ? (
    <AdSlot
      wrapperMiscStyles={{
        gridArea: 'ad',
        marginTop: 0,
        display: [ { until: 'xl', value: 'none', }, ],
      }}
      key={data.contentId}
      {...data}
      loadPriority={data.loadPriority}
    />
  ) : null;
}

function ClickTrackerTeaser(item: ClickTrackerBannerWrapperType) {
  const { css, theme, } = useFela();
  return (
    <ClickTracker
      key={item.contentId}
      {...item}
      render={(banner: ClickTrackerBannerType) => {
        const { image, link, } = banner;
        return (
          <HtzLink
            href={link}
            className={css({
              gridArea: 'ct',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.color('white'),
              extend: [ theme.mq({ until: 'xl', }, { display: 'none', }), ],
            })}
          >
            <Image
              hasWrapper={false}
              image={image}
              miscStyles={{ maxWidth: '125px', height: 'auto', }}
              imgOptions={getImageAssets({
                bps: theme.bps,
                aspect: 'vertical',
                sizes: [ { from: 'xl', size: '125px', }, { size: '103px)', }, ],
                widths: [ 103, 125, 250, ],
              })}
            />
          </HtzLink>
        );
      }}
    />
  );
}
