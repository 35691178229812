// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';

import ListView from '../../../ListView/NewListView';
import Image from '../../../Image/Image';
import Teaser from '../../../Teaser/NewTeaser';
import Footer from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';

type Props = {
  isLazyloadImages: boolean,
  isWideMain: boolean,
  list: ListDataType,
  biAction: ?ListBiActionType,
};

Petunia.defaultProps = {
  isWideMain: false,
  isLazyloadImages: false,
};

export default function Petunia({
  isWideMain,
  list,
  isLazyloadImages,
  biAction,
}: Props): React.Node {
  const { theme, } = useFela();
  const { items, } = list || {};

  if (!items || !items.length) return null;
  return (
    <ListView
      disableWrapper
      keepHeadingLevel
      colGap={[ { until: 's', value: '2', }, { from: 's', value: '4', }, ]}
      rowGap="6rem"
      gridGap={null}
      colTemplate={[ { from: 'xl', value: '1fr', }, { until: 'xl', value: '1fr 1fr', }, ]}
      outerBackgroundColor={[ { from: 's', value: 'white', }, ]}
      padding={null}
      attrs={{
        'data-test': 'petunia',
      }}
    >
      {items.slice(0, 2).map((item, i) => {
        return (
        <PetuniaTeaser
          key={item.contentId}
          isLazyloadImages={isLazyloadImages}
          item={item}
          biAction={setBiAction(i, item, biAction)}
          theme={theme}
        />
      );})}
    </ListView>
  );
}

type PetuniaTeaserPropTypes = {
  item: TeaserDataType,
  isLazyloadImages: boolean,
  theme: Object,
  biAction: ?() => void,
};

const areasStackedTeaser = `
"media media   media"
"...   content   ..."
"...   footer    ..."
`;

PetuniaTeaser.defaultProps = { isLazyloadImages: false, };
function PetuniaTeaser({
  item,
  isLazyloadImages,
  biAction,
  theme,
}: PetuniaTeaserPropTypes): React.Node {
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      areasTemplate={areasStackedTeaser}
      colTemplate={[ { from: 'l', value: '0 1fr 0', }, ]}
      rowTemplate="auto 1fr auto"
      rowGap="1rem"
      colGap={[
        { until: 's', value: '1rem', },
        { from: 's', until: 'xl', value: '0', },
        { from: 'xl', value: '0', },
      ]}
      gridGap={null}
      onClick={biAction}
    >
      <TeaserMedia data={item} onClick={biAction}>
        <Image
          lazyLoad={isLazyloadImages}
          image={image}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'headline',
            sizes: [
              { from: 'xl', size: '290', },
              { from: 'l', size: '260px', },
              { from: 'm', size: '348px', },
              { from: 's', size: '264px', },
              { size: 'calc(50vw - 36px)', },
            ],
            widths: [ 170, 200, 240, 265, 280, 315, 350, 600, ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        {...item}
        typeScale={[
          { until: 's', value: 0, },
          { from: 's', until: 'l', value: 2, },
          { from: 'l', until: 'xl', value: 1, },
          { from: 'xl', value: 0, },
        ]}
        onClick={biAction}
      />
      <Footer
        data={item}
        showAuthor
        showLive={!!item.liveUpdates}
        showRank
        showMediaFlags
        noRankOnMobile
        noCommentsOnMobile
        miscStyles={{ paddingBottom: [ { until: 's', value: '1rem', }, ], }}
        typeScale={[
          { until: 's', value: -3, },
          { from: 's', until: 'xl', value: -2, },
          { from: 'xl', value: -3, },
        ]}
      />
    </Teaser>
  );
}
