// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { Node, } from 'react';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';

import NoSSR from '../../../NoSSR/NoSSR';
import useMedia from '../../../../hooks/useMedia';
import Query from '../../../ApolloBoundary/Query';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import TabbedGraph from '../../../TabbedGraph/TabbedGraph';
import AdSlot from '../../../AdManager/AdSlot';
import FryMobileGraph from './elements/FryMobileGraph';
import FryStaticTable from './elements/FyStaticTable';
import GET_INDEX_TABLE_DATA from './elements/indexTableQuery.graphql';

type FryPropsType = {
  list: ListDataType,
  isLazyloadImages: boolean,
  biAction: ?ListBiActionType,
  gaAction: ?() => void,
};

Fry.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const areasList = [
  {
    until: 's',
    value: `
    "he"
    "t1"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "he he"
    "t1 t1"
    "t2 t3"
    "t4 t4"
    `,
  },
  {
    from: 'l',
    until: 'xl',
    value: `
    "he he he he he he he he he he he he"
    "t1 t1 t1 t1 t1 t1 t1 t2 t2 t2 t2 t2"
    "t3 t3 t3 t3 t3 t3 t4 t4 t4 t4 t4 t4"
    `,
  },
  {
    from: 'l',
    value: `
    "he he he he he he he he he he he he"
    "t1 t1 t1 t1 t1 t1 t2 t2 t2 t3 t3 t3"
    `,
  },
];
const colTemplateList = [
  { until: 's', value: '1fr', },
  { from: 's', until: 'l', value: '1fr 1fr', },
  // { from: 'l', until: 'xl', value: 'repeat(12, 1fr)', },
  { from: 'l', value: 'repeat(12, 1fr)', },
];

const staticTableCount = 5;

export default function Fry({ list, isLazyloadImages, biAction, gaAction, }: FryPropsType): Node {
  const { css, theme, } = useFela();
  const { title, url, banners, } = list;
  const { adSlots, clickTrackerBanners, } = banners;
  const isMobile = useMedia({ query: { until: 's', }, matchOnServer: false, });

  const clickTracker = clickTrackerBanners && clickTrackerBanners[0];
  const adSlotItem = adSlots && adSlots[0];

  return (
    <React.Fragment>
      <ListView
        areasTemplate={areasList}
        colTemplate={colTemplateList}
        gridGap={[ { until: 's', value: '0rem', }, { from: 's', value: '4rem', }, ]}
        // sectionMiscStyles={{ display: [ { until: 's', value: 'none', }, ], }}
        attrs={{
          'data-test': 'fry',
        }}
      >
        {/* Header */}
        <ListViewHeader title={title} url={url} isHorizontal clickTracker={clickTracker} />
        <NoSSR>
          {isMobile ? (
            <FryMobileGraph />
          ) : (
            <React.Fragment>
              <div className={css({ gridArea: 't1', })}>
                <FryTableGraph />
              </div>
              <div
                className={css({
                  gridArea: 't2',
                  display: 'grid',
                  gridTemplateRows: 'auto 1fr',
                  extend: [ theme.mq({ until: 's', }, { display: 'none', }), ],
                })}
              >
                <Query
                  query={GET_INDEX_TABLE_DATA}
                  variables={{
                    filters: [
                      {
                        section: 'index',
                        subSection: '2',
                      },
                    ],
                    count: staticTableCount,
                    sortBy: 'changePercentage',
                    sortOrder: 'desc',
                    offset: 0,
                  }}
                >
                  {({ data, loading, error, }) => {
                    if (loading) return null;
                    if (error) {
                      console.log('error from fry static table', error);
                      return <div />;
                    }
                    return <FryStaticTable data={data} title="עולות" />;
                  }}
                </Query>
              </div>
              <div
                className={css({
                  display: 'grid',
                  gridTemplateRows: 'auto 1fr',
                  gridArea: 't3',
                  extend: [ theme.mq({ until: 's', }, { display: 'none', }), ],
                })}
              >
                <Query
                  query={GET_INDEX_TABLE_DATA}
                  variables={{
                    filters: [
                      {
                        section: 'index',
                        subSection: '2',
                      },
                    ],
                    count: staticTableCount,
                    sortBy: 'changePercentage',
                    sortOrder: 'asc',
                    offset: 0,
                  }}
                >
                  {({ data, loading, error, }) => {
                    if (loading) return null;
                    if (error) {
                      console.log('error from fry static table', error);
                      return <div />;
                    }
                    return <FryStaticTable data={data} title="יורדות" />;
                  }}
                </Query>
              </div>
            </React.Fragment>
          )}
        </NoSSR>
      </ListView>
      {adSlotItem && (
        <AdSlot
          {...adSlotItem}
          loadPriority={list.loadPriority}
          wrapperMiscStyles={{
            marginTop: [ { from: 'l', value: '8rem', }, ],
            // minHeight: '110px',
            width: '100%',
          }}
        />
      )}
    </React.Fragment>
  );
}

function FryTableGraph() {
  const { theme, } = useFela();

  const headers = [
    {
      display: 'שם אפיק',
      value: 'name',
      style: {
        fontWeight: 300,
        paddingStart: '1rem',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        paddingEnd: '1rem',
        marginBottom: '1rem',
        textAlign: 'start',
        color: theme.color('neutral', '-2'),
        backgroundColor: theme.color('layout', 'rowBg'),
      },
    },
    {
      display: '% שינוי',
      percentage: true,
      value: 'changePercentage',
      style: {
        fontWeight: 300,
        textAlign: 'start',
        paddingEnd: '1rem',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        marginBottom: '1rem',
        color: theme.color('neutral', '-2'),
        backgroundColor: theme.color('layout', 'rowBg'),
      },
    },
  ];
  return (
    <TabbedGraph
      tabListMiscStyles={{ marginTop: '0rem', }}
      tabButtonMiscStyles={isActive => ({
        ...(isActive
          ? {
            color: theme.color('neutral', '-1'),
            borderTop: [ 3, 2, 'solid', theme.color('neutral', '-1'), ],
          }
          : {
            color: theme.color('neutral', '-2'),
          }),
      })}
      disableScatter
      disableTimeTabs
      loadingHeight={296}
      isTmHomePage
      filters={[ { section: 'index', subSection: '1', }, ]}
      tabs={[
        {
          display: 'תל אביב',
          control: 'graph-up',
          assetsId: [ '142', '137', '145', '164', '167', ],
          tabFilters: [ { section: 'index', subSection: '2', }, ],
          headers,
        },
        {
          display: 'וול סטריט',
          control: 'graph-up',
          assetsId: [ '30.10.!DJI', '33.10.!SPX', '29.10.@CCO', '29.10.NDX', ],
          tabFilters: [ { section: 'index', subSection: '2', }, ],
          headers,
        },
        {
          display: 'מט"ח',
          control: 'graph-active',
          assetsId: [ '90012', '9001', '90041', '9004', '90011', ],
          headers,
        },
        {
          display: 'קריפטו',
          control: 'graph-upYearly',
          assetsId: [ 'BTC', 'LTC', 'ETH', 'ZEC', 'XRP', ],
          headers,
        },
      ]}
    />
  );
}
