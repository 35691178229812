// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import LayoutContainer from '../../../PageLayout/LayoutContainer';
import LayoutRow from '../../../PageLayout/LayoutRow';
import AkaTeaser from './AkaTeaser';
import setBiAction from '../../../../utils/setBiAction';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

export default function AkaView({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
}: ListViewProps) {
  const item = list.items[0];
  const { theme, } = useFela();
  return (
    <LayoutRow
      bgc={theme.color('neutral', '-2')}
      miscStyles={{
        marginTop: [ { until: 's', value: '6rem', }, { from: 's', value: '8rem', }, ],
      }}
      attrs={{
        'data-test': 'aka',
      }}
    >
      <LayoutContainer>
        <AkaTeaser
          item={item}
          biAction={setBiAction(0, item, biAction)}
        />
      </LayoutContainer>
    </LayoutRow>
  );
}
