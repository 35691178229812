// @flow
import { borderBottom, parseStyleProps, } from '@haaretz/htz-css-tools';
import React, { useEffect, useState, } from 'react';
import { FelaComponent, useFela, } from 'react-fela';

import type { ChildrenArray, Node, } from 'react';
import type { Asset, } from '../../flowTypes/asset';
import Query from '../ApolloBoundary/Query';
import type { Filter, } from '../SortableTable/SortableTable';

import Tab from '../Tab/Tab';
import TabList from '../TabList/TabList';
import Tabs from '../Tabs/Tabs';
import assetsQuery from './queries/assets.graphql';
import assetsListQuery from './queries/assetsList.graphql';

type GetQueryParams = {
  filters: Filter[],
  assetsId?: Array<string>,
  count?: number,
  sortBy: string,
  sortOrder: 'asc' | 'desc',
};

const TableQuery: GetQueryParams => {
  query: DocumentNode,
  variables: Object,
} = ({ fragment, filters, assetsId, count, sortBy, sortOrder, }) => ({
  query: assetsId ? assetsQuery : assetsListQuery,
  variables: assetsId
    ? { assetsId, }
    : {
      filters,
      count: assetsId ? assetsId.length : count,
      sortBy,
      sortOrder,
      offset: 0,
    },
});

type TdComponentProps = {
  children: ChildrenArray<Node> | Node,
  miscStyles: ?Object,
  isHeader?: boolean,
};

const numToString: number => string = num => num.toLocaleString('he', {
  minimumFractionDigits: num > 999.99 ? 0 : 2,
  maximumFractionDigits: num > 999.99 ? 0 : 2,
});

export const TdComponent = ({ children, miscStyles, isHeader, }: TdComponentProps): Node => (
  <FelaComponent
    style={({ theme, }) => ({
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
      verticalAlign: 'top',
      textAlign: 'start',
      extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
    })}
    as={isHeader ? 'th' : 'td'}
  >
    {children}
  </FelaComponent>
);

TdComponent.defaultProps = { miscStyles: null, isHeader: false, };

type Header = {
  display: string,
  value: string,
  style: Object,
  percentage?: boolean,
};

type AssetsTableProps = {
  data: Array<Asset>,
  miscStyles: ?Object,
  changeAsset: Asset => void,
  headers: Array<Header>,
  isTmHomePage?: boolean,
};

const tabRule = ({ theme, isActive, isPrevious, isLast, }) => ({
  cursor: 'pointer',
  ':focus': {
    outline: 'none',
  },
  ...(isLast
    ? {}
    : borderBottom(
      '2px',
      1,
      'solid',
      isActive || isPrevious ? 'transparent' : theme.color('neutral', '-6')
    )),
  ...(isActive
    ? {
      color: theme.color('neutral', '-10'),
      backgroundColor: theme.color('neutral', '-1'),
    }
    : {
      ':hover > td': {
        backgroundColor: theme.color('neutral', '-6'),
      },
    }),
});

AssetsTable.defaultProps = {
  isTmHomePage: false,
};

function AssetsTable({ data, changeAsset, miscStyles, headers, isTmHomePage, }: AssetsTableProps) {
  const { css, theme, } = useFela();
  const [ asset, setAsset, ] = useState(data[0]);
  const [ selectedIndex, setSelectedIndex, ] = useState(0);

  useEffect(() => {
    changeAsset(data[selectedIndex]);
    setAsset(data[selectedIndex]);
  }, [ changeAsset, data, selectedIndex, ]);

  const changeSelectedIndexId: (Asset, number) => void = (selectedAsset, index) => {
    setAsset(selectedAsset);
    setSelectedIndex(index);
  };

  const { id, } = asset;
  return (
    <table
      className={css({
        // ...theme.type(-2),
        whiteSpace: 'nowrap',
        width: '100%',
        extend: [
          theme.type(-1, { untilBp: 'xl', }),
          theme.type(-2, { fromBp: 'xl', }),
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      <thead>
        <tr>
          {headers.map((header: Header) => (
            <TdComponent
              isHeader
              key={header.value}
              miscStyles={{
                color: theme.color('neutral', '-2'),
                paddingTop: '0',
                paddingBottom: '0',
              }}
            >
              <div className={css(header.style)}>{header.display}</div>
            </TdComponent>
          ))}
        </tr>
      </thead>
      <Tabs isFragment activeTab={selectedIndex}>
        {({ setActiveTab, activeTab, }) => (
          <TabList render="tbody">
            {data.map((asset: Asset, index: number) => {
              const isActive: boolean = id === asset.id;
              const isPrevious: boolean = data[index + 1] && id === data[index + 1].id;
              return (
                <Tab
                  isPrevious={isPrevious}
                  index={index}
                  isActive={activeTab === index}
                  isLast={index === data.length - 1}
                  key={asset.id}
                  rule={tabRule}
                  onClick={() => changeSelectedIndexId(asset, index)}
                  setActiveTab={index => setActiveTab(index)}
                  controls={`asset-${asset.id}`}
                  render="tr"
                  presentation={false}
                  // eslint-disable-next-line no-return-assign
                >
                  {headers.map((header: Header, i: number) => {
                    const isLast: boolean = i === headers.length - 1;
                    return (
                      <TdComponent
                        key={`${header.value}-${asset.id}`}
                        isActive={isActive}
                        miscStyles={
                          !i
                            ? {
                              fontWeight: '700',
                              paddingStart: '1rem',
                              paddingEnd: '2rem',
                              maxWidth: isTmHomePage
                                ? [
                                  { from: 'l', until: 'xl', value: '14rem', },
                                  { until: 'l', value: '17rem', },
                                  { from: 'xl', value: '17rem', },
                                ]
                                : '17rem',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }
                            : isLast
                              ? {
                                ...(Number(asset[header.value])
                                  ? {
                                    color:
                                        Number(asset[header.value]) < 0
                                          ? isActive
                                            ? theme.color('negative', '-2')
                                            : theme.color('negative')
                                          : isActive
                                            ? theme.color('positive', '-2')
                                            : theme.color('positive'),
                                    backgroundColor: isActive
                                      ? theme.color('neutral', '-1')
                                      : null,
                                  }
                                  : {}),
                                direction: 'ltr',
                                fontWeight: '700',
                                paddingEnd: '2rem',
                                position: 'relative',
                                textAlign: 'start',
                                ':after': {
                                  ...(isActive
                                    ? {
                                      width: '4rem',
                                      content: '""',
                                      /* selected black arrow svg image */
                                      backgroundImage:
                                          'url("data:image/svg+xml; utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%205%2010%27%3E%3Cpolygon%20fill%3D%27white%27%20points%3D%270%2C0%205%2C0%2C%200%2C5%205%2C10%200%2C10%27%2F%3E%3C%2Fsvg%3E")',
                                      end: '0',
                                      position: 'absolute',
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                      // accommodate a `display: table` bug, that caused `height: 100%`
                                      // to leave two narrow lines at the top and bottom
                                      ...theme.mq({ from: 'xl', }, { height: 'calc(100% + 3px)', }),
                                      ...theme.mq(
                                        { until: 'xl', },
                                        { height: 'calc(100% + 2px)', }
                                      ),
                                    }
                                    : {}),
                                },
                              }
                              : {}
                        }
                      >
                        {asset[header.value] !== null ? (
                          isLast ? (
                            <span
                              className={css({
                                ':before': {
                                  content:
                                  // Number(asset[header.value]) > 0
                                  //   ? '"+"'
                                  //           :
                              Number(asset[header.value]) < 0
                                ? '"-"'
                                : '""',
                                },
                                ...(header.percentage
                                  ? {
                                    ':after': {
                                      content: '"%"',
                                    },
                                  }
                                  : {}),
                              })}
                            >
                              {header.percentage && Number(asset[header.value])
                                ? numToString(Math.abs(Number(asset[header.value])))
                                : Math.abs(Number(asset[header.value]))}
                            </span>
                          ) : (
                            asset[header.value]
                          )
                        ) : (
                          '-'
                        )}
                      </TdComponent>
                    );
                  })}
                </Tab>
              );
            })}
          </TabList>
        )}
      </Tabs>
    </table>
  );
}

export default (props: any) => {
  const { assetsId, assets, sortBy, sortOrder, count, filters, } = props;
  const { query, variables, } = TableQuery({
    assetsId,
    count,
    sortBy,
    sortOrder,
    filters,
  });
  return !assets ? (
    <Query query={query} variables={variables}>
      {({ loading, error, data, }) => {
        if (error) return null;
        if (loading) return null;
        const assets = assetsId ? data.assets : data.assetsList.assets;
        return assets.length ? <AssetsTable data={assets} {...props} /> : null;
      }}
    </Query>
  ) : assets.length ? (
    <AssetsTable data={assets} {...props} />
  ) : null;
};
