// @flow
import React from 'react';
import { WONG_LIST_QUERY as BRANNIGAN_LIST_QUERY, } from '@haaretz/graphql';

import type { Node, } from 'react';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

import BranniganView from './BranniganView.js';
import ListWrapper from '../../ListWrapper';

type Props = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function Brannigan2(props: Props): Node {
  return (
    <ListWrapper {...props} query={BRANNIGAN_LIST_QUERY} view="Brannigan2">
      {dataProps => (
        <BranniganView
          {...dataProps}
          isRelatedArticlesAsLiveUpdate={false}
          displayExclusiveMobile={false}
        />
      )}
    </ListWrapper>
  );
}
