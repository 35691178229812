// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import H from '../../../AutoLevels/H';
import HtzLink from '../../../HtzLink/HtzLink';

type Props = {
  title: ?string,
  url: ?string,
  gutter: ?number,
};

VogelListTitle.defaultProps = {
  title: null,
  gutter: null,
  url: null,
};

export default function VogelListTitle({ title, gutter, url, }: Props): React.Node {
  const { theme, css, } = useFela();

  return (
    <div className={css({
      color: theme.color('vogelList', 'title'),
      backgroundColor: theme.color('vogelList', 'titleBg'),
    })}
    >

      {
        url ? (
          <HtzLink href={url}>
            <H>{title}</H>
          </HtzLink>
        ) : (
          <H>{title}</H>
        )
      }
    </div>
  );
}
