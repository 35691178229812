// @flow
import React from 'react';
import type { ChildrenArray, Node, } from 'react';
import { FelaComponent, } from 'react-fela';
import Link from 'next/link';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import IconBack from '../Icon/icons/IconBack';

type Props = {
  href:
    | string
    | {
        pathname: string,
        query: Object,
      },
  as: ?string,
  children: ChildrenArray<Node> | Node,
  miscStyles?: ?Object,
  icon: boolean,
  target: ?string,
};

const SectionLink = ({ href, as, children, miscStyles, icon, target, }: Props): Node => (
  <FelaComponent
    style={({ theme, }) => ({
      backgroundColor: theme.color('neutral', '-5'),
      color: theme.color('neutral', '-1'),
      display: 'block',
      fontWeight: '700',
      paddingBottom: '1rem',
      paddingTop: '1rem',
      textAlign: 'center',
      ...theme.mq(
        { until: 'xl', },
        {
          paddingTop: 'calc(5 / 6 * 1rem)',
          paddingBottom: 'calc(5 / 6 * 1rem)',
          ...theme.type(-1),
        },
      ),
      ...theme.mq(
        { from: 'xl', },
        {
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          ...theme.type(-2),
        },
      ),
      extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
    })}
  >
    {({ className, }) => (
      <Link href={href} as={as}>
        <a
          className={className}
          {...(target ? { target, } : {})}
        >
          {children}
          {
            icon
              ? <IconBack size={-1} miscStyles={{ marginStart: '0.5rem', }} />
              : null
          }
        </a>
      </Link>
    )}
  </FelaComponent>
);

SectionLink.defaultProps = {
  miscStyles: null,
  as: null,
  icon: true,
  target: null,
};

export default SectionLink;
