// @flow
import React from 'react';
import { useFela, } from 'react-fela';

import type { Node, } from 'react';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';
import type { ClickTrackerBannerWrapperType, } from '../../../../flowTypes/ClickTrackerBannerWrapperType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import Debug from '../../../Debug/Debug';
import HtzLink from '../../../HtzLink/HtzLink';
import Image from '../../../Image/Image';
import LayoutContainer from '../../../PageLayout/LayoutContainer';
import ListView from '../../../ListView/NewListView';

type Props = {
  list: ListDataType,
};

function Leonardo({ list, }: Props): Node {
  const { theme, css, } = useFela();
  const items: ?Array<ClickTrackerBannerWrapperType> = Array.isArray(list?.banners?.clickTrackerBanners) && list.banners.clickTrackerBanners.length > 4
    ? list.banners.clickTrackerBanners.slice(0, 5)
    : null;

  const itemsLength = (items || []).length;

  return items ? (
    <LayoutContainer
      miscStyles={{
        display: [ { until: 's', value: 'none', }, ],
        ...theme.leonardoStyle.layoutContainerMiscStyle,
      }}
      attrs={{
        'data-test': 'leonardo',
      }}
    >
      <ListView
        colTemplate={[
          {
            until: 'l',
            value: `repeat(${itemsLength > 4 ? itemsLength - 1 : itemsLength}, 1fr)`,
          },
          { from: 'l', value: `repeat(${itemsLength}, 1fr)`, },
        ]}
        innerBackgroundColor="white"
        marginTop={0}
        padding={[ { from: 's', value: '0 4rem', }, ]}
        miscStyles={(theme.leonardoStyle.listViewMiscStyle({ theme, }) || {})}
      >
        {items.map((item, i) => (
          <ClickTracker
            key={item.contentId || item.id}
            {...item}
            render={(banner: ClickTrackerBannerType) => {
              const { image, link, } = banner;
              return (
                <HtzLink
                  href={link}
                  className={
                    i > 3
                      ? css({
                        ...theme.mq({ until: 'l', }, { display: 'none', }),
                      })
                      : ''
                  }
                >
                  <Image
                    image={image}
                    imgOptions={{
                      transforms: {
                        width: '227',
                        aspect: 'landscape',
                        quality: 'auto',
                      },
                    }}
                  />
                </HtzLink>
              );
            }}
          />
        ))}
      </ListView>
    </LayoutContainer>
  ) : (
    <Debug>There is not enough items to render this list view</Debug>
  );
}
export default Leonardo;
