// @flow
import * as React from 'react';
import { HYPNOTOAD_LIST_QUERY, } from '@haaretz/graphql';
import HypnotoadView from './HypnotoadView';
import ListWrapper from '../../ListWrapper';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
};

export default function Hypnotoad(props: Props): React.Node {
  return (
    <ListWrapper {...props} query={HYPNOTOAD_LIST_QUERY} view="Hypnotoad">
      {dataProps => <HypnotoadView {...dataProps} />}
    </ListWrapper>
  );
}
