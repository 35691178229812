// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { StyleProps, } from '@haaretz/htz-css-tools';

import StaticTable from '../../../../StaticTable/StaticTable';

const numToString: (number | string) => string = num => (typeof num === 'number'
  ? num.toLocaleString('he', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  : num);

export default function FryStaticTable({
  data,
  title,
}: {
  data: { assetsList: { assets: Array<any>, }, },
  title: string,
}) {
  const { theme, } = useFela();
  const headerStyle = { backgroundColor: 'transparent', fontWeight: 400, };
  return (
    <StaticTable
      baseLinkPath="https://finance.themarker.com/"
      title={title}
      data={data.assetsList.assets}
      linkContent
      miscStyles={{
        height: '100%',
      }}
      titleMiscStyles={{
        backgroundColor: 'transparent',
        marginBottom: '1rem',
        borderBottom: [ '2px', 1, 'solid', theme.color('bodyText', 'base'), ],
      }}
      columns={[
        {
          headerStyle,
          title: 'שם נייר',
          name: 'name',
          styles: {
            fontWeight: '700',
            maxWidth: '17rem',
            paddingStart: '2rem',
            verticalAlign: 'middle',
          },
          render: value => value,
        },
        {
          title: '% שינוי',
          headerStyle,
          percentage: true,
          name: 'changePercentage',
          styles: function styles(value: number): StyleProps {
            return {
              ...(value
                ? {
                  color: value < 0 ? theme.color('negative') : theme.color('positive'),
                }
                : {}),
              direction: 'ltr',
              fontWeight: '700',
              paddingStart: '2rem',
              position: 'relative',
              textAlign: 'start',
              verticalAlign: 'middle',
            };
          },
          render: value => `
                ${value > 0 ? '+' : value < 0 ? '-' : ''}
                ${value ? numToString(Math.abs(value)) : value}%
              `,
        },
      ]}
    />
  );
}
