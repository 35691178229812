// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import Image from '../../../Image/Image';
import getImageAssets from '../../../../utils/getImageAssets';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type VogelItemPropTypes = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  hideImage: ?boolean,
  hideImageOnMobile: ?boolean,
  hideSeparator: ?boolean,
  hideSeparatorOnMobile: ?boolean,
  hideOnMobile: ?boolean,
  index: number,
  biAction: ?() => void,
};

VogelItem.defaultProps = {
  hideImage: false,
  hideImageOnMobile: false,
  hideSeparator: false,
  hideSeparatorOnMobile: false,
  hideOnMobile: false,
  biAction: null,
};

const areasTemplate = hideImage => ([
  {
    until: 's',
    value: `
    ". content ."
    `,
  },
  {
    from: 's',
    value: `
    ${hideImage ? '' : '"media media media"'}
    ". content ."
    `,
  },
]);

function VogelItem({
  data,
  isLazyloadImages,
  hideImage,
  hideImageOnMobile,
  hideSeparator,
  hideSeparatorOnMobile,
  hideOnMobile,
  index,
  biAction,
}: VogelItemPropTypes): React.Node {
  const { theme, } = useFela();
  const borderStyle = [ '1px', 1, 'solid', theme.color('neutral', '-6'), ];

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      areasTemplate={areasTemplate(hideImage)}
      gridGap="1rem"
      colTemplate="0 1fr 0"
      rowTemplate={hideImage ? null : 'auto 1fr'}
      miscStyles={{
        marginTop: index === 0 ? '-1rem' : null,
        paddingBottom: index > 1 ? '1rem' : null,
      }}
    >
      {hideImage === false ? (
        <TeaserMedia
          data={data}
          onClick={biAction}
        >
          <Image
            lazyLoad={isLazyloadImages}
            image={image}
            imgOptions={getImageAssets({
              aspect: 'headline',
              bps: theme.bps,
              sizes: [
                { from: 'xl', size: '219px', },
                { from: 'l', size: '226px', },
                { from: 'm', size: '224px', },
                { size: '168px', },
              ],
              widths: [ 168, 226, 336, 452, ],
            })}
            onClick={biAction}
          />
        </TeaserMedia>
      ) : null}
      <TeaserHeader
        {...data}
        typeScale={[
          { until: 's', value: 0, },
          { from: 's', until: 'xl', value: -1, },
          { from: 'xl', value: -2, },
        ]}
        kickerTypeScale={[
          { until: 's', value: 0, },
          { from: 's', until: 'xl', value: -1, },
          { from: 'xl', value: -2, },
        ]}
        miscStyles={{ fontWeight: 'normal', }}
        wrapperMiscStyles={{ borderBottom: index > 1 ? null : borderStyle, }}
        onClick={biAction}
        isGridItem
      />
    </Teaser>
  );
}

export default VogelItem;
