// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';


import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import Section from '../../../AutoLevels/Section';
import H from '../../../AutoLevels/H';
import LiveUpdateView from '../../../LiveUpdateView/LiveUpdateView';
import HtzLink from '../../../HtzLink/HtzLink';
import setBiAction from '../../../../utils/setBiAction';


type Props = {
  relatedArticles: TeaserDataType[],
  biAction: ?ListBiActionType,
  miscStyles: ?StyleProps,
}

type LiveUpdateProps = {
  article: { contentId: string, path: string, title: string, },
  biAction: ?ListBiActionType,
  index: number,
  isLast: boolean,
}

const relatedUpdatesWrapper = ({ theme, miscStyles, }) => ({
  gridArea: 'links',
  color: theme.color('neutral', '-1'),
  fontWeight: 700,
  extend: [
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

const listWrapperStyles = {
  marginInlineStart: '-1rem',
};

const articleLinkStyles = ({ theme, isLast, }) => ({
  position: 'relative',
  paddingBottom: '1rem',

  ...(isLast ? {} : {
    ':before': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: '100%',
      backgroundColor: theme.color('neutral', '-5'),
      start: '0',
      top: '1.5rem',
      marginInlineStart: '1rem',
    },
  }),
  ':after': {
    content: '""',
    position: 'absolute',
    width: 'calc(1rem + 1px)',
    height: 'calc(1rem + 1px)',
    backgroundColor: theme.color('tertiary'),
    borderRadius: '50%',
    start: '0',
    top: '0.3rem',
    transform: 'translate(50%, 50%)',
  },

  color: theme.color('neutral', '-1'),
  display: 'block',
  paddingInlineStart: '4rem',

  ':visited': {
    color: theme.color('neutral', '-1'),
  },
  ':hover': {
    color: theme.color('neutral', '-1'),
    textDecoration: 'underline',
  },
  ':focus': {
    color: theme.color('neutral', '-1'),
    textDecoration: 'underline',
    outline: 'none',
  },
  extend: [
    theme.type(-1),
  ],
});

export default function RelatedLiveUpdates({
  relatedArticles,
  biAction,
  miscStyles,
}: Props): React.Node {
  const { css, theme, } = useFela({ miscStyles, });
  const wrapperClassName = css(relatedUpdatesWrapper);
  const listWrapperClassName = css(listWrapperStyles);

  return (
    <Section tagName="div" className={wrapperClassName}>
      <LiveUpdateView
        Tag={H}
        text={theme.BranniganI18n.liveUpdates}
        miscStyles={{ paddingBottom: '1rem', display: [ { until: 's', value: 'none', }, ], }}
        textMiscStyles={{ type: -1, marginInlineEnd: '0.5rem', }}
        diameter={[ { until: 'xl', value: 1.5, }, { from: 'xl', value: 'calc(1rem + 2px)', }, ]}
        addAnimation={false}
        isLiveUpdateDot
      />

      <ul className={listWrapperClassName}>
        {relatedArticles.slice(0, 4).map((article, i, articles) => (
          <RelatedLiveUpdate
            key={article.contentId}
            article={article}
            index={i}
            isLast={i === articles.length - 1}
            biAction={biAction}
          />
        ))}
      </ul>
    </Section>
  );
}

function RelatedLiveUpdate({ article, index, isLast, biAction, }: LiveUpdateProps): React.Node {
  const { css, } = useFela({ isLast, });
  const className = css(articleLinkStyles);

  return (
    <li>
      <HtzLink
        href={article.path}
        className={className}
        onClick={setBiAction(index, article, biAction)}
      >
        {article.title}
      </HtzLink>
    </li>
  );
}
