// @flow
import * as React from 'react';
import { PETUNIA_LIST_QUERY, } from '@haaretz/graphql';
import PetuniaView from './PetuniaView.js';
import ListWrapper from '../../ListWrapper';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  updateListDuplication: Function,
  variables: {},
  listData: ListDataType,
};

export default function Petunia(props: Props): React.Node {
  return (
    <ListWrapper  query={PETUNIA_LIST_QUERY} view="Petunia" {...props}>
      {dataProps => <PetuniaView {...dataProps} />}
    </ListWrapper>
  );
}
