// @flow
import * as React from 'react';
import { HUBERT_LIST_QUERY, } from '@haaretz/graphql';
import HubertView from './HubertView.js';
import ListWrapper from '../../ListWrapper';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
};

export default function Hubert(props: Props): React.Node {
  return (
    <ListWrapper {...props} query={HUBERT_LIST_QUERY} view="Hubert">
      {dataProps => <HubertView {...dataProps} />}
    </ListWrapper>
  );
}
