// @flow
import * as React from 'react';
import { WEEKEND_LIST_QUERY, } from '@haaretz/graphql';
import WeekendView from './WeekendView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';
import Debug from '../../../Debug/Debug';

type WeekendWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}


export default function WeekendWrapper(props: WeekendWrapperProps) {
  return (
    <ListWrapper {...props} query={WEEKEND_LIST_QUERY} view="Weekend">
      {dataProps => (dataProps.list.items.length > 4 ? (
        <WeekendView noMargin={props.noMargin} {...dataProps} />
      ) : (
        <Debug>There are not enough items for Weekend list to render</Debug>
      ))
      }
    </ListWrapper>
  );
}
