// @flow
import React, { Fragment, useState, useEffect, useCallback, } from 'react';
import Link from 'next/link';
import gql from 'graphql-tag';
import { useFela, } from 'react-fela';
import { parseStyleProps, borderBottom, } from '@haaretz/htz-css-tools';
// import { IconBack, Query, Debug, } from '@haaretz/htz-components';

import type { StyleProps, } from '@haaretz/htz-css-tools';
import type { ChildrenArray, Node, } from 'react';
import type { DocumentNode, } from 'graphql/language/ast';
import type { Asset, } from '../../flowTypes/asset';

import IconBack from '../Icon/icons/IconBack';
import Query from '../ApolloBoundary/Query';
import Debug from '../Debug/Debug';
import { TdComponent, } from '../AssetsTable/AssetsTable';
import SectionLink from '../SectionLink/SectionLink';
import { useRouter } from 'next/router';

type PathType = { type: string, id: string, };

type FieldType = {
  name: string,
  display: string,
  sortingOrder: 'asc' | 'desc',
  headerStyle?: StyleProps | null,
  style?: (any => StyleProps) | null,
  hideUntil?: String[],
  value: Object => string,
  path?: PathType,
};

export type Filter = {
  section: string,
  subSection?: string,
};

export type LinkType = {
  href:
    | string
    | {
        pathname: string,
        query: Object,
      },
  as: string,
  text: string,
};

type Props = {
  queryPrefix: ?string,
  miscStyles: ?StyleProps,
  headerMiscStyles: ?StyleProps,
  initialSort: string,
  fields: Array<FieldType>,
  fragment: string,
  filters: Filter[],
  assetsId: ?Array<string>,
  type: ?string,
  assetSubSection: ?string,
  link?: LinkType,
  count: number,
  loadMore: ?boolean,
  pagination: ?boolean,
  extractData: ?(any) => Array<Asset>,
  client: Object,
  fetchMore: any => Promise<any>,
  assetsList: Array<Asset>,
  disableSort?: boolean,
  total: ?number,
  fixed: boolean,
};

type SortIconsProps = {
  active: boolean,
  sortOrder: 'asc' | 'desc',
};

type GetQueryParams = {
  queryPrefix: ?string,
  fragment: string,
  filters: Filter[],
  assetsId?: ?Array<string>,
  count?: number,
  sortBy: ?string,
  sortOrder: ?('asc' | 'desc'),
};

const TableQuery: GetQueryParams => {
  query: DocumentNode,
  variables: Object,
} = ({ queryPrefix, fragment, filters, assetsId, count, sortBy, sortOrder, }) => ({
  query: assetsId
    ? gql`
      query ${queryPrefix || ''}SortableTable($ids: [String!]!) {
        assets(ids: $ids) {
          id
          type
          ${fragment}
        }
      }
    `
    : gql`
      query ${queryPrefix || ''}SortableTable(
        $filters: [AssetGroupFilter!],
        $count: Float!,
        $sortBy: AssetColumn,
        $sortOrder: OrderType,
        $offset: Float
      ) {
        assetsList(
          filters: $filters
          count: $count,
          sortBy: $sortBy,
          sortOrder: $sortOrder,
          offset: $offset
        ) {
          assets {
            id
            type
            ${fragment}
          }
          total
        }
      }
    `,
  variables: assetsId
    ? { ids: assetsId, }
    : {
      filters,
      count: assetsId ? assetsId.length : count,
      sortBy,
      sortOrder,
      offset: 0,
    },
});

// eslint-disable-next-line react/prop-types
export const SortIcons: SortIconsProps => Node = ({ active, sortOrder, }) => {
  const { theme, css, } = useFela();
  const className = css({
    transform: 'translateY(-50%)',
    fontSize: '1.5rem',
    marginEnd: '1rem',
  });
  const ascendFill: string = active && sortOrder === 'asc' ? theme.color('neutral', '-2') : theme.color('neutral', '-4');
  const descendFill: string = active && sortOrder === 'desc' ? theme.color('neutral', '-2') : theme.color('neutral', '-4');
  return (
    <svg className={className} viewBox="0 0 20 40" width="0.5em" height="1em">
      <polygon fill={ascendFill} points="0,15 10,0 20,15" />
      <polygon fill={descendFill} points="0,25 10,40 20,25" />
    </svg>
  );
};

type PaginationButtonProps = {
  onClick: () => void,
  disabled: boolean,
  children: ChildrenArray<Node> | Node,
};

function PaginationButton({ onClick, disabled, children, }: PaginationButtonProps): Node {
  const className = useFela().css(({ theme, }) => ({
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem',
    fontWeight: '700',
    extend: [ disabled ? { color: theme.color('neutral', '-4'), } : {}, ],
  }));
  return (
    <button className={className} type="button" onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
}

type TableLinkProps = {
  basePath?: string,
  content: string,
  assetId: string,
  type: string,
  allowTab: ?boolean,
};

TableLink.defaultProps = {
  basePath: '/',
};
export function TableLink({ content, assetId, type, allowTab, basePath, }: TableLinkProps): Node {
  const router = useRouter();

  const className = useFela().css({
    display: 'block',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  });
  return (
    <Link
      href={{
        pathname: `${basePath || '/'}asset/${type}`,
        query: {
          ...router.query,
          assetId,
          section: type,
        },
      }}
      as={`${basePath || '/'}${type}/${assetId}`}
    >
      <a {...(!allowTab ? { tabIndex: -1, } : {})} className={className}>
        {content}
      </a>
    </Link>
  );
}

const tdHeaderStyle: (Object, ?StyleProps) => Object = (theme, miscStyles) => ({
  textAlign: 'start',
  backgroundColor: theme.color('transparent'),
  color: theme.color('neutral', '-2'),
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  paddingEnd: '2rem',
  ...theme.mq(
    { until: 'xl', },
    { paddingTop: 'calc(5 / 6 * 1rem)', paddingBottom: 'calc(5 / 6 * 1rem)', }
  ),
  ...theme.mq({ from: 'xl', }, { paddingTop: '0.5rem', paddingBottom: '0.5rem', }),
  extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
});

/* eslint-disable react/prop-types */
function Table({
  miscStyles,
  headerMiscStyles,
  type,
  link,
  loadMore,
  pagination,
  fields,
  sortOrder,
  assets,
  filters,
  fetchData,
  fetchAll,
  nextPage,
  client,
  count,
  sortBy,
  disableSort,
  total,
  fixed,
}) {
  const [ page, setPage, ] = useState(1);
  const { theme, css, } = useFela();
  return (
    <Fragment>
      <table
        className={css({
          whiteSpace: 'nowrap',
          width: '100%',
          extend: [
            theme.type(-1, { untilBp: 'xl', }),
            theme.type(-2, { fromBp: 'xl', }),
            fixed ? { tableLayout: 'fixed', } : {},
            ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
          ],
        })}
      >
        <thead>
          <tr>
            {fields.map((field: FieldType, i: number) => (
              <TdComponent
                isHeader
                key={field.name}
                miscStyles={{
                  backgroundColor: theme.color('neutral', '-7'),
                  whiteSpace: 'pre-wrap',
                  verticalAlign: 'bottom',
                  paddingTop: '0',
                  paddingBottom: '0',
                  ...(field.hideUntil
                    ? {
                      display: field.hideUntil.map(bp => ({
                        until: bp,
                        value: 'none',
                      })),
                    }
                    : {}),
                  ...(field.headerStyle || {}),
                }}
              >
                {!disableSort ? (
                  <button
                    type="button"
                    className={css({
                      ...tdHeaderStyle(theme, headerMiscStyles),
                      fontWeight: sortBy === field.name ? '700' : '500',
                      paddingStart: i === 0 ? '1rem' : null,
                    })}
                    onClick={() => {
                      setPage(1);
                      fetchData({ client, field, });
                    }}
                  >
                    <SortIcons active={sortBy === field.name} sortOrder={sortOrder} />
                    <span>{field.display}</span>
                  </button>
                ) : (
                  <div
                    className={css({
                      ...tdHeaderStyle(theme, headerMiscStyles),
                      backgroundColor: theme.color('transparent'),
                      display: 'flex',
                      alignItems: 'flex-end',
                      paddingEnd: '2rem',
                      paddingStart: '2rem',
                      fontWeight: sortBy === field.name ? '700' : '300',
                    })}
                  >
                    <span>{field.display}</span>
                  </div>
                )}
              </TdComponent>
            ))}
          </tr>
        </thead>
        <tbody>
          {(assets || []).map((asset: Asset) => (
            <tr
              key={asset.id}
              className={css({
                backgroundColor: theme.color('neutral', '-10'),
                extend: [ borderBottom('2px', 1, 'solid', theme.color('neutral', '-6')), ],
              })}
            >
              {(fields || []).map(({ path, ...field }: FieldType, index: number) => (
                <TdComponent
                  key={`${field.name}-${asset.id}`}
                  miscStyles={{
                    paddingTop: [ { until: 'xl', value: '10px', }, { from: 'xl', value: '1.35rem', }, ],
                    paddingBottom: [
                      { until: 'xl', value: '10px', },
                      { from: 'xl', value: '1.35rem', },
                    ],
                    paddingStart: '1rem',
                    ...(field.style ? field.style(asset) : {}),
                    ...(field.hideUntil
                      ? {
                        display: field.hideUntil.map(bp => ({
                          until: bp,
                          value: 'none',
                        })),
                      }
                      : {}),
                  }}
                >
                  {path ? (
                    <TableLink
                      allowTab
                      content={field.value(asset)}
                      assetId={asset[path.id]}
                      type={asset[path.type]}
                    />
                  ) : index === 0 ? (
                    <TableLink
                      allowTab
                      content={field.value(asset)}
                      assetId={asset.id}
                      type={asset.type}
                    />
                  ) : (
                    <span>{field.value(asset)}</span>
                  )}
                </TdComponent>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {link ? (
        <SectionLink href={link.href} as={link.as}>
          <span>{link.text}</span>
        </SectionLink>
      ) : null}
      {loadMore && assets.length <= count ? (
        <button
          type="button"
          className={css({
            ...theme.type(-2),
            backgroundColor: theme.color('neutral', '-5'),
            color: theme.color('neutral', '-1'),
            display: 'block',
            fontWeight: '700',
            paddingBottom: '1rem',
            paddingTop: '1rem',
            textAlign: 'center',
            width: '100%',
          })}
          onClick={() => fetchAll()}
        >
          טען עוד
          <IconBack
            size={-1}
            miscStyles={{
              marginStart: '1rem',
              transform: 'rotate(270deg)',
            }}
          />
        </button>
      ) : null}
      {pagination && total && assets.length < total ? (
        <div
          className={css({
            display: 'flex',
            ...theme.type(-2),
            color: theme.color('neutral', '-1'),
            fontWeight: '700',
            textAlign: 'center',
            width: '100%',
          })}
        >
          <div
            className={css({
              backgroundColor: theme.color('neutral', '-5'),
              display: 'flex',
              flexGrow: '1',
              paddingStart: '1rem',
              paddingEnd: '1rem',
            })}
          >
            <PaginationButton
              onClick={() => nextPage(count, page, setPage, true)}
              disabled={page === 1}
            >
              <IconBack
                size={-1}
                miscStyles={{
                  transform: 'rotate(180deg)',
                }}
              />
              הקודם
            </PaginationButton>
            <div
              className={css({
                flexGrow: '1',
                fontWeight: '300',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              {`${page * count}-${(page - 1) * count + 1} מתוך ${total}`}
            </div>
            <PaginationButton
              onClick={() => nextPage(count, page, setPage)}
              disabled={total <= page * count}
            >
              הבא
              <IconBack size={-1} />
            </PaginationButton>
          </div>
          <button
            className={css({
              backgroundColor: theme.color('neutral', '-4'),
              fontWeight: '700',
              paddingStart: '1rem',
              paddingEnd: '1rem',
            })}
            type="button"
            onClick={() => fetchAll()}
          >
            הצג הכל
            <IconBack
              size={-1}
              miscStyles={{
                marginStart: '1rem',
              }}
            />
            <IconBack
              size={-1}
              miscStyles={{
                marginStart: '-1rem',
              }}
            />
          </button>
        </div>
      ) : null}
    </Fragment>
  );
}

SortableTable.defaultProps = {
  queryPrefix: null,
  miscStyles: null,
  headerMiscStyles: null,
  assetsId: null,
  filters: null,
  type: null,
  assetSubSection: null,
  count: 5,
  link: null,
  loadMore: null,
  pagination: null,
  extractData: null,
  disableSort: false,
  total: null,
  fixed: true,
};

function SortableTable(props: Props): Node {
  const getSortOrder = useCallback(() => {
    const selectedField: ?FieldType = props.fields.find(
      (field: FieldType) => field.name === props.initialSort
    );
    return selectedField ? selectedField.sortingOrder : null;
  }, [ props.fields, props.initialSort, ]);

  const [ state, setState, ] = useState({
    assetsList: props.assetsList,
    sortBy: props.initialSort,
    sortOrder: getSortOrder,
    filters: props.filters,
  });

  useEffect(() => {
    setState({
      assetsList: props.assetsList,
      sortBy: props.initialSort,
      sortOrder: getSortOrder,
      filters: props.filters,
    });
  }, [ props.assetsList, props.initialSort, props.filters, getSortOrder, ]);

  const { assetsList, sortBy, sortOrder, filters, } = state;

  const fetchData: ({
    client: Object,
    field?: FieldType,
  }) => Promise<any> = async ({ client, field = null, }) => {
    const { assetsId, count, fragment, queryPrefix, } = props;

    const newSortBy = field ? field.name : sortBy;
    const newSortOrder = field
      ? newSortBy !== sortBy
        ? field.sortingOrder
        : sortOrder === 'desc'
          ? 'asc'
          : 'desc'
      : sortOrder;

    const { query, variables, } = TableQuery({
      queryPrefix,
      fragment,
      filters,
      assetsId,
      count,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    });
    client
      .query({
        query,
        variables,
        fetchPolicy: 'network-only',
      })
      .then(({ data, }) => {
        setState({
          assetsList: assetsId ? data.assets : data.assetsList.assets,
          sortBy: newSortBy,
          sortOrder: newSortOrder,
          filters,
        });
      });
  };

  const { fields, count, fetchMore, client, extractData, total, ...restOfProps } = props;

  const nextPage = (offset: number, page: number, setPage: Function, back: boolean = false) => {
    goFetch({
      offset: offset * (back ? page - 2 : page),
      count,
      sortBy,
      sortOrder,
    });
    setPage(page + (back ? -1 : 1));
  };

  const fetchAll = () => goFetch({
    offset: 0,
    sortBy,
    sortOrder,
    ...(total ? { count: total, } : {}),
  });

  function goFetch(variables): void {
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult, }) => (fetchMoreResult
        ? Object.assign({}, prev, {
          assetsList: fetchMoreResult.assetsList,
        })
        : prev),
    }).then(({ data, }) => {
      setState({
        assetsList: data ? data.assetsList.assets : assetsList,
        sortBy,
        sortOrder,
        filters,
      });
    });
  }
  return (
    <Table
      assets={extractData ? extractData(assetsList) : assetsList}
      fetchData={fetchData}
      {...{
        sortOrder,
        filters,
        fetchMore,
        fields,
        client,
        count,
        sortBy,
        total,
        fetchAll,
        nextPage,
      }}
      {...restOfProps}
    />
  );
}

export default (props: any) => {
  const { filters, assetsId, count, fragment, queryPrefix, loadMore, } = props;

  const getSortOrder: () => ?('asc' | 'desc') = () => {
    if (props.initialSortOrder) return props.initialSortOrder;
    const selectedField: ?FieldType = props.fields.find(
      (field: FieldType) => field.name === props.initialSort
    );
    return selectedField ? selectedField.sortingOrder : null;
  };

  const sortBy: string = props.initialSort;
  const sortOrder = getSortOrder();

  if (!sortOrder) {
    return <Debug>you must provide initial sort order</Debug>;
  }

  const { query, variables, } = TableQuery({
    queryPrefix,
    fragment,
    filters,
    assetsId,
    count,
    sortBy,
    sortOrder,
  });
  return (
    <Query query={query} variables={variables}>
      {({ loading, error, data, fetchMore, client, }) => {
        if (error) return null;
        if (loading) return null;
        const { assets, total, } = data.assetsList || data;
        return (
          <SortableTable
            assetsList={assets}
            fetchMore={fetchMore}
            client={client}
            total={total}
            {...props}
            loadMore={loadMore && total && total > assets.length}
          />
        );
      }}
    </Query>
  );
};
